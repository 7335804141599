import { DroplistDivider } from './DroplistDivider';
import cc from 'classcat';
import styles from './DroplistHeader.module.scss';

interface DroplistItemProps {
    title: string;
    subtitle?: string;
    className?: string;
}

/** @deprecated use mui/list instead */
export const DroplistHeader = (props: DroplistItemProps) => (
    <>
        <div className={cc([styles['root'], props.className])}>
            <h6 className={styles['root__title']}>{props.title}</h6>
            <p className={styles['root__subtitle']}>{props.subtitle}</p>
        </div>
        <DroplistDivider />
    </>
);
