import { Box, MenuItem, Typography } from '@mui/material';
import { listItem } from './styles';
import { AddCohort } from '../../../icons/common/CAddCohort';
import { getSquare } from '../../../theme/style.helpers';
import { pluralize } from '../../../utils/pluralize';
import React from 'react';
import { CohortMetaDto } from '../../../api/cohorts';

type Props = {
    cohort: CohortMetaDto;
    props: React.HTMLAttributes<HTMLLIElement> & { key?: string };
};

export function CohortMenuItem({
    cohort,
    props: { key: _, className: _cn, ...props },
}: Props) {
    return (
        <MenuItem
            {...props}
            key={cohort.id}
            sx={listItem}
            data-testid="popper-result-list-item"
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <AddCohort
                    sx={{
                        ...getSquare(20),
                        mr: 2,
                        color: 'custom.gray.super',
                    }}
                />
                <Typography
                    variant="body3"
                    color="primary.dark"
                    data-testid="popper-cohort-name"
                >
                    {cohort.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body4"
                    color="custom.gray.super"
                    data-testid="popper-cohort-users-count"
                >
                    {pluralize(cohort.contactsCount, 'user', 'users')}
                </Typography>
            </Box>
        </MenuItem>
    );
}
