import { useEnabledFeature, useMeQueryData } from '../../../../queries/user';
import { Contact, Feature, Message, SenderType } from '../../../../api/types';
import { getName } from '../../../../main/campaign/v3/get-profile-name';
import { CONTACT_BY_PHONE_KEY } from '../../../../components/Contacts/use-contact-by-phone';
import { useQueryClient } from '@tanstack/react-query';
import { getPlaceholder } from '../../../../components/Contacts/get-placeholder';
import { isAssistant } from '../../../../components/ConversationRow/Assistant/check-is-until-review';
import { getContactName } from '../../../../utils/contacts';

export const useMessageSender = () => {
    const me = useMeQueryData();
    const client = useQueryClient();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return (message: Message) => {
        if (message.inbound) {
            const contact =
                client.getQueryData<Contact>([
                    CONTACT_BY_PHONE_KEY,
                    message.sender,
                ]) ?? getPlaceholder(message.sender);
            return getContactName(
                contact,
                me?.activeTeam?.countryCode,
                isMultipleContactPhonesEnabled,
            );
        }

        if (isAssistant(message.senderType)) {
            return 'Clerk Assistant';
        }

        if (
            [SenderType.CAMPAIGN, SenderType.CAMPAIGN_MESSAGE].includes(
                message.senderType,
            )
        ) {
            return message.sentByName
                ? `Campaign: ${message.sentByName}`
                : 'Campaign';
        }

        if (SenderType.WORKFLOW === message.senderType) {
            return message.sentByName
                ? `Workflow: ${message.sentByName}`
                : 'Workflow';
        }
        if (message.user) {
            return (
                getName(message.user) ?? message.sentByName ?? message.sender
            );
        }
        return message.sentByName ?? message.sender;
    };
};
