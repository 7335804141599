import { Contact } from '../api/types';
import { formatPhoneNumber } from './phoneNumber';
import { getPrimaryPhone } from '../components/NewConversation/utils';
import { CampaignContact } from '../api/campaign.types';

export const getContactName = (
    contact: Pick<
        Contact | CampaignContact,
        'name' | 'email' | 'phone' | 'phones'
    >,
    teamCountry?: string,
    isMultipleContactPhonesEnabled: boolean = false,
) => {
    const { name, email, phones } = contact;

    const primaryPhone = isMultipleContactPhonesEnabled
        ? getPrimaryPhone(phones ?? [])
        : contact;

    if (name && name.trim().length > 0) {
        return name;
    }

    if (primaryPhone?.phone) {
        return formatPhoneNumber(primaryPhone.phone, teamCountry);
    }

    return email;
};
