import { useMutation } from '@tanstack/react-query';
import { client } from '../../../api/http';
import { Conversation, Message } from '../../../api/types';
import { CampaignOrMessageCreateParams } from '../../../api/campaign.types';
import { queryClient } from '../../../queries/queryClient';
import { CONVERSATIONS_LIST_KEY } from '../../../queries/conversations';
import { useTrack } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import {
    createOptimisticMessage,
    onError,
    onSuccess,
} from '../../MessageFormContainers/message.query';

export const useMessageCreateFromInbox = (
    conversationId?: Conversation['id'],
) => {
    const track = useTrack();
    const me = useMeQueryData();

    return useMutation({
        mutationFn: (message: CampaignOrMessageCreateParams) =>
            client
                .post<Message>('/messages/from-inbox', message)
                .then(({ data }) => data),
        onMutate: (message) => {
            if (!conversationId) {
                return { tempId: null };
            }

            return createOptimisticMessage(message.body, conversationId, me);
        },
        onSuccess: (message, _, { tempId }) => {
            track('message_created', {
                bodyLength: message.body.length,
                attachmentsCount: message.attached.length,
                status: message.status,
                origin: 'new_chat',
            });

            onSuccess(message, tempId);

            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
        },
        onError: (_, message, context) => {
            if (!context?.tempId || !conversationId) {
                return;
            }

            onError(
                {
                    ...message,
                    conversationId,
                },
                context.tempId,
            );
        },
    });
};
