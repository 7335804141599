import { Contact, Conversation, Feature, Message } from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { createNotification } from '../../notifications';
import { useNavigate } from 'react-router';
import { aggregator } from '../../components/Contacts/LookupAggregator/phone-lookup-aggregator';
import { useEnabledFeature, usePhoneNumberFormatter } from '../../queries/user';
import { queryClient } from '../../queries/queryClient';
import { CONTACT_BY_PHONE_KEY } from '../../components/Contacts/use-contact-by-phone';

type VisibilityChangeHandler = () => void;

const closeNotification = (
    notification: Notification,
    handleVisibilityChange: VisibilityChangeHandler,
) => {
    notification?.close();
    document.removeEventListener('visibilitychange', handleVisibilityChange);
};

export const useShowNotification = () => {
    const format = usePhoneNumberFormatter();
    const navigate = useNavigate();
    const track = useTrack();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return async (message: Message, conversation: Conversation) => {
        const contact =
            queryClient.getQueryData<Contact>([
                CONTACT_BY_PHONE_KEY,
                message.sender,
            ]) ||
            (await aggregator.getContact(
                message.sender,
                isMultipleContactPhonesEnabled,
            ));

        const title =
            contact?.name?.trim() ||
            contact?.email?.trim() ||
            format(message.sender);

        const notification = createNotification(title, {
            icon:
                message.attached.find(({ type }) => type.startsWith('image/'))
                    ?.url ||
                'https://clerk-static-assets.s3.amazonaws.com/clerk-logo-header.svg',
            requireInteraction: true,
            body: message.body,
            tag: conversation.id,
        });

        if (notification) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    closeNotification(notification, handleVisibilityChange);
                }
            };

            document.addEventListener(
                'visibilitychange',
                handleVisibilityChange,
            );

            // Activate browser window and tab if user clicks notification
            notification?.addEventListener('click', () => {
                navigate(
                    `/inbox/${conversation.inboxId}/conversations/${conversation.id}`,
                );
                track('browser_notification_clicked', {
                    target: 'conversation',
                });
                window.focus();
                closeNotification(notification, handleVisibilityChange);
            });
        }
    };
};
