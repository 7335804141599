import { NavigationArea } from '../../../hooks/use-keyboard-navigation';

export const handleChatKeyboardEvent = (e: KeyboardEvent) => {
    const parentArea = document.querySelector<HTMLElement>(
        `[data-navigation-area="${NavigationArea.Chat}"]`,
    );
    const leftArea = document.querySelector<HTMLElement>(
        `[data-navigation-area="${NavigationArea.Inbox}"]`,
    );

    const goToLeftArea = () => {
        if (leftArea) {
            const targetElement = leftArea.querySelector<HTMLAnchorElement>(
                `a[href="${window.location.pathname + window.location.search}"]`,
            );
            if (targetElement) {
                targetElement.focus();
            } else {
                leftArea.focus();
            }

            e.preventDefault();
        }
    };

    if (!parentArea) {
        return;
    }

    /* Get all elements with `data-navigation-element` in this area */
    const focusableElements = Array.from(
        parentArea.querySelectorAll<HTMLElement>('[data-navigation-element]'),
    );

    /* Get the currently focused element */
    const activeElement = document.activeElement as HTMLElement | null;
    const currentIndex = focusableElements.indexOf(
        activeElement ?? focusableElements[0],
    );
    let nextIndex: number | null = null;
    if (e.code === 'ArrowDown') {
        nextIndex =
            currentIndex === -1 || currentIndex === focusableElements.length - 1
                ? 0 // Loop back to the first element
                : currentIndex + 1; // Move to the next element
    } else if (e.code === 'ArrowUp') {
        nextIndex =
            currentIndex === -1 || currentIndex === 0
                ? focusableElements.length - 1 // Loop back to the last element
                : currentIndex - 1; // Move to the previous element
    } else if (e.code === 'Escape') {
        goToLeftArea();
    } else if (e.code === 'ArrowLeft') {
        const isSlateEditor =
            activeElement?.getAttribute('data-slate-editor') === 'true';

        const isInput =
            (activeElement?.tagName === 'INPUT' ||
                activeElement?.tagName === 'TEXTAREA') &&
            (activeElement as HTMLInputElement).type === 'text';

        if (!isSlateEditor && !isInput) {
            goToLeftArea();
        }
    }

    // Focus the next element, if applicable
    if (nextIndex !== null) {
        focusableElements[nextIndex].focus();
        e.preventDefault();
    }
};
