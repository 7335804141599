import { v4 as uuidv4 } from 'uuid';
import {
    Workflow,
    WorkflowAction,
    WorkflowActionTypes,
    WorkflowCondition,
    WorkflowConditionTypes,
    WorkflowStep,
    WorkflowStepType,
    WorkflowTrigger,
} from './types';
import { Icons } from '../../icons/iconsMap';

type Branch = 'left' | 'right';

export const findNode = (
    nodeId: string,
    step?: WorkflowStep,
): WorkflowStep | null => {
    if (!step) {
        return null;
    }
    if (step.id === nodeId) {
        return step;
    }

    if (step.type === WorkflowStepType.Action) {
        const action = step as WorkflowAction;
        return findNode(nodeId, action.nextStep);
    } else {
        const conditional = step as WorkflowCondition;
        return (
            findNode(nodeId, conditional.falsyStep) ||
            findNode(nodeId, conditional.truthyStep)
        );
    }
};

const findParentNode = (
    nodeId: string,
    step?: WorkflowStep,
): WorkflowStep | null => {
    if (!step) {
        return null;
    }

    if (step.type === WorkflowStepType.Action) {
        const action = step as WorkflowAction;
        if (action.nextStep?.id === nodeId) {
            return action;
        }

        return findParentNode(nodeId, action.nextStep);
    } else {
        const conditional = step as WorkflowCondition;
        if (conditional.truthyStep?.id === nodeId) {
            return conditional;
        }
        if (conditional.falsyStep?.id === nodeId) {
            return conditional;
        }

        return (
            findParentNode(nodeId, conditional.falsyStep) ||
            findParentNode(nodeId, conditional.truthyStep)
        );
    }
};

export const createNewStep = (
    stepName: WorkflowActionTypes | WorkflowConditionTypes,
): WorkflowStep | null => {
    switch (stepName) {
        // Acctions
        case WorkflowActionTypes.SendSMS:
            return {
                id: uuidv4(),
                type: WorkflowStepType.Action,
                action: WorkflowActionTypes.SendSMS,
            };
        case WorkflowActionTypes.Webhook:
            return {
                id: uuidv4(),
                type: WorkflowStepType.Action,
                action: WorkflowActionTypes.Webhook,
            };
        case WorkflowActionTypes.AddToCohort: {
            return {
                id: uuidv4(),
                type: WorkflowStepType.Action,
                action: WorkflowActionTypes.AddToCohort,
            };
        }
        case WorkflowActionTypes.AssignTeamMember: {
            return {
                id: uuidv4(),
                type: WorkflowStepType.Action,
                action: WorkflowActionTypes.AssignTeamMember,
            };
        }

        // Conditions
        case WorkflowConditionTypes.TimeFrame:
            return {
                id: uuidv4(),
                type: WorkflowStepType.Conditional,
                kind: WorkflowConditionTypes.TimeFrame,
            };
        case WorkflowConditionTypes.LastContacted:
            return {
                id: uuidv4(),
                type: WorkflowStepType.Conditional,
                kind: WorkflowConditionTypes.LastContacted,
            };
        case WorkflowConditionTypes.Schedule:
            return {
                id: uuidv4(),
                type: WorkflowStepType.Conditional,
                kind: WorkflowConditionTypes.Schedule,
            };

        case WorkflowConditionTypes.Filter: {
            return {
                id: uuidv4(),
                type: WorkflowStepType.Conditional,
                kind: WorkflowConditionTypes.Filter,
            };
        }
    }

    return null;
};

export const insertStep = (
    workflow: Workflow,
    childStep: WorkflowStep,
    parentId: string,
    branch?: Branch,
) => {
    const {
        spec: { steps },
    } = workflow;

    if (parentId === 'trigger') {
        if (childStep.type === WorkflowStepType.Action) {
            (childStep as WorkflowAction).nextStep = workflow.spec.steps;
            workflow.spec.steps = childStep;
        }
        if (childStep.type === WorkflowStepType.Conditional) {
            (childStep as WorkflowCondition).truthyStep = workflow.spec.steps;
            workflow.spec.steps = childStep;
        }

        return workflow;
    }

    const parentStep = findNode(parentId, steps);
    if (!parentStep) {
        return workflow;
    }

    // Insert into the parent action
    if (parentStep.type === WorkflowStepType.Action) {
        const parentAction = parentStep as WorkflowAction;

        if (childStep.type === WorkflowStepType.Action) {
            (childStep as WorkflowAction).nextStep = parentAction.nextStep;
            parentAction.nextStep = childStep;
        }
        if (childStep.type === WorkflowStepType.Conditional) {
            (childStep as WorkflowCondition).truthyStep = parentAction.nextStep;
            parentAction.nextStep = childStep;
        }
    }

    // Insert into the parent conditional to the left (falsy) branch
    if (branch === 'left' && parentStep.type === WorkflowStepType.Conditional) {
        const parentConditional = parentStep as WorkflowCondition;

        if (childStep.type === WorkflowStepType.Action) {
            (childStep as WorkflowAction).nextStep =
                parentConditional.falsyStep;
            parentConditional.falsyStep = childStep;
        }
        if (childStep.type === WorkflowStepType.Conditional) {
            (childStep as WorkflowCondition).truthyStep =
                parentConditional.falsyStep;
            parentConditional.falsyStep = childStep;
        }
    }

    // Insert into the parent conditional to the right (truthy) branch
    if (
        branch === 'right' &&
        parentStep.type === WorkflowStepType.Conditional
    ) {
        const parentConditional = parentStep as WorkflowCondition;

        if (childStep.type === WorkflowStepType.Action) {
            (childStep as WorkflowAction).nextStep =
                parentConditional.truthyStep;
            parentConditional.truthyStep = childStep;
        }
        if (childStep.type === WorkflowStepType.Conditional) {
            (childStep as WorkflowCondition).truthyStep =
                parentConditional.truthyStep;
            parentConditional.truthyStep = childStep;
        }
    }

    return workflow;
};

const findAndDelete = (nodeId: string, step?: WorkflowStep) => {
    if (!step) {
        return;
    }

    if (step.type === WorkflowStepType.Action) {
        const action = step as WorkflowAction;
        if (action.nextStep?.id === nodeId) {
            delete action.nextStep;
            return;
        }
        findAndDelete(nodeId, action.nextStep);
    } else {
        const conditional = step as WorkflowCondition;
        if (conditional.falsyStep?.id === nodeId) {
            delete conditional.falsyStep;
            return;
        }
        if (conditional.truthyStep?.id === nodeId) {
            delete conditional.truthyStep;
            return;
        }
        findAndDelete(nodeId, conditional.falsyStep);
        findAndDelete(nodeId, conditional.truthyStep);
    }
};

export const deleteNode = (workflow: Workflow, nodeId: string) => {
    const {
        spec: { steps },
    } = workflow;

    if (steps?.id === nodeId) {
        delete workflow.spec.steps;
        return workflow;
    }

    findAndDelete(nodeId, steps);

    return workflow;
};

const walkAndCount = (step?: WorkflowStep): number => {
    if (!step) {
        return 0;
    }

    if (step.type === WorkflowStepType.Action) {
        const action = step as WorkflowAction;
        return 1 + walkAndCount(action.nextStep);
    } else {
        const conditional = step as WorkflowCondition;
        return (
            1 +
            walkAndCount(conditional.truthyStep) +
            walkAndCount(conditional.falsyStep)
        );
    }
};

export const countNodes = (workflow: Workflow) => {
    const {
        spec: { steps },
    } = workflow;
    return walkAndCount(steps);
};

export const createNewWorkflow = (): Workflow => ({
    id: -1,
    name: 'Unnamed Workflow',
    enabled: true,
    spec: {},
    trigger: WorkflowTrigger.IncomingMessage,
    inboxes: [],
});

export const STEP_LABEL_MAP: Record<
    WorkflowActionTypes | WorkflowConditionTypes,
    string
> = {
    // Actions
    [WorkflowActionTypes.SendSMS]: 'Send message',
    [WorkflowActionTypes.Webhook]: 'Webhook',
    [WorkflowActionTypes.Sleep]: 'Sleep',
    [WorkflowActionTypes.AddToCohort]: 'Add to List',
    [WorkflowActionTypes.AssignTeamMember]: 'Assign to',
    // Conditions
    [WorkflowConditionTypes.NewContact]: 'New contact',
    [WorkflowConditionTypes.Property]: 'Property',
    [WorkflowConditionTypes.TimeFrame]: 'Time frame',
    [WorkflowConditionTypes.Schedule]: 'Schedule',
    [WorkflowConditionTypes.LastContacted]: 'Last contacted',
    [WorkflowConditionTypes.Filter]: 'Filter',
};

/** @deprecated */
export const STEP_ICON_MAP: Record<
    WorkflowActionTypes | WorkflowConditionTypes,
    Icons
> = {
    // Actions
    [WorkflowActionTypes.SendSMS]: 'message',
    [WorkflowActionTypes.Webhook]: 'website',
    [WorkflowActionTypes.Sleep]: 'sleep',
    [WorkflowActionTypes.AddToCohort]: 'add-cohort',
    [WorkflowActionTypes.AssignTeamMember]: 'assign-member',
    // Conditions
    [WorkflowConditionTypes.NewContact]: '',
    [WorkflowConditionTypes.Property]: '',
    [WorkflowConditionTypes.TimeFrame]: 'time-frame',
    [WorkflowConditionTypes.Schedule]: 'schedule',
    [WorkflowConditionTypes.LastContacted]: 'last-contacted',
    [WorkflowConditionTypes.Filter]: 'split',
};

export const updateStep = (workflow: Workflow, step: WorkflowStep) => {
    const {
        spec: { steps },
    } = workflow;

    if (step.id === steps?.id) {
        workflow.spec.steps = step;
        return workflow;
    }

    const parentNode = findParentNode(step.id, workflow.spec.steps);

    if (parentNode) {
        if (parentNode.type === WorkflowStepType.Action) {
            const actionParentNode = parentNode as WorkflowAction;
            actionParentNode.nextStep = step;
        } else {
            const conditionalParentNode = parentNode as WorkflowCondition;
            if (conditionalParentNode.truthyStep?.id === step.id) {
                conditionalParentNode.truthyStep = step;
            }
            if (conditionalParentNode.falsyStep?.id === step.id) {
                conditionalParentNode.falsyStep = step;
            }
        }
    }

    return workflow;
};
