import { ClerkIconProps } from '../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const CheckFilledIcon = (props: ClerkIconProps) => (
    <SvgIcon {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.5511 8.75871C17.8321 8.45434 17.8131 7.97985 17.5087 7.6989C17.2043 7.41794 16.7298 7.43692 16.4489 7.74129L10.0769 14.6443L7.5511 11.908C7.27015 11.6036 6.79566 11.5846 6.49129 11.8656C6.18692 12.1465 6.16794 12.621 6.4489 12.9254L9.52582 16.2587C9.6678 16.4125 9.8676 16.5 10.0769 16.5C10.2862 16.5 10.486 16.4125 10.628 16.2587L17.5511 8.75871Z"
            fill="currentColor"
        />
    </SvgIcon>
);
