import {
    Alert,
    AlertTitle,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { colors } from '../../../theme/palette';
import { Link } from 'react-router-dom';

type Props = {
    delay: number;
    isWorkspaceOwner: boolean;
};

export const DialCounter = ({ delay, isWorkspaceOwner }: Props) => {
    const delayInSeconds = delay / 1000;
    const [count, setCount] = useState(delayInSeconds);

    useEffect(() => {
        if (count > 0) {
            const timer = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [count]);
    return (
        <Stack justifyContent="space-between" height="100%">
            <Stack gap={6}>
                <Stack sx={{ width: 320 }} gap={10}>
                    <Stack alignItems="center" gap={4}>
                        <Typography fontSize={40} lineHeight="40px">
                            🙋🏻‍♂️
                        </Typography>
                        <Stack alignItems="center" gap={2}>
                            <Typography variant="h1">Hang on tight </Typography>
                            <Typography variant="body3" align="center">
                                We are about to connect you to the Clerk Chat
                                verification specialist.
                            </Typography>
                        </Stack>
                    </Stack>
                    {count > 0 ? (
                        <Stack gap={4} alignItems="center">
                            <Typography variant="body4">Calling in:</Typography>
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                            >
                                <CircularProgress
                                    variant="indeterminate"
                                    size={80}
                                    thickness={1}
                                    sx={{
                                        color: colors.info.main,
                                    }}
                                />
                                <Typography
                                    fontSize={32}
                                    position="absolute"
                                    color="textPrimary"
                                    fontWeight="bold"
                                >
                                    {count}
                                </Typography>
                            </Stack>
                        </Stack>
                    ) : (
                        <Alert
                            severity="success"
                            sx={{ mb: 3 }}
                            icon={<Typography fontSize={20}>✅</Typography>}
                        >
                            <AlertTitle>
                                You should be connected with a specialist
                            </AlertTitle>
                            If you call during off-hours, we will call you back
                            the next business day.
                        </Alert>
                    )}
                </Stack>
                <Stack alignItems="center">
                    <Typography variant="body3">
                        If the call didn’t go through, please
                    </Typography>
                    <Stack direction="row" gap={1}>
                        <Typography variant="body3">reach us at</Typography>
                        <Typography
                            variant="h4"
                            color="textPrimary"
                            sx={{ '& a': { color: colors.text.primary } }}
                        >
                            <Link
                                to={`tel:${import.meta.env.VITE_CLERK_SALES_PHONE}`}
                            >
                                +1 415 480 5480
                            </Link>
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                        <Typography variant="body3">Monday - Friday</Typography>
                        <Typography variant="body3">9 am - 5 pm PST</Typography>
                    </Stack>
                </Stack>
            </Stack>
            {isWorkspaceOwner && (
                <Stack direction="row" gap={1} mb={10}>
                    <Typography variant="body3">Wrong phone number?</Typography>
                    <Link to="/setup-inbox/existing-number">
                        {'Back to SMS setup ->'}
                    </Link>
                </Stack>
            )}
        </Stack>
    );
};
