import { SxProps, Theme } from '@mui/material';
import { colors } from '../../theme/palette';
import { getSquare } from '../../theme/style.helpers';

export const onboardingProgress: SxProps<Theme> = ({ palette }) => ({
    backgroundColor: palette.custom.gray.sideBack,
    py: { xs: 1, sm: 12 },
    px: { xs: 1, sm: 10 },
    display: 'flex',
    justifyContent: 'center',
    maxHeight: '100vh',
    height: { xs: 'calc(100% - 60px)', sm: 'auto' },
});

export const statusBlock: SxProps<Theme> = ({ palette }) => ({
    position: 'relative',
    backgroundColor: colors.primary.white,
    height: 1,
    maxHeight: { xs: 10000, sm: 800 },
    width: '100%',
    maxWidth: 744,
    border: '1px solid',
    borderColor: palette.custom.gray.light400,
    borderRadius: 1.5,
    display: 'flex',
    flexDirection: 'column',
});

export const content: SxProps = {
    flex: 1,
    px: { xs: 2, sm: 10 },
    pt: 10,
    overflow: 'auto',
    zIndex: 1,
};

export const onboardingFooter: SxProps<Theme> = ({ palette }) => ({
    backgroundColor: palette.custom.gray.superLight,
    height: 20,
    boxSizing: 'content-box',
    px: 10,
    py: 4.5,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 2,
    zIndex: 1,
});

export const progressStepIcon = (completed?: boolean): SxProps =>
    ({
        ...getSquare(40),
        background: completed ? colors.green[600] : colors.lightGrey[100],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        marginRight: 2,
    }) as SxProps;

export const icon = {
    ...getSquare(24),
    color: 'custom.primary.button.containedPrimaryHover',
    '&.MuiStepIcon-root': {
        marginRight: 8,
    },
} as SxProps;

export const canvas: SxProps = {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 0,
    gridColumn: '1/2',
    gridRow: '1/2',
    height: '100vh',
    width: 1,
};
