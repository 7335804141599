import { useRef, useState } from 'react';
import { SelectSuggestion } from './SelectSuggestion';
import {
    OnOptionCreateHandler,
    UserPropertyModel,
    ValueProps,
} from '../../types';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import {
    useDeletePropertyOption,
    useUpdatePropertyOption,
} from '../../../../queries/properties';
import { ContactPropertyType } from '../../../../api/types';
import { Box, Typography } from '@mui/material';
import { limitedValue } from '../styles';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';

interface SelectValueProps extends ValueProps<string> {
    contactId?: string;
    onCreate?: OnOptionCreateHandler;
}

const getLabel = (property: UserPropertyModel<string>) => {
    const { value, options = [] } = property;
    if (!value) {
        return '';
    }

    return options.find((opt) => opt === value) || '';
};

export const SelectValue = ({
    userProperty,
    contactId,
    onChange,
    onCreate,
    readonly,
}: SelectValueProps) => {
    const [isEditEnabled, setEditEnabled] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(selectRef, () => setEditEnabled(false));

    // Mutations
    const { mutateAsync: updateOption } = useUpdatePropertyOption(
        userProperty.id!,
        contactId,
    );
    const { mutate: deleteOption } = useDeletePropertyOption(
        userProperty.id!,
        contactId,
    );

    const label = getLabel(userProperty);

    if (isEditEnabled && !readonly) {
        return (
            <SelectSuggestion
                userProperty={userProperty}
                options={userProperty.options}
                wrapperRef={selectRef}
                onChange={(optionId) => {
                    onChange?.({ ...userProperty, value: optionId });
                    setEditEnabled(false);
                }}
                onCreate={(option) => {
                    onCreate?.(option)
                        .then((updatedUserProperty) => {
                            onChange?.({
                                ...updatedUserProperty,
                                value: option,
                            });
                        })
                        // eslint-disable-next-line no-console
                        .catch((e) => console.log(e));
                    setEditEnabled(false);
                }}
                onSave={(oldOption, newOption) =>
                    updateOption({
                        type: ContactPropertyType.Select,
                        oldOption,
                        newOption,
                    })
                }
                onRemove={(option) =>
                    deleteOption({
                        type: ContactPropertyType.Select,
                        option,
                    })
                }
            />
        );
    }

    return (
        <Box sx={limitedValue} onClick={() => setEditEnabled(true)}>
            {label ? (
                <Typography
                    aria-label={`${userProperty.name} value`}
                    variant="body3"
                    color="primary.dark"
                    noWrap
                    sx={{
                        display: 'inline-block',
                    }}
                >
                    {label}
                </Typography>
            ) : (
                <EmptyValuePlaceholder
                    text={readonly ? 'Empty' : undefined}
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
