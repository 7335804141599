import { useMemo } from 'react';
import { Contact, ContactPhone, Feature } from '../../../../api/types';
import { formatPhoneNumber } from '../../../../utils/phoneNumber';
import { useEnabledFeature, useMeQueryData } from '../../../../queries/user';
import { getPrimaryPhone } from '../../utils';
import { CampaignContact } from '../../../../api/campaign.types';

export function useContactNameAndPhone(
    contact: Contact | CampaignContact,
    notSelectedPhones: ContactPhone[] = contact.phones ?? [],
) {
    const countryCode = useMeQueryData()?.activeTeam?.countryCode ?? 'US';
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useMemo(() => {
        const primaryPhone = isMultipleContactPhonesEnabled
            ? getPrimaryPhone(notSelectedPhones)
            : contact;

        const name = contact.name?.trim() || contact.email?.trim();

        if (name && primaryPhone) {
            return {
                name,
                phone: formatPhoneNumber(primaryPhone?.phone, countryCode),
            };
        }

        return {
            name: formatPhoneNumber(primaryPhone?.phone, countryCode),
            phone: null,
        };
    }, [
        isMultipleContactPhonesEnabled,
        notSelectedPhones,
        contact,
        countryCode,
    ]);
}
