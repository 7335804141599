import { useMemo } from 'react';
import { typeIconMap } from '../typeIconMap';
import { ContactPropertyType } from '../../../api/types';
import { Box, Divider, Stack, TextField } from '@mui/material';
import { Header } from './Header';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Actions } from './Actions';

type FormFields = {
    label: string;
};

const schema = Yup.object({
    label: Yup.string().required("Property name can't be empty"),
});

type PropertyConfiguratorProps = {
    type: ContactPropertyType;
    name?: string;
    onNameChange: (name: string) => void;
    onClose: () => void;
};

export const PropertyConfigurator = ({
    onClose,
    onNameChange,
    type,
    name,
}: PropertyConfiguratorProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormFields>({
        defaultValues: {
            label: name ?? '',
        },
        resolver: yupResolver(schema),
    });

    const propertyInfo = useMemo(
        () => typeIconMap.find((item) => item.type === type),
        [type],
    );

    const onSubmit = ({ label }: FormFields) => {
        onNameChange(label);
    };

    return (
        <Box py={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box px={3} py={2}>
                    <Stack spacing={2}>
                        <Header title={propertyInfo?.name ?? ''} />

                        <TextField
                            {...register('label')}
                            fullWidth
                            autoFocus
                            size="small"
                            placeholder="Property name"
                            error={!!errors.label}
                            helperText={
                                errors.label ? errors.label.message : null
                            }
                        />
                    </Stack>
                </Box>
                <Divider sx={{ mb: 1 }} />
                <Actions onRemove={onClose} />
            </form>
        </Box>
    );
};
