import { useMeQueryData } from '../../../queries/user';
import { useMemo } from 'react';
import { useIsCampaignRegistered } from '../../../hooks/useIsCampaignRegistered';
import { useIsDownMd } from '../../../hooks/useIsDownMd';

export function useIs10DLCRequired() {
    const me = useMeQueryData();
    const isCampaignRegistered = useIsCampaignRegistered();
    const isMobile = useIsDownMd();

    return useMemo(() => {
        // hide on mobile
        if (isMobile) {
            return false;
        }

        // if user is not logged in or is on free tier
        if (!me || me.activeTeam.tier === 'free') {
            return false;
        }

        // if user is not registered for 10DLC yet
        return !isCampaignRegistered;
    }, [isMobile, me, isCampaignRegistered]);
}
