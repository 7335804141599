import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ChevronLeftDouble = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.52851 8.47141C3.26816 8.21106 3.26816 7.78895 3.52851 7.5286L7.52851 3.5286C7.78886 3.26825 8.21097 3.26825 8.47132 3.5286C8.73167 3.78895 8.73167 4.21106 8.47132 4.4714L4.94273 8L8.47132 11.5286C8.73167 11.7889 8.73167 12.2111 8.47132 12.4714C8.21097 12.7318 7.78886 12.7318 7.52851 12.4714L3.52851 8.47141Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.52851 8.47141C7.26816 8.21106 7.26816 7.78895 7.52851 7.5286L11.5285 3.5286C11.7889 3.26825 12.211 3.26825 12.4713 3.5286C12.7317 3.78895 12.7317 4.21106 12.4713 4.4714L8.94273 8L12.4713 11.5286C12.7317 11.7889 12.7317 12.2111 12.4713 12.4714C12.211 12.7318 11.7889 12.7318 11.5285 12.4714L7.52851 8.47141Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
