import { OptionWithDescription } from '../../../../elements/AdvancedSelect';
import { WorkflowRecipientType } from '../../types';

export const workflowRecipientOptions: OptionWithDescription[] = [
    {
        id: WorkflowRecipientType.Cohort,
        label: 'List',
        description: 'Send a campaign to all contacts in this list.',
    },
    {
        id: WorkflowRecipientType.Phone,
        label: 'Phone',
        description: 'Send to a phone number',
    },
    {
        id: WorkflowRecipientType.Sender,
        label: 'Sender',
        description: 'Send to the contact that triggered the workflow.',
    },
    {
        id: WorkflowRecipientType.Recipient,
        label: 'Recipient (outbound trigger only)',
        description:
            'Send to the recipient of message that started this workflow.',
    },
];
