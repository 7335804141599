import { useEffect, useMemo } from 'react';
import { useGetMicrosoftTeamsPhones } from '../../../queries/integrations';
import { StepShell } from '../../Onboarding/StepShell';
import Loading from '../../../components/Loading/Loading';
import { UUID } from '../../../types/uuid';
import { useMeQueryData } from '../../../queries/user';
import { useTrack } from '../../../contexts/analytics';
import { useNavigate } from 'react-router';
import { Button, Stack } from '@mui/material';
import { MSTeams } from '../../../icons/carriers/MSTeams';
import { RequestAdminAccess } from './RequestAdminAccess';
import { useSearchParams } from 'react-router-dom';

const buildLoginURL = (userId: UUID, teamId: UUID) => {
    const url = new URL(import.meta.env.VITE_MS_ADMIN_LOGIN_URL);
    url.searchParams.append(
        'state',
        JSON.stringify({
            userId,
            teamId,
            redirectUri: import.meta.env.VITE_MS_ADMIN_REDIRECT_URI,
            backUrl: window.location.pathname,
        }),
    );
    return url.href;
};

export const MSTeamsAuthStep = () => {
    /* Queries */
    const { error, isPending, refetch, isRefetching } =
        useGetMicrosoftTeamsPhones();
    const [searchParams] = useSearchParams();
    const me = useMeQueryData();
    const track = useTrack();
    const navigate = useNavigate();
    const auth = searchParams.get('authStatus');

    const showVideo = useMemo(() => Math.random() > 0.5, []);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (auth === 'failed') {
            navigate('/invited/ms-teams');
        }
        if (!error?.response?.status && !isRefetching && !isPending) {
            navigate('/setup-inbox/ms-teams/select');
        }
    }, [error, isRefetching, isPending, navigate, auth]);

    return (
        <StepShell
            title="Your Microsoft Teams Phones are Ready for SMS"
            icon={<MSTeams />}
            subtitle="👋🏻 Welcome! Let's get you set up with Clerk Chat so you can start texting with your business phone number. We'll guide you through the process, step-by-step."
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={12} width={480} alignItems="center" flex={1}>
                {isPending || isRefetching ? (
                    <Loading />
                ) : (
                    <Stack
                        spacing={4}
                        alignItems="center"
                        justifyContent="space-between"
                        flex={1}
                    >
                        <Button
                            onClick={() => {
                                track('clicked_authenticate_to_ms', {
                                    videoWasShown: showVideo,
                                });
                                window.open(
                                    buildLoginURL(me!.id, me!.activeTeam.id),
                                    '_blank',
                                    'noopener, noreferrer',
                                );
                            }}
                            sx={{ width: 320 }}
                        >
                            Next
                        </Button>
                        {showVideo ? (
                            <iframe
                                width="360"
                                height="215"
                                src="https://www.youtube.com/embed/4cw7piRMvo0?si=uQ-tsbW34lvxORhW"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                        ) : null}

                        <Stack spacing={18} alignItems="center">
                            <RequestAdminAccess />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </StepShell>
    );
};
