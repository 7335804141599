import { History } from '../../main/campaign/v3/History/History';
import {
    MessageForm,
    MessageFormData,
} from '../../components/MessageForm/MessageForm';
import { Box } from '@mui/material';
import { useTrack } from '../../contexts/analytics';
import { useToastContext } from '../../containers/toast/reducer';
import { useCampaign } from '../../queries/campaigns';
import {
    CampaignMessage,
    CampaignMessageStatus,
    useCampaignMessageUpdate,
    useCreateCampaignMessage,
} from '../../main/campaign/v3/use-campaign-messages';
import { useEffect, useMemo, useState } from 'react';
import { useConfirmSending } from '../../main/campaign/v3/use-confirm-sending';
import { CampaignType } from '../../api/campaign.types';
import { handleError } from '../../components/MessageFormContainers/utils';
import { history } from '../../main/campaign/v3/campaign.styles';
import { Nullable } from '../../helpers/types';
import { HEADER_HEIGHT, pixelize } from './inbox.styles';
import { useNavigate } from 'react-router';
import { EXTRA_OFFSET_ON_MOBILE } from './query.helpers';
import { useIs10DLCRequired } from '../../main/settings/Compliance/useIs10DLCRequired';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';
import { PaymentFailedBanner } from '../../components/Banner/PaymentFailedBanner';
import { useIsSendingBlocked } from '../../queries/user';

const messageToFormData = (
    message: Nullable<CampaignMessage>,
): MessageFormData | null => {
    if (!message) {
        return null;
    }
    return {
        message: message.body,
        sendAt: message.timestamp,
        attachments: message.attachments,
    };
};

type Props = {
    campaignId: number;
};

export const CampaignView = ({ campaignId }: Props) => {
    const isSendingBlocked = useIsSendingBlocked();
    const track = useTrack();
    const navigate = useNavigate();
    const create = useCreateCampaignMessage();
    const { dispatch: toastDispatch } = useToastContext();
    const [sendMessageAnnounce, setSendMessageAnnounce] = useState<
        string | null
    >(null);

    const { data } = useCampaign(campaignId);
    const [campaignMessage, setCampaignMessage] =
        useState<Nullable<CampaignMessage>>(null);
    const update = useCampaignMessageUpdate(campaignMessage?.id);
    const [confirm, ConfirmDialog] = useConfirmSending();

    const reset = () => setCampaignMessage(null);
    const is10DLCRequired = useIs10DLCRequired();

    useEffect(() => {
        if (
            data?.type &&
            ![CampaignType.INSTANT, CampaignType.PLANNED].includes(data.type)
        ) {
            track('campaign_redirected_to_old');
            navigate(`/campaigns/${data.id}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.type]);

    const initialMessage = useMemo(
        () => messageToFormData(campaignMessage),
        [campaignMessage],
    );

    if (!campaignId) {
        return null;
    }

    const handleMessage = async (data: MessageFormData) => {
        if (campaignMessage) {
            return update
                .mutateAsync({
                    body: data.message,
                    attachments: data.attachments?.map(({ id }) => id) ?? [],
                    timestamp: data.sendAt,
                })
                .then(() => {
                    setCampaignMessage(null);
                    setSendMessageAnnounce(
                        'Campaign message has been updated successfully',
                    );
                })
                .catch(() => {
                    handleError(toastDispatch);
                    setSendMessageAnnounce(
                        'Error occurred trying to update the campaign message',
                    );
                })
                .finally(() => {
                    setTimeout(() => {
                        setSendMessageAnnounce(null);
                    }, 5000);
                });
        }

        if (!(await confirm())) {
            return Promise.reject();
        }

        return create
            .mutateAsync({
                campaignId,
                attachments: data.attachments?.map(({ id }) => id),
                body: data.message,
                timestamp: data.sendAt ? new Date(data.sendAt) : undefined,
            })
            .then((response) => {
                reset();
                if (response?.status === CampaignMessageStatus.SmsExceeded) {
                    setSendMessageAnnounce(`Sms limit exceeded`);
                } else {
                    setSendMessageAnnounce(
                        'Campaign message has been sent successfully',
                    );
                }
            })
            .catch(() => {
                handleError(toastDispatch);
                setSendMessageAnnounce(
                    'Error occurred trying to sent the campaign message',
                );
            })
            .finally(() => {
                setTimeout(() => {
                    setSendMessageAnnounce(null);
                }, 5000);
            });
    };
    const topOffset = is10DLCRequired
        ? BANNER_HEIGHT + HEADER_HEIGHT
        : HEADER_HEIGHT;

    return (
        <Box
            sx={{
                display: 'grid',
                width: 1,
                gridTemplateRows: '1fr auto',
                height: [
                    `calc(100vh - ${pixelize(topOffset + EXTRA_OFFSET_ON_MOBILE)})`,
                    `calc(100vh - ${pixelize(topOffset + EXTRA_OFFSET_ON_MOBILE)})`,
                    `calc(100vh - ${pixelize(topOffset)})`,
                ],
            }}
        >
            <Box sx={{ overflow: 'hidden' }}>
                <Box sx={history}>
                    <History
                        campaignId={campaignId}
                        onEdit={(message) => {
                            setCampaignMessage(message);
                        }}
                    />
                </Box>
            </Box>
            <Box
                aria-live="assertive"
                aria-atomic="false"
                role="alert"
                style={{
                    display: sendMessageAnnounce?.length ? 'inline' : 'none',
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    margin: '-1px',
                    overflow: 'hidden',
                    clip: 'rect(0 0 0 0)',
                    border: '0',
                }}
            >
                {sendMessageAnnounce}
            </Box>
            <div>
                <PaymentFailedBanner />
                <MessageForm
                    initial={initialMessage}
                    onSend={handleMessage}
                    disabled={
                        create.isPending || update.isPending || isSendingBlocked
                    }
                    placeholder={`Send another message to ${data?.name}`}
                    templatesAvailable
                />
            </div>
            <ConfirmDialog contactsCount={data?.contactIds.length ?? 0} />
        </Box>
    );
};
