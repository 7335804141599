import { useMutation } from '@tanstack/react-query';
import {
    contactPhoneCreate,
    contactPhoneDelete,
    contactPhoneUpdate,
} from '../api/contacts';
import { queryClient } from './queryClient';
import { NEXT_CONTACTS } from '../components/Contacts/contacts.queries';
import { Contact, ContactPhone } from '../api/types';
import { CONTACT_BY_PHONE_KEY } from '../components/Contacts/use-contact-by-phone';
import { PagedQueryData } from '../types/PagedData';
import { CONTACT, CONTACTS_SEARCH_KEY } from './contacts';
import { useTrack } from '../contexts/analytics';

const CONTACT_PHONE_CREATE_KEY = 'contact_phone_create';
const CONTACT_PHONE_UPDATE_KEY = 'contact_phone_update';

export const useContactPhoneDelete = () => {
    const track = useTrack();
    return useMutation({
        mutationKey: [CONTACT_PHONE_UPDATE_KEY],
        mutationFn: contactPhoneDelete,
        onSuccess: () => {
            track('contact_phone_deleted');
        },
        onMutate: async (data) => {
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });
            await queryClient.cancelQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });

            queryClient
                .getQueriesData<Contact>({
                    queryKey: [CONTACT_BY_PHONE_KEY],
                    exact: false,
                })
                .forEach(([queryKey, found]) => {
                    if (found?.phones?.some((phone) => phone.id === data.id)) {
                        queryClient.invalidateQueries({ queryKey });
                    }
                });

            const current = queryClient.getQueryData<Contact>([
                CONTACT,
                data.id,
            ]);

            queryClient.setQueryData<Contact>([CONTACT, data.id], (prev) =>
                prev
                    ? {
                          ...prev,
                          phones: (prev?.phones ?? []).filter(
                              (prevPhone) => prevPhone.id !== data.phoneId,
                          ),
                      }
                    : undefined,
            );

            queryClient.setQueriesData<PagedQueryData<Contact[]>>(
                {
                    queryKey: [NEXT_CONTACTS],
                    exact: false,
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return {
                        ...prev,
                        pages: prev.pages.map((page) => ({
                            ...page,
                            data: page.data.map((prevContact) => {
                                if (prevContact.id === data.id) {
                                    return {
                                        ...prevContact,
                                        phones: (
                                            prevContact?.phones ?? []
                                        ).filter(
                                            (prevPhone) =>
                                                prevPhone.id !== data.phoneId,
                                        ),
                                    };
                                }
                                return prevContact;
                            }),
                        })),
                    };
                },
            );

            return { previousContact: current };
        },
        onError: (_err, contact, context) => {
            if (context!.previousContact) {
                queryClient.setQueryData<Contact>(
                    [CONTACT, contact.id],
                    context!.previousContact,
                );
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
};

export const useContactPhoneCreate = () => {
    const track = useTrack();
    return useMutation({
        mutationKey: [CONTACT_PHONE_CREATE_KEY],
        mutationFn: contactPhoneCreate,
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });
            await queryClient.cancelQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
        onSuccess: (created) => {
            track('contact_phone_created');
            queryClient.setQueryData(
                [CONTACT, created.contactId],
                (prev: Contact) => ({
                    ...prev,
                    phones: [...(prev?.phones ?? []), created],
                }),
            );
            queryClient.invalidateQueries({ queryKey: [NEXT_CONTACTS] });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
};

export const useContactPhoneUpdate = () => {
    const track = useTrack();
    return useMutation({
        mutationKey: [CONTACT_PHONE_UPDATE_KEY],
        mutationFn: contactPhoneUpdate,
        onMutate: async (contactPhone: ContactPhone) => {
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            queryClient
                .getQueriesData<Contact>({
                    queryKey: [CONTACT_BY_PHONE_KEY],
                    exact: false,
                })
                .forEach(([queryKey, found]) => {
                    if (
                        found?.phone === contactPhone.phone ||
                        found?.id === contactPhone.contactId
                    ) {
                        queryClient.invalidateQueries({ queryKey });
                    }
                });

            const current = queryClient.getQueryData<Contact>([
                CONTACT,
                contactPhone.contactId,
            ]);

            queryClient.setQueryData<Contact>(
                [CONTACT, contactPhone.contactId],
                (prev) =>
                    prev
                        ? {
                              ...prev,
                              phones: (prev?.phones ?? []).map((prevPhone) =>
                                  prevPhone.id === contactPhone.id
                                      ? contactPhone
                                      : prevPhone,
                              ),
                          }
                        : undefined,
            );

            queryClient.setQueriesData<PagedQueryData<Contact[]>>(
                {
                    queryKey: [NEXT_CONTACTS],
                    exact: false,
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return {
                        ...prev,
                        pages: prev.pages.map((page) => ({
                            ...page,
                            data: page.data.map((prevContact) => {
                                if (prevContact.id === contactPhone.contactId) {
                                    return {
                                        ...prevContact,
                                        phones: (prevContact.phones ?? []).map(
                                            (prevPhone: ContactPhone) =>
                                                prevPhone.id === contactPhone.id
                                                    ? contactPhone
                                                    : prevPhone,
                                        ),
                                    };
                                }
                                return prevContact;
                            }),
                        })),
                    };
                },
            );

            return { previousContact: current };
        },
        onError: (_err, contact, context) => {
            if (context!.previousContact) {
                queryClient.setQueryData<Contact>(
                    [CONTACT, contact.id],
                    context!.previousContact,
                );
            }
        },
        onSuccess: (updated) => {
            track('contact_phone_updated');
            queryClient.setQueryData(
                [CONTACT, updated.contactId],
                (prev: Contact) => ({
                    ...prev,
                    phones: prev?.phones?.map((prevPhone) =>
                        prevPhone.id === updated.id
                            ? updated
                            : updated.isPrimary
                              ? { ...prevPhone, isPrimary: false }
                              : prevPhone,
                    ),
                }),
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
};
