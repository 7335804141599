import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Stack,
    Typography,
} from '@mui/material';
import { useSendAdminEmail } from '../hooks/use-send-admin-email';

type Mode = 'request' | 'finalize';

type Props = {
    mode: Mode;
};

export const AdminMailRequest = ({ mode }: Props) => {
    const { isSend, sendEmail } = useSendAdminEmail();
    return (
        <Stack sx={{ width: 320 }} gap={10}>
            <Stack alignItems="center" gap={4}>
                <Typography fontSize={40} lineHeight="40px">
                    💻
                </Typography>
                <Stack alignItems="center" gap={2}>
                    <Typography variant="h1">
                        {`You don't have permission`}
                    </Typography>
                    <Typography variant="body3" align="center">
                        To {mode} your activation please reach out to your IT
                        admin.
                    </Typography>
                </Stack>
            </Stack>
            <Button onClick={!isSend ? sendEmail : () => ({})} color="info">
                Send a request
            </Button>
            <Box>
                {isSend && (
                    <Alert
                        severity="success"
                        icon={<Typography fontSize={20}>✅</Typography>}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '.MuiAlert-icon': { pt: 0 },
                        }}
                    >
                        <AlertTitle sx={{ mb: 0 }}>
                            We sent a note to workspace owner.
                        </AlertTitle>
                    </Alert>
                )}
            </Box>
        </Stack>
    );
};
