import React, { useState } from 'react';

export type MenuProps = {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void;
};

export const useMenu = (): MenuProps => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return { anchorEl, open, handleOpen, handleClose };
};
