import { SxProps, Theme } from '@mui/material';

export const textField: SxProps<Theme> = {
    '& .MuiInputBase-root.MuiFilledInput-root': {
        py: 3,
        px: 6,
        background: 'transparent',
        '&:focus, &:hover, &:active': {
            background: 'transparent',
            borderColor: 'custom.gray.divider',

            '&:before': {
                borderColor: 'custom.gray.divider',
            },
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '&:before': {
            borderColor: 'custom.gray.divider',
        },
    },
    '& .MuiInputAdornment-root': {
        width: 20,
        height: 20,
    },
};

export const textInput: SxProps<Theme> = {
    minHeight: 60,
    height: 'auto',
};

export const groupLabel: SxProps<Theme> = {
    pt: 4,
    pb: 2,
    px: 4,
    display: 'flex',
};

export const groupList: SxProps<Theme> = {
    py: 0,
    px: 2,
};

export const paper: SxProps<Theme> = {
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'custom.gray.tableDivider',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: 'white',
    pb: 2,
    boxShadow: 'none',

    '& .MuiAutocomplete-listbox': {
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
    },
};

export const listItem: SxProps<Theme> = {
    paddingX: 3,
    paddingY: 2,
    marginBottom: 1,
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    border: '1px solid',
    borderColor: 'white',

    '&:hover, &.Mui-focusVisible': {
        background: (theme) => theme.palette.custom.action.hover,
    },
};

export const expandedListItem: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 1,
    background: (theme) => theme.palette.custom.action.selected,

    '&.Mui-focusVisible': {
        background: (theme) => theme.palette.custom.action.hover,
    },
};

export const extraPhoneItem: SxProps<Theme> = {
    width: 1,
    py: 1.5,
    pl: 10,
    pr: 3,
    mb: 1,
    border: '1px solid white',

    '&:hover, &.Mui-focusVisible': {
        background: (theme) => theme.palette.custom.action.hover,
    },
};

export const tag: SxProps = { m: 1 };
