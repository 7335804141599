import { SxProps, Theme } from '@mui/material';
import { shadows } from '../../../../../theme/shadows';
import { focusVisible } from '../../../../../theme/focusVisible';

export const fileInput: SxProps = {
    display: 'none',
};
export const removeButton: SxProps<Theme> = {
    position: 'absolute',
    bgcolor: 'white',
    boxShadow: shadows[1],
    color: 'info.main',
    border: '1px solid',
    borderColor: 'primary.outlinedBorder',
    opacity: 0,
    top: -8,
    right: -8,
    width: 20,
    height: 20,
    zIndex: 2,
    borderRadius: 1.5,
    '&:focus-visible': {
        ...focusVisible,
        opacity: 1,
    },
};

const attachment: SxProps = {
    position: 'relative',
    borderRadius: 1.5,
    '&:hover, &:focus-visible': {
        '.MuiIconButton-root': {
            opacity: 1,
        },
        '.overlay': {
            opacity: 0.16,
            transition: 'opacity 250ms ease-in-out',
        },
    },
};

export const imageAttachment: SxProps = {
    ...attachment,
    width: 48,
    height: 48,
};

export const img: SxProps = {
    borderRadius: 1.5,
    width: 1,
    height: 1,
    maxWidth: 1,
    maxHeight: 1,
    objectFit: 'cover',
};

export const overlay: SxProps = {
    width: 1,
    height: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    borderRadius: 1.5,
    opacity: 0,
    backgroundColor: '#1F2936',
};

export const fileAttachment: SxProps = {
    ...attachment,
    display: 'flex',
    position: 'relative',
    gap: 2,
    p: 2,
    borderRadius: 1.5,
    border: '1px solid ',
    borderColor: 'custom.gray.light400',
    height: 48,
};

export const attachmentTitle: SxProps = {
    maxWidth: 149,
};

export const attachmentsHolder: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 3,
    maxWidth: 1,
};
