import { Box, Divider } from '@mui/material';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useCallback, useState } from 'react';
import { useMeQueryData } from '../../../queries/user';
import { RoleTypes } from '../../../api/types';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';
import { InviteButton } from './InviteButton';
import { useTrack } from '../../../contexts/analytics';
import { ToastActions, useToastContext } from '../../toast/reducer';
import { focusVisible } from '../../../theme/focusVisible';

export const SideBarInvite = () => {
    const [showInviteModal, setShowInviteModal] = useState(false);
    const { dispatch: toastDispatch } = useToastContext();
    const track = useTrack();
    const me = useMeQueryData();

    const handleClick = () => {
        track('clicked_invite_team');
        setShowInviteModal(true);
    };

    const handleSuccessInvite = useCallback(() => {
        toastDispatch({
            type: ToastActions.ADD,
            payload: {
                severity: 'success',
                title: 'Invitation was sent',
                description:
                    'Once your teammate accepts the invitation, they will be able to use Clerk Chat.',
            },
        });
        setShowInviteModal(false);
    }, [toastDispatch]);

    return (
        <>
            <Box
                sx={{
                    px: 2,
                    py: 3,
                    mx: 2,
                    borderRadius: 1.5,
                    '&:focus-visible': {
                        ...focusVisible,
                        outlineOffset: '-1px',
                        borderRadius: 1,
                    },
                }}
                data-navigation-element
                tabIndex={0}
            >
                <InviteButton onClick={handleClick} />
            </Box>
            <Divider />
            {[RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
                me?.activeRole?.type as RoleTypes,
            ) ? (
                <InviteUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                    onSuccess={handleSuccessInvite}
                />
            ) : (
                <InviteMsTeamsUserModal
                    open={showInviteModal}
                    onClose={() => setShowInviteModal(false)}
                    onSuccess={handleSuccessInvite}
                />
            )}
        </>
    );
};
