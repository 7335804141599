import { useMemo } from 'react';
import { useInboxes } from '../queries/inboxes';
import { useTeammates } from '../queries/user';
import { RoleTypes } from '../api/types';

export const useLicenseCount = () => {
    const { data: teammates = [] } = useTeammates();
    const { data: inboxes = [] } = useInboxes();

    const nonAdmins = useMemo(
        () =>
            teammates.filter(
                (teammate) => teammate.role?.type !== RoleTypes.Admin,
            ),
        [teammates],
    );

    return useMemo(
        () => Math.max(nonAdmins.length, inboxes.length),
        [nonAdmins.length, inboxes.length],
    );
};
