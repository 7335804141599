import { useTeammates } from '../queries/user';
import { RoleTypes } from '../api/types';

export const useWorkspaceOwners = () => {
    const { data: teammates = [] } = useTeammates();
    return teammates.filter(
        (teammate) =>
            teammate?.role?.type === RoleTypes.WorkspaceOwner ||
            teammate?.role?.type === RoleTypes.Admin,
    );
};
