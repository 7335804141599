import { Box, IconButton, MenuItem, Stack, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { expandedListItem, listItem } from './styles';
import { Contact, ContactPhone, Feature } from '../../../api/types';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { useEnabledFeature } from '../../../queries/user';
import { useContactNameAndPhone } from './hooks/useContactNameAndPhone';
import { ExpandMoreFilledIcon } from '../../../icons/common/ExpandMoreFilled';
import { ExpandLessFilledIcon } from '../../../icons/common/ExpandLessFilled ';

type Props = {
    contact: Contact;
    props: React.HTMLAttributes<HTMLLIElement> & {
        key?: string;
    };
    expanded?: boolean;
    setExpanded: Dispatch<SetStateAction<Contact | null>>;
    notSelectedPhones: ContactPhone[];
};

export function ContactMenuItem({
    contact,
    expanded = false,
    props: { key: _, className: _cn, ...props },
    setExpanded,
    notSelectedPhones,
}: Props) {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const { name, phone } = useContactNameAndPhone(contact, notSelectedPhones);

    return (
        <MenuItem
            {...props}
            sx={expanded ? expandedListItem : listItem}
            data-testid="popper-result-list-item"
        >
            <Stack
                width={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <UserAvatar
                        size={20}
                        username={contact.name}
                        src={contact.avatarURL}
                    />
                    <Stack ml={2} spacing={1}>
                        <Typography
                            variant="body3"
                            color="primary.dark"
                            data-testid="popper-contact-name"
                        >
                            {name}
                        </Typography>
                        {phone && (
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                                data-testid="popper-contact-phone"
                            >
                                {phone}
                            </Typography>
                        )}
                    </Stack>
                </Box>
                {isMultipleContactPhonesEnabled &&
                    (notSelectedPhones?.length ?? 0) > 1 && (
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded((prev) =>
                                    prev?.id === contact.id ? null : contact,
                                );
                            }}
                        >
                            {expanded ? (
                                <ExpandLessFilledIcon />
                            ) : (
                                <ExpandMoreFilledIcon />
                            )}
                        </IconButton>
                    )}
            </Stack>
        </MenuItem>
    );
}
