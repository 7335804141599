import { queryClient } from './queryClient';
import {
    getMe,
    getReseller,
    getSettings,
    inactivateUser,
    resetMicrosoftGraphCredentials,
    updateMe,
    updateMeSettings,
} from '../api/user';
import {
    Feature,
    Invite,
    Profile,
    RoleTypes,
    UserTeamSetting,
    UserTeamSettingType,
} from '../api/types';
import {
    QueryObserverOptions,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import { UUID } from '../types/uuid';
import { useEffect } from 'react';
import { formatPhoneNumber } from '../utils/phoneNumber';
import { client } from '../api/http';
import { getName } from '../main/campaign/v3/get-profile-name';
import { identify } from '../services/analytics';
import { useTrack } from '../contexts/analytics';
import dayjs from 'dayjs';

export const CURRENT_USER_KEY = 'me';
const CURRENT_USER_SETTINGS = 'me_settings';
export const TEAMMATES_KEY = 'teammates';
const INVITES_KEY = 'invites';

export const useIsMuted = (inboxId: UUID): boolean => {
    const me = useMeQueryData();
    return me?.mutedInboxIds.includes(inboxId) ?? false;
};

export function useMeQuery() {
    const query = useQuery({
        queryKey: [CURRENT_USER_KEY],
        queryFn: getMe,
        retry: 0,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (query.data) {
            identify(query.data);
        }
    }, [query.data]);

    return query;
}

export const useIsPaymentFailed = (): Date | null => {
    const date = useMeQueryData()?.activeTeam?.paymentFailedAt;

    return date ? new Date(date) : null;
};

export const useIsSendingBlocked = (): boolean => {
    const date = useMeQueryData()?.activeTeam?.paymentFailedAt;

    return date ? dayjs(date).isBefore(dayjs().subtract(2, 'weeks')) : false;
};

export const getCurrentUser = () =>
    queryClient.getQueryData<Profile>([CURRENT_USER_KEY]);

export function useMeQueryData() {
    return queryClient.getQueryData<Profile>([CURRENT_USER_KEY]);
}

export const isFree = (tier?: string) => ['free', 'fraud'].includes(tier || '');

export const checkIsFreeTeam = () => isFree(getCurrentUser()?.activeTeam?.tier);

export function useEnabledFeature(feature: Feature): boolean {
    const me = queryClient.getQueryData<Profile>([CURRENT_USER_KEY]);
    return me?.features.some((f) => f.name === feature && f.enabled) || false;
}

export function useUpdateMeSettings() {
    return useMutation({
        mutationKey: ['updateMeSettings'],
        mutationFn: updateMeSettings,
        onMutate: async (setting) => {
            await queryClient.cancelQueries({
                queryKey: [CURRENT_USER_SETTINGS, setting.type],
            });

            const previousSetting = queryClient.getQueryData([
                CURRENT_USER_SETTINGS,
                setting.type,
            ]);
            const previousSettings =
                queryClient.getQueryData([CURRENT_USER_SETTINGS]) || [];

            queryClient.setQueryData(
                [CURRENT_USER_SETTINGS, setting.type],
                /*todo: @will check the correctness `settingS` possibly used insted of `settinG` */
                // @ts-ignore
                (_settings: UserTeamSetting) => [...previousSettings, setting],
            );

            return { previousSettings, previousSetting };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(
                // @ts-ignore
                [CURRENT_USER_SETTINGS, context.previousSetting.type],
                () => [
                    ...(context?.previousSettings || []),
                    context?.previousSetting,
                ],
            );
        },
        onSettled: (setting) => {
            return queryClient.invalidateQueries({
                queryKey: [CURRENT_USER_SETTINGS, setting.type],
            });
        },
    });
}
const initTime = new Date().getTime();
export function useGetSetting(type: UserTeamSettingType) {
    return useQuery({
        queryKey: [CURRENT_USER_SETTINGS, type],
        select: (data: UserTeamSetting[]) => data.find((d) => d.type === type),
        queryFn: getSettings,
        refetchOnMount: ({ state }) =>
            state.dataUpdatedAt < initTime ? 'always' : false,
    });
}

export const useUpdateMe = () =>
    useMutation({
        mutationKey: ['updateMe'],
        mutationFn: updateMe,
        onMutate: async (newMe) => {
            await queryClient.cancelQueries({ queryKey: [CURRENT_USER_KEY] });

            const previousMe = queryClient.getQueryData([CURRENT_USER_KEY]);

            queryClient.setQueryData(
                [CURRENT_USER_KEY],
                (me: Profile | undefined) => {
                    return { ...me, ...newMe };
                },
            );

            return { previousMe };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData([CURRENT_USER_KEY], context?.previousMe);
        },
        onSettled: () => {
            return queryClient.invalidateQueries({
                queryKey: [CURRENT_USER_KEY],
            });
        },
    });

export const useToggleInboxMute = () => {
    const me = useMeQueryData();
    const update = useUpdateMe();
    return {
        ...update,
        mutate: (inboxId: UUID) => {
            const mutedInboxIds = me?.mutedInboxIds.includes(inboxId)
                ? me.mutedInboxIds.filter((id) => id !== inboxId)
                : [...(me?.mutedInboxIds || []), inboxId];
            return update.mutate({ mutedInboxIds });
        },
        mutateAsync: (inboxId: UUID) => {
            const mutedInboxIds = me?.mutedInboxIds.includes(inboxId)
                ? me.mutedInboxIds.filter((id) => id !== inboxId)
                : [...(me?.mutedInboxIds || []), inboxId];
            return update.mutateAsync({ mutedInboxIds });
        },
    };
};

export const useInactivateUser = () =>
    useMutation({
        mutationFn: inactivateUser,
        onSettled: () => {
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.refetchQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });

// Teammates

export const useTeammates = (options?: {
    disabled?: boolean;
    select?: QueryObserverOptions<Profile[]>['select'];
}) =>
    useQuery({
        queryKey: [TEAMMATES_KEY],
        queryFn: () =>
            client.get<Profile[]>('/users/teammates').then(({ data }) => data),
        staleTime: Infinity,
        refetchOnMount: ({ state }) =>
            state.dataUpdatedAt < initTime ? 'always' : false,
        enabled: !options?.disabled,
        select: options?.select,
    });

const getTeammates = () =>
    queryClient.getQueryData<Profile[]>([TEAMMATES_KEY]) ?? [];

// Invites

export const useInvites = () =>
    useQuery({
        queryKey: [INVITES_KEY],
        queryFn: () =>
            client.get<Invite[]>('/invites').then(({ data }) => data),
    });

export const useInviteTeamMembers = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (body: {
            emails: string[];
            role?: RoleTypes;
            inboxIds: UUID[];
        }) => client.post<void>('/invites', body).then(({ data }) => data),
        onSuccess: (_, { emails }) => {
            track('invite_sent', { count: emails?.length ?? 0 });
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });
};

export const useDeleteInvite = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (id: string) =>
            client.delete(`/invites/${id}`).then<void>(({ data }) => data),
        onSuccess: () => {
            track('invite_deleted');
            queryClient.refetchQueries({ queryKey: [CURRENT_USER_KEY] });
            queryClient.invalidateQueries({ queryKey: [INVITES_KEY] });
        },
    });
};

export const useResendInvite = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: (id: string) =>
            client.post<void>(`/invites/${id}/resend`).then(({ data }) => data),
        onSuccess: () => {
            track('invite_resent');
        },
    });
};

export const usePhoneNumberFormatter = () => {
    const me = useMeQueryData();
    return (phone: string) =>
        formatPhoneNumber(phone, me?.activeTeam.countryCode);
};

export const useResetMicrosoftGraphCredentials = () =>
    useMutation({ mutationFn: resetMicrosoftGraphCredentials });

export const getTeammate = (id: UUID) =>
    getTeammates().find((profile) => profile.id === id);

export const getTeammateName = (id: UUID) => {
    const teammate = getTeammate(id);
    return teammate ? getName(teammate) : '';
};

export const useReseller = () => {
    const me = useMeQueryData();
    return useQuery({
        queryKey: ['reseller'],
        queryFn: getReseller,
        enabled: me?.activeTeam.resellerId !== null,
    });
};
