import SideBarInboxes from './SideBarInboxes';
import SideBarMutedInboxes from './SideBarMutedInboxes';
import TierUpgradeOverlay from '../../components/TierUpgradeOverlay';
import SideBarInvite from './SideBarInvite';
import { Box, IconButton, Stack } from '@mui/material';
import { WithPermission } from '../WithPermission/WithPermission';
import { ClerkPermission, Feature } from '../../api/types';
import { useMatch } from 'react-router';
import { SideBarNavigation } from './SideBarNavigation/SideBarNavigation';
import { TeamSelector } from '../../components/TeamSelector/TeamSelector';
import { useMeQueryData } from '../../queries/user';
import { MenuIcon } from '../SettingsLayout/MenuIcon';
import { colors } from '../../theme/palette';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { HideUpMd } from '../../components/MediaHelpers/HideUpMd';
import { useTrack } from '../../contexts/analytics';
import { aside, content, section, topRow } from './styles';
import { WithFeature } from '../Feature/WithFeature';
import { InboxesPopover } from './InboxesPopover';
import { HideDownMd } from '../../components/MediaHelpers/HideDownMd';
import { SidebarDrawer } from './SidebarDrawer';
import { useSidebar } from '../../contexts/sidebar';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { NavigationArea } from '../../hooks/use-keyboard-navigation';
import { focusVisible } from '../../theme/focusVisible';

const SideBar = () => {
    const track = useTrack();
    const isFree = useMeQueryData()?.activeTeam?.tier === 'free';
    const { isOpened, toggleOpen, isCollapsed } = useSidebar();
    const isMobile = useIsDownMd();
    const isShrinkMode = isCollapsed && !isMobile;

    return (
        <>
            <HideUpMd>
                <Stack gap={3} direction="row" sx={topRow}>
                    <TeamSelector />
                    <Box sx={{ bgcolor: colors.primary.gray.active }}>
                        <IconButton
                            aria-label={
                                isOpened
                                    ? 'Close mobile menu'
                                    : 'Open mobile menu'
                            }
                            sx={{
                                width: 60,
                                height: 1,
                            }}
                            onClick={() => {
                                track('sidebar_menu_toggled', {
                                    open: !open,
                                });
                                toggleOpen();
                            }}
                            data-navigation-element
                        >
                            {isOpened ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    </Box>
                </Stack>
            </HideUpMd>
            <SidebarDrawer>
                <Box
                    component="aside"
                    sx={[
                        aside,
                        {
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '-1px',
                            },
                        },
                    ]}
                    tabIndex={0}
                    data-navigation-area={NavigationArea.MainSidebar}
                    aria-label="Application sidebar"
                >
                    <HideDownMd>
                        <Stack
                            gap={3}
                            direction="row"
                            sx={topRow}
                            pr={!isCollapsed ? 4 : 0}
                        >
                            <TeamSelector />
                        </Stack>
                    </HideDownMd>
                    <Box
                        sx={content}
                        className={isOpened ? 'isMenuOpen' : undefined}
                    >
                        <SideBarNavigation setMenuOpen={toggleOpen} />

                        <Box sx={section}>
                            <WithPermission
                                requiredPermissions={[
                                    ClerkPermission.AddTeamMembers,
                                ]}
                            >
                                <WithFeature feature={Feature.InviteTeammate}>
                                    <HideDownMd>
                                        <SideBarInvite />
                                    </HideDownMd>
                                </WithFeature>
                            </WithPermission>
                            {isShrinkMode ? (
                                <InboxesPopover />
                            ) : (
                                <>
                                    <SideBarInboxes setMenuOpen={toggleOpen} />
                                    <SideBarMutedInboxes
                                        setMenuOpen={toggleOpen}
                                    />
                                </>
                            )}

                            <Box mt="auto">
                                {isFree && !isShrinkMode && (
                                    <UpgradeComponent />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SidebarDrawer>
        </>
    );
};

const UpgradeComponent = () => {
    const isMatch = useMatch('/payment/*');
    return (
        !isMatch && (
            <Box pt={4}>
                <TierUpgradeOverlay />
            </Box>
        )
    );
};

export default SideBar;
