import sflogo from '../../../assets/images/sf.svg';
import hubspotLogo from '../../../assets/images/hubspot.svg';
import googlelogo from '../../../assets/images/google.svg';
import csvlogo from '../../../assets/images/csv.svg';
import shopifylogo from '../../../assets/images/shopify-icon.svg';
import dynamicslogo from '../../../assets/images/dynamics-logo.svg';
import linkedinlogo from '../../../assets/images/linkedin.svg';
import pipedrivelogo from '../../../assets/images/pipedrive-logo.svg';
import teamslogo from '../../../assets/images/teams.svg';
import globalrelaylogo from '../../../assets/images/global-relay-logo.png';
import onedrivelogo from '../../../assets/images/onedrive-logo.png';
import stripelogo from '../../../assets/images/stripe.svg';
import {
    Feature,
    IntegrationSource,
    IntegrationTypes,
} from '../../../api/types';
import smarshlogo from '../../../assets/images/smarsh-logo.svg';
import { FilterTabs } from './Integrations';

export type AvailableIntegration = {
    name: string;
    icon: string;
    desc: string;
    source: IntegrationSource;
    comingSoon: boolean;
    url?: string;
    extraSettings?: string[];
    hasSettings?: boolean;
    forFeature?: Feature;
    integrationType?:
        | IntegrationTypes.Contact
        | IntegrationTypes.Logs
        | IntegrationTypes.Data
        | IntegrationTypes.Application;
    filter?: FilterTabs;
};

// The order of items in the list matters!
export const availableIntegrations: AvailableIntegration[] = [
    {
        name: 'Microsoft Outlook',
        icon: 'https://clerk-static-assets.s3.amazonaws.com/outlook-logo.svg',
        desc: 'Import your contacts from Outlook in to Clerk Chat',
        source: IntegrationSource.Microsoft,
        comingSoon: false,
        url: import.meta.env.VITE_MS_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'Microsoft Dynamics',
        icon: dynamicslogo,
        desc: 'Microsoft Business CRM',
        source: IntegrationSource.Dynamics,
        comingSoon: false,
        url: import.meta.env.VITE_DYNAMICS_LOGIN_URL,
        extraSettings: ['workspaceURL'],
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'Calling Analytics',
        icon: teamslogo,
        desc: 'Analyze your Microsoft Teams calling activity',
        source: IntegrationSource.Microsoft,
        url: import.meta.env.VITE_MS_LOGIN,
        comingSoon: false,
        integrationType: IntegrationTypes.Data,
        forFeature: Feature.CallingStatistics,
        filter: 'compliance',
    },
    {
        name: 'Salesforce CRM',
        icon: sflogo,
        desc: "The world's most popular CRM",
        source: IntegrationSource.Salesforce,
        comingSoon: false,
        url: import.meta.env.VITE_SF_LOGIN_URL,
        hasSettings: true,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'Smarsh',
        icon: smarshlogo,
        desc: 'Helping companies manage the risk in their electronic communications. Cloud-based capture, archiving and supervision solutions across more than 80 channels.',
        source: IntegrationSource.Smarsh,
        comingSoon: false,
        integrationType: IntegrationTypes.Logs,
        extraSettings: [''],
        filter: 'compliance',
    },
    {
        name: 'Global Relay',
        icon: globalrelaylogo,
        desc: 'Technology services company providing software-as-a-service electronic message archiving',
        source: IntegrationSource.GlobalRelay,
        comingSoon: false,
        url: 'mailto:support@clerk.chat?subject=Global%20Relay%20Integration%20Request',
        integrationType: IntegrationTypes.Logs,
        filter: 'compliance',
    },
    {
        name: 'Microsoft Azure Storage',
        icon: onedrivelogo,
        desc: 'Microsoft Azure Storage is a cloud service for storing large amounts of unstructured data',
        source: IntegrationSource.Azure,
        comingSoon: false,
        integrationType: IntegrationTypes.Logs,
        extraSettings: [''],
        filter: 'compliance',
    },
    {
        name: 'Purview',
        icon: dynamicslogo,
        desc: 'Export logs to Purview',
        source: IntegrationSource.Purview,
        comingSoon: false,
        integrationType: IntegrationTypes.Logs,
        extraSettings: [''],
        filter: 'compliance',
    },
    {
        name: 'HubSpot',
        icon: hubspotLogo,
        desc: 'All-in-one CRM platform for marketing, sales, and customer service',
        source: IntegrationSource.Hubspot,
        comingSoon: !import.meta.env.VITE_HUBSPOT_LOGIN_URL,
        url: import.meta.env.VITE_HUBSPOT_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'CSV Import',
        icon: csvlogo,
        desc: 'Upload a custom CSV and let our team handle the rest!',
        source: IntegrationSource.CSV,
        integrationType: IntegrationTypes.Data,
        comingSoon: false,
        filter: 'contact',
    },
    {
        name: 'Shopify',
        icon: shopifylogo,
        desc: 'The online Ecom platform',
        source: IntegrationSource.Shopify,
        comingSoon: false,
        extraSettings: ['shop_url'],
        integrationType: IntegrationTypes.Contact,
        url: import.meta.env.VITE_SHOPIFY_OAUTH_URL,
        filter: 'contact',
    },
    {
        name: 'PipeDrive',
        icon: pipedrivelogo,
        desc: 'The Original Pipeline CRM',
        source: IntegrationSource.Pipedrive,
        comingSoon: false,
        url: import.meta.env.VITE_PIPEDRIVE_LOGIN_URL,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'Google Contacts',
        icon: googlelogo,
        desc: "Google contacts is Google's native CRM",
        source: IntegrationSource.Google,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'LinkedIn',
        icon: linkedinlogo,
        desc: 'The employee social network',
        source: IntegrationSource.Linkedin,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
    {
        name: 'Stripe',
        icon: stripelogo,
        desc: 'Import Customers from Stripe',
        source: IntegrationSource.Stripe,
        comingSoon: true,
        integrationType: IntegrationTypes.Contact,
        filter: 'contact',
    },
];
