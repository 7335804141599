import { useState } from 'react';
import styles from './AddNewProperty.module.scss';
import PropertyTypesList from '../PropertyTypesList';
import { useCreateProperty } from '../../../queries/properties';
import { PropertyConfigurator } from '../PropertyConfigurator/PropertyConfigurator';
import { Contact, ContactPropertyType, Feature } from '../../../api/types';
import { propertyTypeIconNameMap } from '../typeIconMap';
import { Box, Popover } from '@mui/material';
import { Add } from '../../ConversationsList/CAdd';
import { getSquare } from '../../../theme/style.helpers';
import { useEnabledFeature } from '../../../queries/user';
import { PhoneConfigurator } from '../PropertyConfigurator/PhoneConfigurator';
import { useMenu } from '../../../hooks/use-menu';

const AddNewProperty = ({ contact }: { contact: Partial<Contact> }) => {
    const menu = useMenu();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const handleClose = () => {
        menu.handleClose();
        window.setTimeout(() => {
            setSelectedType(null);
        }, 200);
    };

    const [selectedType, setSelectedType] =
        useState<ContactPropertyType | null>(null);

    const { mutateAsync: createProperty } = useCreateProperty();

    return (
        <div className={styles['root']}>
            <button
                className={styles['root__button']}
                onClick={menu.handleOpen}
            >
                <Add sx={getSquare(20)} />
                <span>Add a new property</span>
            </button>
            <Popover
                open={menu.open}
                anchorEl={menu.anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        elevation: 5,
                        sx: {
                            borderRadius: 1.5,
                            margin: 0,
                            border: '1px solid',
                            borderColor: 'custom.gray.divider',
                        },
                    },
                }}
            >
                <Box minWidth={220}>
                    {selectedType ? (
                        selectedType === ContactPropertyType.Phone &&
                        isMultipleContactPhonesEnabled ? (
                            <PhoneConfigurator
                                onClose={handleClose}
                                contact={contact}
                            />
                        ) : (
                            <PropertyConfigurator
                                type={selectedType}
                                onNameChange={(name) => {
                                    setSelectedType(null);

                                    createProperty({
                                        name,
                                        type: selectedType,
                                        icon: propertyTypeIconNameMap[
                                            selectedType
                                        ],
                                    }).then(handleClose);
                                }}
                                onClose={handleClose}
                            />
                        )
                    ) : (
                        <PropertyTypesList
                            onClick={(type: ContactPropertyType) => {
                                setSelectedType(type);
                            }}
                        />
                    )}
                </Box>
            </Popover>
        </div>
    );
};

export default AddNewProperty;
