import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ChevronRightDouble = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4715 8.47141C12.7318 8.21106 12.7318 7.78895 12.4715 7.5286L8.47149 3.5286C8.21114 3.26825 7.78903 3.26825 7.52868 3.5286C7.26833 3.78895 7.26833 4.21106 7.52868 4.4714L11.0573 8L7.52868 11.5286C7.26833 11.7889 7.26833 12.2111 7.52868 12.4714C7.78903 12.7318 8.21114 12.7318 8.47149 12.4714L12.4715 8.47141Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.47149 8.47141C8.73184 8.21106 8.73184 7.78895 8.47149 7.5286L4.47149 3.5286C4.21114 3.26825 3.78903 3.26825 3.52868 3.5286C3.26833 3.78895 3.26833 4.21106 3.52868 4.4714L7.05727 8L3.52868 11.5286C3.26833 11.7889 3.26833 12.2111 3.52868 12.4714C3.78903 12.7318 4.21114 12.7318 4.47149 12.4714L8.47149 8.47141Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
