import {
    Dispatch,
    Reducer,
    ReducerAction,
    ReducerState,
    useReducer,
} from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useReducerWithMiddleware = <R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>,
    middlewareFn: (state: ReducerState<R>, action: ReducerAction<R>) => void,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchWithMiddleware = (action: ReducerAction<R>) => {
        middlewareFn(state, action);
        dispatch(action);
    };

    return [state, dispatchWithMiddleware];
};
