import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { ToastActions, ToastModalState, useToastContext } from './reducer';
import { getSquare } from '../../theme/style.helpers';
import { createElement, useMemo } from 'react';
import isString from 'lodash/isString';

const DEFAULT_TOAST_DURATION = 5000;

export default function Toast({ toast = [] }: { toast: ToastModalState }) {
    const { dispatch: toastDispatch } = useToastContext();

    const icon = useMemo(() => {
        if (!toast[0]?.icon) {
            return undefined;
        }
        return isString(toast[0]?.icon)
            ? toast[0]?.icon
            : createElement(toast[0]?.icon, { sx: getSquare(20) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast[0]?.icon]);

    if (!toast.length) {
        return null;
    }

    return (
        <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={toast[0].duration || DEFAULT_TOAST_DURATION}
            onClose={() => {
                toastDispatch({
                    type: ToastActions.REMOVE,
                    payload: { id: toast[0].id },
                });
            }}
        >
            <Alert
                icon={icon}
                onClose={() => {
                    toastDispatch({
                        type: ToastActions.REMOVE,
                        payload: { id: toast[0].id },
                    });
                }}
                severity={toast[0].severity}
            >
                <AlertTitle>{toast[0].title}</AlertTitle>
                {toast[0].description}
            </Alert>
        </Snackbar>
    );
}
