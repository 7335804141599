import { useMemo } from 'react';
import styles from './ContactPropertiesPanel.module.scss';
import { getInitialVlue, getTitle } from './utils';
import { Contact } from '../../api/types';
import ComplianceProperties from '../../components/ComplianceProperties';
import { usePropertiesQuery } from '../../queries/properties';
import { ContactPropertiesGroup } from './ContactPropertiesGroup';
import { CohortsGroup } from './CohortsGroup';
import { PropertySource } from '../../components/ContactProperty/types';

interface Props {
    contact: Partial<Contact>;
    onCreate?: (c: Contact) => void;
}

export const ContactPropertiesPanel = (props: Props) => {
    const { data: properties = [] } = usePropertiesQuery();

    const groupedProps = useMemo(() => {
        return properties.reduce((acc, property) => {
            if (property.source) {
                acc[property.source].push(property);
            }
            return acc;
        }, getInitialVlue());
    }, [properties]);

    return (
        <section className={styles['root']}>
            {props.contact?.id && (
                <ComplianceProperties contact={props.contact} />
            )}

            <ContactPropertiesGroup
                title={getTitle(PropertySource.Clerk)}
                contact={props.contact}
                properties={groupedProps.clerk}
                onCreate={props.onCreate}
                canCreateProps
                initiallyExpanded
            />

            {props.contact?.id && <CohortsGroup contact={props.contact} />}

            {Object.keys(groupedProps)
                .filter((key) => key !== PropertySource.Clerk)
                .map((key) => {
                    const source = key as PropertySource;
                    const propertiesGroup = groupedProps[source];

                    return (
                        propertiesGroup.length > 0 && (
                            <ContactPropertiesGroup
                                key={getTitle(source)}
                                title={getTitle(source)}
                                contact={props.contact}
                                properties={propertiesGroup}
                                onCreate={props.onCreate}
                                initiallyExpanded
                            />
                        )
                    );
                })}
        </section>
    );
};
