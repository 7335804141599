import { CohortMetaDto } from '../../../api/cohorts';
import { CustomPhone, ConversationOption, CustomContactPhone } from '../types';
import { Contact } from '../../../api/types';

export const isCohort = (
    option: ConversationOption,
): option is CohortMetaDto => {
    return (option as CohortMetaDto).contactsCount !== undefined;
};

export const isCustomPhone = (
    option: ConversationOption,
): option is CustomContactPhone | CustomPhone => {
    return !isCohort(option) && 'isCustom' in option;
};

export const isContactPhone = (
    option: ConversationOption,
): option is CustomContactPhone => {
    return !isCohort(option) && 'contactId' in option;
};

export const isContact = (option: ConversationOption): option is Contact => {
    return (
        !isCohort(option) && !isCustomPhone(option) && !isContactPhone(option)
    );
};
