import { Box, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    title: string;
    description?: string;
}>;

export const Empty = ({
    title = 'Nothing here',
    description,
    children,
}: Props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: '10%',
        }}
    >
        <Typography sx={{ fontSize: 56, lineHeight: 1 }}>📂</Typography>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                mb: 6,
                mt: 4,
            }}
        >
            <Typography variant="h2" fontWeight={600}>
                {title}
            </Typography>
            {description && (
                <Typography variant="body3">{description}</Typography>
            )}
        </Box>
        {children}
    </Box>
);
