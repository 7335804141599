import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { CopyIcon } from '../../../../icons/common/CCopyIcon';

export function CopyAction({
    defaultText = 'Copy',
    value,
}: {
    defaultText?: string;
    value: string;
}) {
    const [copyText, setCopyText] = useState(defaultText);

    return (
        <Tooltip title={copyText} placement="top">
            <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value);
                    setCopyText('Copied!');
                    setTimeout(() => {
                        setCopyText(defaultText);
                    }, 500);
                }}
                aria-label="Copy value button"
            >
                <CopyIcon />
            </IconButton>
        </Tooltip>
    );
}
