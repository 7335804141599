import { useState } from 'react';
import styles from './ContactsMoveDialog.module.scss';
import Button from '../../elements/Buttons';
import CheckBox from '../../elements/CheckBox/CheckBox';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogTitle,
} from '../../elements/Dialog';
import {
    useAddContactsToCohortsMutation,
    useCohortsQueryData,
} from '../../queries/cohorts';
import { UUID } from '../../types/uuid';

interface ContactsMoveDialogProps {
    selected: Set<UUID>;
    onClose: () => void;
    onMove: () => void;
}

export const ContactsMoveDialog = (props: ContactsMoveDialogProps) => {
    const [selectedCohorts, setSelectedCohorts] = useState<number[]>([]);
    const cohorts = useCohortsQueryData();

    const mutation = useAddContactsToCohortsMutation();

    return (
        <Dialog className={styles['root']}>
            <DialogTitle onClose={props.onClose}>
                Move contacts to list
            </DialogTitle>
            <DialogContent>
                <p className={styles['root__info']}>
                    Select a list(-s) where you want to move{' '}
                    <b>{props.selected.size} selected items.</b>
                    There will not be an effect if a contact is a part of
                    selected lists.
                </p>

                <h6 className={styles['root__list-title']}>Available lists</h6>

                <ul className={styles['root__list']}>
                    {cohorts.map((cohort) => (
                        <li
                            key={cohort.id}
                            className={styles['root__list-item']}
                        >
                            <CheckBox
                                checked={selectedCohorts.includes(cohort.id)}
                                label={cohort.name}
                                onClick={() => {
                                    if (selectedCohorts.includes(cohort.id)) {
                                        setSelectedCohorts(
                                            selectedCohorts.filter(
                                                (id) => id !== cohort.id,
                                            ),
                                        );
                                    } else {
                                        setSelectedCohorts(
                                            selectedCohorts.concat(cohort.id),
                                        );
                                    }
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions className={styles['root__actions']}>
                <Button type="default" onClick={props.onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={selectedCohorts.length === 0}
                    onClick={() => {
                        mutation.mutate({
                            cohortIds: selectedCohorts,
                            contactIds: Array.from(props.selected),
                        });
                        props.onMove();
                    }}
                >
                    Move
                </Button>
            </DialogActions>
        </Dialog>
    );
};
