import {
    TypographyOptions,
    TypographyStyleOptions,
} from '@mui/material/styles/createTypography';

const h3: TypographyStyleOptions = {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
};

export const typography: TypographyOptions = {
    fontFamily: 'Inter, sans-serif',
    htmlFontSize: 14,
    h1: {
        fontSize: 24,
        lineHeight: '32px',
        fontWeight: 700,
    },
    h2: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 500,
    },
    h2bold: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 600,
    },
    h3,
    h4: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
    },
    body1: {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 400,
    },
    body2: {
        fontSize: 16,
        lineHeight: '24px',
        fontWeight: 400,
    },
    body3: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
    },
    button: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 400,
    },
    body4: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 400,
        letterSpacing: '0.2px',
    },
    body5: {
        fontSize: 10,
        lineHeight: '12px',
        fontWeight: 400,
    },
    caption: {
        fontSize: 12,
        lineHeight: '16px',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.2px',
    },
    subtitle2: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
    },
    alertTitle: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '20px',
    },
};
