import {
    Avatar,
    Box,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Stack,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';
import avatarPlaceholder from '../../../icons/common/avatarPlaceholder.svg';
import { getInitials } from '../../../main/campaign/v3/string.helpers';
import { useContactNameAndPhone } from '../../../components/NewConversation/SearchPanel/hooks/useContactNameAndPhone';
import { Contact } from '../../../api/types';
import { useTrack } from '../../../contexts/analytics';
import { getContactName } from '../../../utils/contacts';
import { useMeQueryData } from '../../../queries/user';
import { formatPhoneNumber } from '../../../utils/phoneNumber';

export function ContactsListItem({
    contact,
    query,
}: {
    contact: Contact;
    query: string;
}) {
    const track = useTrack();
    const { name, phone } = useContactNameAndPhone(contact);
    const initials = getInitials(name);
    const country = useMeQueryData()?.activeTeam.countryCode || 'US';

    return (
        <MenuItem
            component={NavLink}
            to={`/contacts/book/${contact.id}`}
            onClick={() => {
                track('global_search_navigation', {
                    action: 'visit',
                    type: 'contact',
                    queryLength: query.length,
                });
            }}
        >
            <ListItemIcon>
                <Avatar
                    sx={{
                        background: getAvatarGradient(name),
                        width: 20,
                        height: 20,
                        fontSize: 10,
                    }}
                    alt={name}
                >
                    {(contact.avatarURL || !initials) && (
                        <Box
                            src={contact.avatarURL || avatarPlaceholder}
                            sx={{ width: 20, height: 20 }}
                            component="img"
                            alt="avatar"
                        />
                    )}
                    {!contact.avatarURL && initials}
                </Avatar>
            </ListItemIcon>
            <ListItemText sx={{ width: 1 }}>
                <Stack
                    direction="row"
                    sx={{
                        width: 1,
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            flexShrink: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {getContactName(contact, country)}
                    </Box>
                    {!!(name && phone) && (
                        <div>{formatPhoneNumber(contact.phone, country)}</div>
                    )}
                </Stack>
            </ListItemText>
        </MenuItem>
    );
}
