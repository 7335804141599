import { NavigationItemProps } from '../../types/ElementsProps';
import { ListItemIcon, MenuItem, Stack, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { navItemIconForNarrowScreens, navItemStyle } from './styles';
import { useSidebar } from '../../contexts/sidebar';
import { useIsDownMd } from '../../hooks/useIsDownMd';

const NavigationItem = ({
    icon,
    to,
    onClick,
    label,
    children,
    keyboardNav,
    TooltipProps,
}: NavigationItemProps) => {
    const { isCollapsed } = useSidebar();
    const isMobile = useIsDownMd();
    const isShrinkMode = isCollapsed && !isMobile;

    return (
        <Tooltip
            disableFocusListener
            disableHoverListener={!isShrinkMode}
            arrow
            title={label}
            placement="right"
            {...TooltipProps}
        >
            <MenuItem
                sx={navItemStyle}
                to={to}
                component={NavLink}
                onClick={(e) => {
                    onClick?.(e);
                }}
                data-navigation-element={keyboardNav ? 'true' : undefined}
            >
                <ListItemIcon
                    sx={isShrinkMode ? navItemIconForNarrowScreens : undefined}
                >
                    {icon}
                </ListItemIcon>

                {!isShrinkMode && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flex={1}
                    >
                        <span>{label}</span>
                        {children}
                    </Stack>
                )}
            </MenuItem>
        </Tooltip>
    );
};

export default NavigationItem;
