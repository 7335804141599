import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const ExpandMoreFilledIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.4107 13.0894C9.73614 13.4149 10.2638 13.4149 10.5892 13.0894L15.5892 8.08942C15.9147 7.76398 15.9147 7.23634 15.5892 6.91091C15.2638 6.58547 14.7361 6.58547 14.4107 6.91091L9.99996 11.3217L5.58921 6.91091C5.26378 6.58547 4.73614 6.58547 4.4107 6.91091C4.08527 7.23634 4.08527 7.76398 4.4107 8.08942L9.4107 13.0894Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
