import { useEnabledFeature } from '../../queries/user';
import { Feature } from '../../api/types';

interface Props {
    feature: Feature;
    with: React.ReactNode;
    without: React.ReactNode;
}

export const OnFeature = (props: Props) => {
    const enabled = useEnabledFeature(props.feature);

    if (enabled) {
        return props.with;
    }
    return props.without;
};
