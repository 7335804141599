import { Button, Stack, Typography } from '@mui/material';
import { useMeQueryData } from '../../../queries/user';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { useTrack } from '../../../contexts/analytics';
import { call } from '@microsoft/teams-js';
import { DialCounter } from './DialCounter';
import { useTeams } from 'msteams-react-base-component';
import { RoleTypes } from '../../../api/types';
import { userName } from '../../../helpers/formatting';

const DIAL_DELAY = 5000;

export const CallToVerify = () => {
    const me = useMeQueryData();
    const track = useTrack();
    const [{ inTeams }] = useTeams();
    const [isCallStarted, setIsCallStarted] = useState(false);

    const handleCallButtonClick = useCallback(() => {
        track('clicked_teams_onboarding_call_button');
        setIsCallStarted(true);

        /* TODO: uncomment after investigate how to call */
        setTimeout(() => {
            if (inTeams) {
                call.startCall({
                    targets: [
                        me!.email,
                        `4:${import.meta.env.VITE_CLERK_SALES_PHONE}`,
                    ],
                    requestedModalities: [call.CallModalities.Audio],
                    source: 'Clerk Chat',
                }).catch((error) => {
                    // eslint-disable-next-line no-undef
                    Sentry.captureException(error);
                });
            } else {
                document.location.href = `tel:${import.meta.env.VITE_CLERK_SALES_PHONE}`;
            }
        }, DIAL_DELAY);
    }, [inTeams, me, track]);

    if (isCallStarted) {
        return (
            <DialCounter
                delay={DIAL_DELAY}
                isWorkspaceOwner={
                    me?.activeRole?.type === RoleTypes.WorkspaceOwner
                }
            />
        );
    }

    const bodyText = inTeams
        ? 'Great news! Your Microsoft Teams Number is Available for SMS Activation.'
        : 'Get us a call to get started.';

    return (
        <Stack sx={{ width: 320 }} gap={10}>
            <Stack alignItems="center" gap={4}>
                <Typography fontSize={40} lineHeight="40px">
                    👋
                </Typography>
                <Stack alignItems="center" gap={2}>
                    <Typography variant="h1">Hey, {userName(me!)}</Typography>
                    <Typography variant="body3" align="center">
                        {bodyText}
                    </Typography>
                </Stack>
            </Stack>
            <Stack gap={4}>
                <Button onClick={handleCallButtonClick} color="info">
                    Verify
                </Button>
            </Stack>
        </Stack>
    );
};
