import { IconButton, Tooltip } from '@mui/material';
import { useGoToQueryParam } from '../../pages/NewInbox/query.helpers';
import { ContactInfoIcon } from '../../icons/common/CContactInfoIcon';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { useSearchParams } from 'react-router-dom';
import { useBetweenMdXl } from '../../hooks/useBetweenMdXl';
import { theme } from '../../theme/theme';
import { focusVisible } from '../../theme/focusVisible';

export function ContactInfoButton() {
    const isBetweenMdXl = useBetweenMdXl();
    const goToQueryParam = useGoToQueryParam();
    const [searchParams] = useSearchParams();
    const handleClick = () => {
        const state = searchParams.get(QueryParam.ContactInfo);
        const discussionId = searchParams.get(QueryParam.DiscussionId);

        goToQueryParam(
            {
                [QueryParam.ContactInfo]:
                    state === 'false' || isBetweenMdXl || discussionId
                        ? 'true'
                        : 'false',
            },
            [QueryParam.DiscussionId],
        );
    };

    return (
        <Tooltip arrow placement="bottom" title="Show more info">
            <IconButton
                color="primary"
                onClick={handleClick}
                data-navigation-element
                sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root:focus-visible': {
                        ...focusVisible,
                        outlineOffset: '0px',
                        backgroundColor: theme.palette.passive.main,
                    },
                }}
            >
                <ContactInfoIcon />
            </IconButton>
        </Tooltip>
    );
}
