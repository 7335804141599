import cc from 'classcat';
import Search from '../Inputs/Search';
import styles from './DroplistSearch.module.scss';

interface DroplistSearchProps {
    value?: string;
    className?: string;
    onChange?: (searchText: string) => void;
}

/** @deprecated use mui/list instead */
export const DroplistSearch = (props: DroplistSearchProps) => (
    <div className={cc([styles['root'], props.className])}>
        <Search value={props.value} onSearch={props.onChange} fullWidth />
    </div>
);
