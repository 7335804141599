import { createContext, useContext, useEffect } from 'react';
import { useBetweenMdLg } from '../hooks/useBetweenMdLg';
import { useIsDownMd } from '../hooks/useIsDownMd';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useReducerWithMiddleware } from '../hooks/use-reducer-with-middleware';
import isNil from 'lodash/isNil';

type SidebarState = {
    isCollapsed: boolean;
    isOpened: boolean;
};

type SidebarContextType = {
    state: SidebarState;
    dispatch: (action: SidebarActions) => void;
};

type SidebarActions =
    | {
          type: 'COLLAPSE';
      }
    | {
          type: 'EXPAND';
      }
    | {
          type: 'CLOSE';
      }
    | {
          type: 'OPEN';
      }
    | {
          type: 'TOGGLE_COLLAPSE';
      }
    | {
          type: 'TOGGLE_OPEN';
      };

const SidebarContext = createContext<SidebarContextType>({
    state: {
        isCollapsed: false,
        isOpened: false,
    },
    dispatch: () => {},
});

const reducer = (state: SidebarState, action: SidebarActions) => {
    switch (action.type) {
        case 'COLLAPSE':
            return { ...state, isCollapsed: true };
        case 'EXPAND':
            return { ...state, isCollapsed: false };
        case 'CLOSE':
            return { ...state, isOpened: false };
        case 'OPEN':
            return { ...state, isOpened: true };
        case 'TOGGLE_COLLAPSE':
            return {
                ...state,
                isCollapsed: !state.isCollapsed,
            };
        case 'TOGGLE_OPEN':
            return { ...state, isOpened: !state.isOpened };
        default:
            return state;
    }
};

type Props = {
    children: React.ReactNode;
};
export const SidebarProvider = ({ children }: Props) => {
    const isNarrowScreen = useBetweenMdLg();
    const isMobile = useIsDownMd();
    const [isCollapsedByUser, setIsCollapsed] =
        useLocalStorage<boolean>('isSidebarCollapsed');

    const [state, dispatch] = useReducerWithMiddleware(
        reducer,
        {
            isOpened: false,
            isCollapsed: !isNil(isCollapsedByUser)
                ? isCollapsedByUser
                : isNarrowScreen,
        },
        (state: SidebarState, action: SidebarActions) => {
            if (action.type === 'TOGGLE_COLLAPSE') {
                setIsCollapsed(!state.isCollapsed);
            }
        },
    );

    useEffect(() => {
        if (isNil(isCollapsedByUser)) {
            dispatch({ type: isNarrowScreen ? 'COLLAPSE' : 'EXPAND' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNarrowScreen, isCollapsedByUser]);

    useEffect(() => {
        dispatch({ type: isMobile ? 'CLOSE' : 'OPEN' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    return (
        <SidebarContext.Provider value={{ state, dispatch }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    const { state, dispatch } = useContext(SidebarContext);

    return {
        isCollapsed: state.isCollapsed,
        isOpened: state.isOpened,
        toggleCollapse: () => dispatch({ type: 'TOGGLE_COLLAPSE' }),
        toggleOpen: () => dispatch({ type: 'TOGGLE_OPEN' }),
    };
};
