import { createPortal } from 'react-dom';
import { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import styles from './CsvImportWizard.module.scss';
import {
    ACTION_OPTIONS,
    Column,
    CSV_PARSER_OPTIONS,
    FILEDS_OPTIONS,
    getPropertiesList,
    PROP_TYPES_OPTIONS,
    Usage,
} from './utils';
import Loading from '../../../components/Loading/Loading';
import { usePropertiesQuery } from '../../../queries/properties';
import Button from '../../../elements/Buttons';
import DragndropStep from '../DragndropStep';
import AdvancedSelect from '../../../elements/AdvancedSelect';
import Input from '../../../elements/Inputs/Input';
import { ContactPropertyType } from '../../../api/types';
import { queryClient } from '../../../queries/queryClient';
import { useNavigate } from 'react-router';
import { ALL_CONTACTS_KEY, useContactsImport } from '../../../queries/contacts';
import {
    ToastActions,
    useToastContext,
} from '../../../containers/toast/reducer';
import { AxiosError } from 'axios';
import head from 'lodash/head';
import { Stack } from '@mui/material';

const getErrorDescription = (error: AxiosError) => {
    const errorData = (
        error as AxiosError<{
            message?: string;
            schemaValidationErrors?: {
                line: number;
                column: string;
                errors: string[];
                value: unknown;
            }[];
            line?: number;
        }>
    )?.response?.data;
    if (
        errorData &&
        'schemaValidationErrors' in errorData &&
        errorData.schemaValidationErrors?.length
    ) {
        const firstError = head(errorData.schemaValidationErrors);
        return [
            `Column '${firstError?.column}' on line #${errorData?.line} has following errors: `,
            ...(firstError?.errors ?? []),
        ];
    }

    return errorData?.message ?? 'Something went wrong, please try again';
};

export function CsvImportWizard() {
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState<Column[]>([]);
    const { mutateAsync: importCSV } = useContactsImport();
    const { dispatch: dispatchToast } = useToastContext();

    const navigate = useNavigate();

    const { data: properties = [] } = usePropertiesQuery();

    const onComplete = useCallback(
        (results: Papa.ParseResult<unknown>) => {
            if (results.meta.fields) {
                const columns = results.meta.fields.map<Column>(
                    (columnName) => ({ columnName, usage: 'skip' }),
                );
                setColumns(columns);
            }
        },
        [setColumns],
    );

    useEffect(() => {
        if (file) {
            Papa.parse(file, {
                ...CSV_PARSER_OPTIONS,
                complete: onComplete,
            });
        } else {
            setColumns([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file]);

    return (
        <div className={styles['csv-import']}>
            <header className={styles['csv-import__header']}>
                <h4>CSV Import</h4>
                <div className={styles['csv-import__actions']}>
                    <div className={styles['clerk-contacts-actions__icons']}>
                        <Button
                            type="default"
                            onClick={() => navigate('/settings/integrations')}
                        >
                            Import Contacts
                        </Button>
                    </div>
                </div>
            </header>
            {file && (
                <>
                    <div className={styles['csv-import__mapper']}>
                        <div className={styles['csv-import__mapper-heading']}>
                            <h5>Map Columns</h5>
                        </div>

                        <div className={styles['csv-import__headings-row']}>
                            <div className={styles['csv-import__headings-col']}>
                                Columns from File
                            </div>
                            <div className={styles['csv-import__headings-col']}>
                                Properties
                            </div>
                        </div>

                        <div className={styles['csv-import__properties-rows']}>
                            {columns.map((column, i) => (
                                <div
                                    key={column.columnName}
                                    className={
                                        styles['csv-import__properties-row']
                                    }
                                >
                                    <div
                                        className={
                                            styles['csv-import__column-name']
                                        }
                                        title={column.columnName}
                                    >
                                        {column.columnName}
                                    </div>
                                    <div
                                        className={
                                            styles['csv-import__column-config']
                                        }
                                    >
                                        <AdvancedSelect
                                            options={ACTION_OPTIONS}
                                            value={column.usage}
                                            onChange={(optionId) => {
                                                const usage = optionId as Usage;
                                                const newColumns =
                                                    columns.slice();

                                                const newColumn: Column = {
                                                    columnName:
                                                        columns[i].columnName,
                                                    usage,
                                                };
                                                if (usage === 'new-property') {
                                                    newColumn.propertyName =
                                                        column.columnName;
                                                    newColumn.itemType =
                                                        'property';
                                                }

                                                newColumns[i] = newColumn;
                                                setColumns(newColumns);
                                            }}
                                        />

                                        {column.usage ===
                                            'existing-property' && (
                                            <AdvancedSelect
                                                placeholder="Select a property"
                                                options={getPropertiesList(
                                                    properties,
                                                )}
                                                value={
                                                    column.itemType === 'field'
                                                        ? column.field
                                                        : column.propertyName
                                                }
                                                onChange={(optionId) => {
                                                    const newColumns =
                                                        columns.slice();

                                                    const isField =
                                                        FILEDS_OPTIONS.some(
                                                            (f) =>
                                                                f.id ===
                                                                optionId,
                                                        );
                                                    const tmpCol: Column = {
                                                        columnName:
                                                            newColumns[i]
                                                                .columnName,
                                                        usage: newColumns[i]
                                                            .usage,
                                                    };

                                                    newColumns[i] = isField
                                                        ? {
                                                              ...tmpCol,
                                                              itemType: 'field',
                                                              field: optionId,
                                                          }
                                                        : {
                                                              ...tmpCol,
                                                              itemType:
                                                                  'property',

                                                              propertyName:
                                                                  optionId,
                                                              propertyType:
                                                                  properties.find(
                                                                      (p) =>
                                                                          p.name ===
                                                                          optionId,
                                                                  )?.type,
                                                          };
                                                    setColumns(newColumns);
                                                }}
                                            />
                                        )}

                                        {column.usage === 'new-property' && (
                                            <>
                                                <Input
                                                    size="large"
                                                    placeholder="Property name"
                                                    value={column.propertyName}
                                                    onChange={(value) => {
                                                        const newColumns =
                                                            columns.slice();
                                                        newColumns[i] = {
                                                            ...newColumns[i],
                                                            propertyName: value,
                                                        };
                                                        setColumns(newColumns);
                                                    }}
                                                />

                                                <AdvancedSelect
                                                    placeholder="Select property type"
                                                    options={PROP_TYPES_OPTIONS}
                                                    value={column.propertyType}
                                                    onChange={(optionId) => {
                                                        const newColumns =
                                                            columns.slice();
                                                        newColumns[i] = {
                                                            ...newColumns[i],
                                                            propertyType:
                                                                optionId as ContactPropertyType,
                                                        };
                                                        setColumns(newColumns);
                                                    }}
                                                />

                                                {(column.propertyType ===
                                                    ContactPropertyType.Select ||
                                                    column.propertyType ===
                                                        ContactPropertyType.MultiSelect) && (
                                                    <Input
                                                        size="large"
                                                        placeholder="List possible options splitted with ,"
                                                        value={
                                                            column.options?.join(
                                                                ',',
                                                            ) || ''
                                                        }
                                                        onChange={(value) => {
                                                            const newColumns =
                                                                columns.slice();
                                                            newColumns[i] = {
                                                                ...newColumns[
                                                                    i
                                                                ],
                                                                options: value
                                                                    .split(',')
                                                                    .map((v) =>
                                                                        v.trim(),
                                                                    ),
                                                            };
                                                            setColumns(
                                                                newColumns,
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles['csv-import__status-bar']}>
                        <Button
                            onClick={async () => {
                                const schema = columns.map(
                                    ({ usage: _, ...rest }) => ({ ...rest }),
                                );

                                setIsLoading(true);
                                try {
                                    const { data: contacts } = await importCSV({
                                        file,
                                        schema,
                                    });

                                    if (contacts.length) {
                                        dispatchToast({
                                            type: ToastActions.ADD,
                                            payload: {
                                                severity: 'success',
                                                title: 'Contacts import',
                                                description: `${contacts.length} contacts successfully uploaded`,
                                            },
                                        });
                                    } else {
                                        dispatchToast({
                                            type: ToastActions.ADD,
                                            payload: {
                                                severity: 'warning',
                                                title: 'Contacts import',
                                                description:
                                                    'No contacts found for import',
                                            },
                                        });
                                    }
                                } catch (e) {
                                    // eslint-disable-next-line no-console
                                    console.error(e);
                                    const errorDescripton = getErrorDescription(
                                        e as AxiosError,
                                    );

                                    dispatchToast({
                                        type: ToastActions.ADD,
                                        payload: {
                                            severity: 'error',
                                            title: 'Contacts import error',
                                            description:
                                                typeof errorDescripton ===
                                                'string' ? (
                                                    errorDescripton
                                                ) : (
                                                    <Stack>
                                                        {errorDescripton.map(
                                                            (desc) => (
                                                                <span
                                                                    key={desc}
                                                                >
                                                                    {desc}
                                                                </span>
                                                            ),
                                                        )}
                                                    </Stack>
                                                ),
                                        },
                                    });
                                } finally {
                                    await queryClient.refetchQueries({
                                        queryKey: [ALL_CONTACTS_KEY],
                                    });

                                    setIsLoading(false);
                                }

                                navigate('/contacts/book');
                            }}
                            disabled={isLoading}
                        >
                            Finish
                        </Button>
                    </div>
                </>
            )}

            {!file &&
                createPortal(
                    <DragndropStep onDone={setFile} />,
                    document.querySelector('#portal')!,
                )}

            {isLoading &&
                createPortal(
                    <div className={styles['csv-import__loader-wrapper']}>
                        <Loading />
                    </div>,
                    document.querySelector('#portal')!,
                )}
        </div>
    );
}
