import { Contact, ContactPhone } from '../../../api/types';
import { CustomPhone, CustomContactPhone } from '../types';
import { formatPhoneNumber } from '../../../utils/phoneNumber';

export const createContactFromPhoneNumber = (
    phoneNumber: string,
): CustomPhone => {
    return {
        id: phoneNumber,
        name: phoneNumber,
        data: {},
        phone: phoneNumber,
        updated: new Date().toISOString(),
        created: new Date().toISOString(),
        public: true,
        isCustom: true,
    };
};

export const createContactFromContactPhoneNumber = (
    contact: Contact,
    contactPhone: ContactPhone,
): CustomContactPhone => {
    return {
        ...contactPhone,
        name: contactPhone.isPrimary
            ? contact.name || formatPhoneNumber(contact.phone)
            : `${contact.name} - ${contactPhone.label || contactPhone.phone}`,
    };
};
