import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const StarFilledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.62952 1.92022C7.86288 1.80443 8.13696 1.80443 8.37032 1.92022C8.56903 2.01882 8.67721 2.19452 8.73661 2.30099C8.79975 2.41415 8.86549 2.56165 8.93552 2.71882L10.1731 5.49519C10.1759 5.50141 10.1785 5.50725 10.1809 5.51275C10.1869 5.51339 10.1933 5.51406 10.2 5.51477L13.223 5.83383C13.3941 5.85187 13.5547 5.8688 13.6818 5.89388C13.8014 5.91748 14.0019 5.96606 14.1571 6.12458C14.3394 6.31074 14.4241 6.5714 14.3861 6.82912C14.3537 7.04858 14.22 7.20576 14.1371 7.29516C14.049 7.39017 13.929 7.49827 13.8012 7.61344L11.5431 9.64839C11.5381 9.65295 11.5333 9.65723 11.5289 9.66126C11.5301 9.66716 11.5314 9.67341 11.5328 9.68007L12.1635 12.6537C12.1993 12.822 12.2328 12.9799 12.2482 13.1086C12.2627 13.2296 12.2785 13.4354 12.1757 13.6319C12.055 13.8628 11.8332 14.0239 11.5764 14.0674C11.3576 14.1044 11.1669 14.0258 11.0562 13.9746C10.9386 13.9202 10.7988 13.8395 10.6498 13.7535L8.01657 12.2348C8.01068 12.2314 8.00514 12.2282 7.99992 12.2252C7.99469 12.2282 7.98916 12.2314 7.98326 12.2348L5.35008 13.7535C5.20106 13.8395 5.0612 13.9202 4.94362 13.9746C4.83298 14.0258 4.64219 14.1044 4.42347 14.0674C4.16662 14.0239 3.94488 13.8628 3.82415 13.6319C3.72134 13.4354 3.7371 13.2296 3.75162 13.1086C3.76705 12.9799 3.80058 12.822 3.83629 12.6537L4.467 9.68007C4.46841 9.67341 4.46973 9.66716 4.47098 9.66126C4.4665 9.65723 4.46176 9.65295 4.4567 9.64839L2.19863 7.61343C2.0708 7.49826 1.95083 7.39017 1.86273 7.29516C1.77983 7.20576 1.64615 7.04858 1.61379 6.82912C1.57577 6.5714 1.66047 6.31074 1.8427 6.12458C1.99789 5.96606 2.19842 5.91748 2.31803 5.89388C2.44515 5.8688 2.60575 5.85187 2.77686 5.83383L5.7998 5.51477C5.80657 5.51406 5.81292 5.51339 5.81892 5.51275C5.82137 5.50725 5.82397 5.50141 5.82675 5.49519L7.06432 2.7188C7.13436 2.56164 7.20009 2.41414 7.26322 2.30099C7.32263 2.19452 7.4308 2.01882 7.62952 1.92022Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
