import { ReactEditor } from 'slate-react';
import { useCallback, useState, memo } from 'react';
import { IconButton, Popper, Tooltip } from '@mui/material';
import { TemplateIcon } from './TemplateIcon';
import MessageFormVariables from '../../../../MessageFormVariables';
import { insertTemplate } from './utils';
import { CustomEditor } from '../../../slate';
import { focusVisible } from '../../../../../theme/focusVisible';

export const TemplateButton = memo(function TemplateButton({
    disabled = false,
    editor,
}: {
    disabled?: boolean;
    editor: CustomEditor;
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleSelect = useCallback(
        (template: string) => {
            setAnchorEl(null);
            insertTemplate(editor, template);
            ReactEditor.focus(editor);
        },
        [editor],
    );

    return (
        <>
            <Tooltip title="Add Variable" arrow placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                            setAnchorEl((ref) => (ref ? null : e.currentTarget))
                        }
                        color={anchorEl ? 'info' : 'primary'}
                        aria-label="Add Variable"
                        sx={{
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '2px',
                            },
                        }}
                    >
                        <TemplateIcon />
                    </IconButton>
                </span>
            </Tooltip>

            {!!anchorEl && (
                <Popper
                    open
                    anchorEl={anchorEl}
                    placement="top-start"
                    disablePortal
                >
                    <MessageFormVariables
                        onSelect={handleSelect}
                        onClose={() => setAnchorEl(null)}
                    />
                </Popper>
            )}
        </>
    );
});
