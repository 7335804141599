import { useMemo, useState } from 'react';
import styles from './PropertyTypesList.module.scss';
import { typeIconMap } from '../typeIconMap';
import Droplist, {
    DroplistItems,
    DroplistItem,
    DroplistSearch,
} from '../../../elements/Droplist';
import { ContactPropertyType } from '../../../api/types';
import { Divider } from '@mui/material';

interface PropertyTypesListProps {
    onClick: (type: ContactPropertyType) => void;
}

export const PropertyTypesList = (props: PropertyTypesListProps) => {
    const [term, setTerm] = useState('');

    const filteredItems = useMemo(
        () =>
            typeIconMap.filter((item) =>
                item.name.toLowerCase().includes(term.toLowerCase()),
            ),
        [term],
    );

    const handleClick = (type: ContactPropertyType) => {
        setTerm('');
        props.onClick(type);
    };

    return (
        <Droplist style={{ border: 'none' }}>
            <DroplistSearch value={term} onChange={setTerm} />
            <DroplistItems>
                {filteredItems.map((item, idx) =>
                    item.type === 'Divider' ? (
                        <Divider sx={{ marginY: 1 }} key={`divider-${idx}`} />
                    ) : (
                        <DroplistItem
                            className={styles['item']}
                            key={item.name}
                            onClick={() =>
                                item.type !== 'Divider' &&
                                handleClick(item.type)
                            }
                        >
                            {item.icon}
                            <span>{item.name}</span>
                        </DroplistItem>
                    ),
                )}
            </DroplistItems>
        </Droplist>
    );
};
