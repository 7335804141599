import {
    Avatar,
    Badge,
    BadgeProps,
    Box,
    ButtonBase,
    Skeleton,
    Stack,
    SxProps,
    Typography,
} from '@mui/material';
import { getMessageStyle } from '../../ConversationRow/styles';
import { ButtonBaseProps } from '@mui/material/ButtonBase/ButtonBase';
import {
    avatar as avatarStyle,
    base,
    container,
    mainText,
    textLines,
} from './styles';
import React, { forwardRef, ReactElement, ReactNode, useMemo } from 'react';
import avatarPlaceholder from '../../../icons/common/avatarPlaceholder.svg';
import { getInitials } from '../../../main/campaign/v3/string.helpers';
import { getAvatarGradient } from '../../../elements/Avatar/get-avatar-color';

type Props = ButtonBaseProps & {
    href?: string;
    title: ReactElement | string;
    avatar?: string;
    selected?: boolean;
    description?: string | React.JSX.Element;
    date?: string;
    BadgeProps?: BadgeProps;
    children?: ReactNode;
    extraDescription?: ReactNode;
    loading?: boolean;
};

export const InboxListItem = forwardRef(
    (
        {
            title = '',
            avatar,
            description,
            extraDescription,
            date,
            selected = false,
            loading = false,
            sx,
            children,
            BadgeProps = {},
            ...props
        }: Props,
        ref,
    ) => {
        const initials = useMemo(() => getInitials(title), [title]);
        const background = useMemo(() => getAvatarGradient(title), [title]);

        return (
            <ButtonBase
                ref={ref}
                sx={
                    {
                        ...base(selected),
                        ...sx,
                        borderRadius: 1.5,
                        '&:focus-visible': {
                            outline: '1px solid',
                            outlineColor: 'info.main',
                            outlineOffset: '-1px',
                        },
                    } as SxProps
                }
                {...props}
                data-navigation-element
            >
                <Box>
                    <Badge invisible {...BadgeProps}>
                        {loading ? (
                            <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                            />
                        ) : (
                            <Avatar sx={avatarStyle(background)} alt={title}>
                                {(avatar || !initials) && (
                                    <Box
                                        src={avatar || avatarPlaceholder}
                                        sx={{
                                            width: 1,
                                            height: 1,
                                            objectFit: 'cover',
                                        }}
                                        component="img"
                                        alt="avatar"
                                    />
                                )}
                                {!avatar && initials}
                            </Avatar>
                        )}
                    </Badge>
                </Box>
                <Box sx={container}>
                    <Box sx={textLines}>
                        <Typography sx={mainText} variant="body3">
                            {loading ? (
                                <Skeleton height={20} width={99} />
                            ) : (
                                title
                            )}
                        </Typography>
                        {date && (
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                                whiteSpace="nowrap"
                            >
                                {date}
                            </Typography>
                        )}
                    </Box>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            mt: 1,
                            gap: 1,
                            width: 1,
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                        }}
                    >
                        {description && (
                            <Typography
                                variant="body4"
                                sx={getMessageStyle(false, false)}
                            >
                                {description}
                            </Typography>
                        )}
                        {extraDescription}
                    </Stack>
                </Box>
                {children}
            </ButtonBase>
        );
    },
);

InboxListItem.displayName = 'InboxListItem';
