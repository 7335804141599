import { MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { CustomContactPhone } from '../types';

type Props = {
    customPhone: CustomContactPhone;
    props: React.HTMLAttributes<HTMLLIElement> & {
        key?: string;
    };
};

export function CustomPhoneMenuItem({
    customPhone,
    props: { key: _, className: _cn, ...props },
}: Props) {
    return (
        <MenuItem {...props} data-testid="popper-result-phone-list-item">
            <Stack
                width={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography
                    variant="body3"
                    color="primary.dark"
                    data-testid="popper-contact-name"
                >
                    {formatPhoneNumber(customPhone.phone)}
                </Typography>
            </Stack>
        </MenuItem>
    );
}
