import { useEffect, useRef, useState } from 'react';
import isNumber from 'lodash/isNumber';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import Input from '../../../../elements/Inputs/Input';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';
import { limitedValue } from '../styles';
import { Box, Typography } from '@mui/material';

export const NumberValue = ({
    userProperty,
    onChange,
    readonly,
}: ValueProps<string | number>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(inputRef, () => setEditEnabled(false));

    useOnKeyDown(inputRef, 'Enter', () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Escape', () => {
        setValue(userProperty.value);
        setEditEnabled(false);
    });

    useEffect(() => {
        setValue(userProperty.value);
    }, [userProperty]);

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
        }
    }, [isEditEnabled]);

    if (isEditEnabled && !readonly) {
        return (
            <Input
                aria-label={`${userProperty.name} value input`}
                type="number"
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                value={userProperty.value}
                ref={inputRef}
                fullWidth
                onChange={(value: string) =>
                    setValue(value ? Number(value) : value)
                }
            />
        );
    }

    return (
        <Box
            sx={limitedValue}
            title={String(userProperty.value ?? '')}
            onClick={() => setEditEnabled(true)}
        >
            {isNumber(userProperty.value) ? (
                <Typography
                    aria-label={`${userProperty.name} value`}
                    variant="body3"
                    color="primary.dark"
                    noWrap
                    sx={{
                        display: 'inline-block',
                    }}
                >
                    {userProperty.value}
                </Typography>
            ) : (
                <EmptyValuePlaceholder
                    text={readonly ? 'Empty' : undefined}
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
