import React, { CSSProperties, ReactNode } from 'react';

interface ReactAudioPlayerProps {
    autoPlay?: boolean;
    children?: ReactNode;
    className?: string;
    controls?: boolean;
    controlsList?: string;
    crossOrigin?: string;
    id?: string;
    listenInterval?: number;
    loop?: boolean;
    muted?: boolean;
    onAbort?: (e: Event) => void;
    onCanPlay?: (e: Event) => void;
    onCanPlayThrough?: (e: Event) => void;
    onEnded?: (e: Event) => void;
    onError?: (e: Event) => void;
    onListen?: (time: number) => void;
    onLoadedMetadata?: (e: Event) => void;
    onPause?: (e: Event) => void;
    onPlay?: (e: Event) => void;
    onSeeked?: (e: Event) => void;
    onVolumeChanged?: (e: Event) => void;
    preload?: '' | 'none' | 'metadata' | 'auto';
    src?: string;
    style?: CSSProperties;
    title?: string;
    volume?: number;
}

const ReactAudioPlayerProvider = React.lazy(() =>
    import('react-audio-player').then((module) => ({
        default: module.default as React.ComponentType<ReactAudioPlayerProps>,
    })),
);

export const ReactAudioPlayer = ({ src, ...props }: ReactAudioPlayerProps) => (
    <React.Suspense fallback={<div>Loading audio player...</div>}>
        <ReactAudioPlayerProvider src={src} {...props} />
    </React.Suspense>
);
