import { useEffect } from 'react';
import { Alert, AlertTitle, Box, Stack, Typography } from '@mui/material';
import { MicrosoftTeamsLogoIcon } from '../../../icons/logos/MicrosoftTeamsLogoIcon';
import { useMeQueryData } from '../../../queries/user';
import { useGetMicrosoftTeamsPhones } from '../../../queries/integrations';
import { useSendAdminEmail } from './hooks/use-send-admin-email';
import { formatPhoneNumber } from '../../../utils/phoneNumber';

export const WelcomeToWorkspace = () => {
    const me = useMeQueryData();
    const { data: msPhones } = useGetMicrosoftTeamsPhones();

    const { isSend, sendEmail } = useSendAdminEmail();

    useEffect(() => {
        if (!isSend) {
            sendEmail();
        }
    }, [isSend, sendEmail]);

    const phoneNumber = msPhones?.phones.find(
        (phone) => phone?.user?.userPrincipalName === me?.email,
    )?.phone;

    return (
        <Stack sx={{ width: 320 }} gap={10}>
            <Stack alignItems="center" gap={4}>
                <Typography fontSize={40} lineHeight="40px">
                    🎉
                </Typography>
                <Stack alignItems="center" gap={2}>
                    <Typography variant="h1" textAlign="center">
                        Welcome to your workspace
                    </Typography>
                    <Typography variant="body3" align="center">
                        {`Your owner hasn't granted the necessary permission to
                        finalize the activation process.`}
                    </Typography>
                </Stack>
            </Stack>
            <Stack gap={2}>
                <Typography
                    variant="body4"
                    color="text.secondary"
                    textAlign="center"
                    mx={6}
                >
                    We found the following Microsoft Teams phone number.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        p: '14px 24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 4,
                        alignSelf: 'stretch',
                        borderRadius: 1.5,
                        border: '1px dashed var(--blue-100, #C5CEFC)',
                    }}
                >
                    <MicrosoftTeamsLogoIcon />
                    <Typography variant="body1">
                        {formatPhoneNumber(phoneNumber)}
                    </Typography>
                </Box>
            </Stack>
            <Box>
                {isSend && (
                    <Alert
                        severity="success"
                        icon={<Typography sx={{ pt: 0 }}>✅</Typography>}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            '.MuiAlert-icon': { pt: 0 },
                        }}
                    >
                        <AlertTitle sx={{ mb: 0 }}>
                            We sent a note to workspace owner.
                        </AlertTitle>
                    </Alert>
                )}
            </Box>
        </Stack>
    );
};
