import { WelcomeToWorkspace } from '../WelcomeToWorkspace';
import { CallToVerify } from '../CallToVerify';
import { Views } from '../hooks/use-teams-non-wo-onboarding';
import { NoPermission } from '../NoPermission';
import { NonMSUser } from '../NonMSUser';

export const getView = (view: Views | null) => {
    switch (view) {
        case Views.WelcomeToWorkspace:
            return <WelcomeToWorkspace />;
        case Views.CallToVerify:
            return <CallToVerify />;
        case Views.NoPermission:
            return <NoPermission />;
        case Views.NonMSUser:
            return <NonMSUser />;
        default:
            return <NonMSUser />;
    }
};
