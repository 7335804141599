import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import {
    deleteContacts,
    deleteContactsWithFilters,
    exportContactsCSV,
    importCSV,
} from '../api/contacts';
import { Contact, Feature } from '../api/types';
import { queryClient } from './queryClient';
import { client } from '../api/http';
import isUndefined from 'lodash/isUndefined';
import {
    ContactLibrary,
    getEmpty,
    getEmptyLibrary,
} from './contact-library.helpers';
import { UUID } from '../types/uuid';
import {
    NEXT_CONTACTS,
    Page,
    SlimFilter,
} from '../components/Contacts/contacts.queries';
import { PagedQueryData } from '../types/PagedData';
import { CONTACT_BY_PHONE_KEY } from '../components/Contacts/use-contact-by-phone';
import { useTrack } from '../contexts/analytics';
import { useEnabledFeature } from './user';
import omit from 'lodash/omit';

export type NotDraftContact = Partial<Contact> & { id: UUID };

/** @deprecated */
export const ALL_CONTACTS_KEY = 'contacts_list';
export const CONTACT = 'contact';
const CONTACT_CREATE_KEY = 'contact_create';
export const CONTACTS_SEARCH_KEY = 'contacts_search';

const initTime = new Date().getTime();

/** @deprecated */
const useContactsQuery = () =>
    useQuery({
        queryKey: [ALL_CONTACTS_KEY],
        placeholderData: getEmptyLibrary(),
        queryFn: () =>
            client.get<ContactLibrary>('/contacts').then(({ data }) => data),
        refetchOnMount: ({ state }) =>
            state.dataUpdatedAt < initTime ? 'always' : false,
        gcTime: 1000 * 60 * 60,
        refetchInterval: 1000 * 60 * 60,
        meta: {
            shouldCache: true,
        },
    });

/** @deprecated */
export const useContactsQueryData = () =>
    queryClient.getQueryData<ContactLibrary>([ALL_CONTACTS_KEY]) ||
    getEmptyLibrary();

export const useContactQuery = (id: string | undefined) =>
    useQuery<Contact>({
        queryKey: [CONTACT, id],
        queryFn: () =>
            client.get<Contact>(`/contacts/${id!}`).then(({ data }) => data),
        enabled: !!id,
    });

/** @deprecated */
export const useContactsByPhones = () => {
    const { data } = useContactsQuery();
    return (numbers: string[]) => {
        return numbers.map((number) => {
            const index = data?.mapByPhone?.[number];
            if (!isUndefined(index)) {
                return data!.list[index];
            }
            return getEmpty(number);
        });
    };
};

export function useContactCreateQuery() {
    const track = useTrack();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useMutation({
        mutationKey: [CONTACT_CREATE_KEY],
        mutationFn: (payload: Partial<Omit<Contact, 'id'>>) =>
            client
                .post<Contact>(`/contacts`, omit(payload, 'id'))
                .then(({ data }) => data),
        onMutate: async () => {
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });
            await queryClient.cancelQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
        onSuccess: (created) => {
            track('contact_created');
            if (created.phone) {
                queryClient.setQueryData(
                    [
                        CONTACT_BY_PHONE_KEY,
                        created.phone,
                        isMultipleContactPhonesEnabled,
                    ],
                    created,
                );
            }

            queryClient.invalidateQueries({ queryKey: [NEXT_CONTACTS] });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
}

export const useContactUpdateQuery = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['contact_update'],
        mutationFn: (payload: NotDraftContact) =>
            client
                .put<Contact>(`/contacts/${payload.id}`, payload)
                .then(({ data }) => data),
        onMutate: async (contact) => {
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            queryClient
                .getQueriesData<Contact>({
                    queryKey: [CONTACT_BY_PHONE_KEY],
                    exact: false,
                })
                .forEach(([queryKey, found]) => {
                    if (
                        found?.phone === contact.phone ||
                        found?.id === contact.id
                    ) {
                        queryClient.invalidateQueries({ queryKey });
                    }
                });

            const current = queryClient.getQueryData<Contact>([
                CONTACT,
                contact.id,
            ]);
            queryClient.setQueryData<Contact>(
                [CONTACT, contact.id],
                (prev) => (prev ? { ...prev, ...contact } : contact) as Contact,
            );

            queryClient.setQueriesData<PagedQueryData<Contact[]>>(
                {
                    queryKey: [NEXT_CONTACTS],
                    exact: false,
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) => ({
                            ...page,
                            data: page.data.map((prevContact) => {
                                if (prevContact.id === contact.id) {
                                    return {
                                        ...prevContact,
                                        ...contact,
                                    };
                                }
                                return prevContact;
                            }),
                        })),
                    };
                },
            );

            return { previousContact: current };
        },
        onError: (_err, contact, context) => {
            if (context!.previousContact) {
                queryClient.setQueryData<Contact>(
                    [CONTACT, contact.id],
                    context!.previousContact,
                );
            }
        },
        onSuccess: (updated) => {
            track('contact_created');
            if (updated.phone) {
                queryClient.setQueryData(
                    [CONTACT_BY_PHONE_KEY, updated.phone],
                    updated,
                );
            }

            queryClient.refetchQueries({
                queryKey: [CONTACT, updated.id],
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
        },
    });
};

export function useContactDeleteQuery() {
    const track = useTrack();

    return useMutation({
        mutationKey: ['contact_delete'],
        mutationFn: deleteContacts,
        onMutate: async ({ ids }) => {
            await queryClient.cancelQueries({ queryKey: [ALL_CONTACTS_KEY] });
            await queryClient.cancelQueries({
                queryKey: [CONTACTS_SEARCH_KEY],
            });

            const library = queryClient.getQueryData<ContactLibrary>([
                ALL_CONTACTS_KEY,
            ]);

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                (library) => {
                    if (library) {
                        const mapByPhone = { ...library.mapByPhone };
                        ids.forEach((id) => {
                            const phone = library.list.find(
                                (contact) => contact.id === id,
                            )?.phone;
                            if (phone) {
                                delete mapByPhone[phone];
                            }
                        });
                        return {
                            mapByPhone,
                            list: library.list.filter(
                                ({ id }) => !ids.includes(id),
                            ),
                        };
                    }
                    return getEmptyLibrary();
                },
            );

            queryClient.setQueriesData<PagedQueryData<Contact[]>>(
                {
                    queryKey: [NEXT_CONTACTS],
                    exact: false,
                },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return {
                        ...prev,
                        pages: prev.pages.map((page) => ({
                            ...page,
                            data: page.data.filter(
                                (prevContact: Contact) =>
                                    !ids.includes(prevContact.id),
                            ),
                        })),
                    };
                },
            );

            return { library };
        },
        onError: (_err, _contacts, context) => {
            const { library } = context!;

            queryClient.setQueryData<ContactLibrary>(
                [ALL_CONTACTS_KEY],
                library ?? getEmptyLibrary(),
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [CONTACTS_SEARCH_KEY] });
            queryClient.refetchQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
        onSuccess: (_, { ids }) => {
            track('contact_deleted');
            ids.forEach((id) => {
                queryClient.invalidateQueries({ queryKey: [CONTACT, id] });
            });
            queryClient
                .getQueriesData<Contact>({
                    queryKey: [CONTACT_BY_PHONE_KEY],
                    exact: false,
                })
                .forEach(([queryKey, prev]) => {
                    if (!prev) {
                        return prev;
                    }
                    if (ids.includes(prev?.id)) {
                        queryClient.invalidateQueries({ queryKey });
                    }
                });
            queryClient.setQueriesData<Contact[]>(
                { queryKey: [CONTACTS_SEARCH_KEY] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return prev.filter((contact) => !ids.includes(contact.id));
                },
            );
        },
    });
}

export function useContactDeleteWithFiltersQuery() {
    const track = useTrack();

    return useMutation({
        mutationKey: ['contact_delete'],
        mutationFn: deleteContactsWithFilters,
        onSettled: () => {
            queryClient.resetQueries({
                queryKey: [NEXT_CONTACTS],
                exact: false,
            });
        },
        onSuccess: ({ ids }: { ids: UUID[] }) => {
            track('contacts_deleted');

            ids.forEach((id) => {
                queryClient.invalidateQueries({ queryKey: [CONTACT, id] });
            });
            queryClient
                .getQueriesData<Contact>({
                    queryKey: [CONTACT_BY_PHONE_KEY],
                    exact: false,
                })
                .forEach(([queryKey, prev]) => {
                    if (!prev) {
                        return prev;
                    }
                    if (ids.includes(prev?.id)) {
                        queryClient.invalidateQueries({ queryKey });
                    }
                });
            queryClient.setQueriesData<Contact[]>(
                { queryKey: [CONTACTS_SEARCH_KEY] },
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return prev.filter((contact) => !ids.includes(contact.id));
                },
            );
        },
    });
}

export function useContactsSearch(queryTerm: string) {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    const resultsV2 = useContactsSearchV2(
        queryTerm,
        5,
        true,
        !isMultipleContactPhonesEnabled,
    );

    const resultsV3 = useContactsSearchV3(
        queryTerm,
        10,
        isMultipleContactPhonesEnabled,
    );

    return isMultipleContactPhonesEnabled ? resultsV3 : resultsV2;
}

export function useContactsSearchV2(
    queryTerm: string,
    limit = 10,
    withPhone?: boolean,
    enabled = true,
) {
    return useQuery({
        queryKey: [CONTACTS_SEARCH_KEY, { queryTerm, limit, withPhone }],
        queryFn: () => {
            return client
                .get<Page<Contact[]>>('/v2/contacts', {
                    params: {
                        query: queryTerm?.trim() || undefined,
                        limit,
                        withPhone,
                    },
                })
                .then(({ data }) => data?.data ?? []);
        },
        placeholderData: keepPreviousData,
        gcTime: 1000 * 60 * 60,
        enabled,
    });
}

export function useContactsSearchV3(
    queryTerm: string,
    limit = 10,
    enabled = true,
) {
    return useQuery({
        queryKey: [CONTACTS_SEARCH_KEY, { queryTerm, limit }],
        queryFn: () => {
            return client
                .get<Contact[]>('/v3/contacts/search-by-phone', {
                    params: {
                        name: queryTerm?.trim() || undefined,
                        limit,
                    },
                })
                .then(({ data }) => data);
        },
        placeholderData: keepPreviousData,
        gcTime: 1000 * 60 * 60,
        enabled,
    });
}

export const useContactWithFiltersExport = (
    filters: {
        cohortId?: number;
        query?: string;
        filters?: SlimFilter[];
        included?: string[];
        excluded?: string[];
    } = {},
) =>
    useMutation({
        mutationKey: ['contact_export', filters],
        mutationFn: exportContactsCSV,
    });

export const useContactsImport = () =>
    useMutation({ mutationKey: ['contacts_import'], mutationFn: importCSV });
