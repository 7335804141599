import { MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { extraPhoneItem } from './styles';
import { Contact, ContactPhone } from '../../../api/types';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import { StarFilledIcon } from '../../../icons/common/StarFilledIcon';
import { getSquare } from '../../../theme/style.helpers';

type Props = {
    contactPhone: ContactPhone;
    props: React.HTMLAttributes<HTMLLIElement> & {
        key?: string;
    };
    handleSelectExtraPhoneNumber: (
        contact: Contact,
        selectedPhone: ContactPhone,
    ) => void;
};

export function ContactPhoneMenuItem({
    contactPhone,
    props: { key: _, className: _cn, ...props },
}: Props) {
    return (
        <MenuItem
            {...props}
            sx={extraPhoneItem}
            data-testid="popper-result-phone-list-item"
        >
            <Stack
                width={1}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="body4" color="custom.gray.super">
                    {formatPhoneNumber(contactPhone.phone)}
                </Typography>
                {contactPhone.isPrimary && (
                    <StarFilledIcon sx={getSquare(16)} />
                )}
            </Stack>
        </MenuItem>
    );
}
