import { client } from '../../api/http';
import { AtLeastOne } from '../../utils/types';
import { UUID } from '../../types/uuid';
import { CONVERSATION_MESSAGES_KEY } from '../../queries/messages';
import {
    Attachment,
    Message,
    MessageStatus,
    Profile,
    SenderType,
} from '../../api/types';
import { useTrack } from '../../contexts/analytics';
import { InfiniteData, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../queries/queryClient';
import { useMeQueryData } from '../../queries/user';
import { getName } from '../../main/campaign/v3/get-profile-name';
import { LAST_UPLOADED_ATTACHMENTS } from '../MessageForm/use-attachment';
import { CampaignOrMessageCreateParams } from '../../api/campaign.types';

type UpdateDto = AtLeastOne<{ body: string; timestamp?: Date }> & {
    conversationId: UUID;
};

type UpdateParams = {
    id: number;
    message: UpdateDto;
};

let id = -1 - Math.random() - new Date().getTime() / 1000;
const getId = () => id--;

/**
 * NOTICE: it's an example of optimistic update.
 * It can be optimized, but we should try to update optimistic creations
 * using context passed from onMutate to onSuccess hook
 * */
export const createOptimisticMessage = (
    body: string,
    conversationId: string,
    me?: Profile,
) => {
    const tempId = getId();
    queryClient.setQueryData<InfiniteData<Message[]>>(
        [CONVERSATION_MESSAGES_KEY, conversationId],
        (previousMessages) => {
            if (!previousMessages) {
                return previousMessages;
            }

            const { pages } = previousMessages;
            const now = new Date().toString();
            const firstPages = pages[0].concat([
                {
                    id: tempId,
                    conversationId,
                    status: MessageStatus.Sending,
                    created: now,
                    senderType: SenderType.USER,
                    inbound: false,
                    body,
                    sender: me ? getName(me) : 'You',
                    userId: me?.id,
                    user: me,
                    attached: [],
                    attachments: [],
                    timestamp: now,
                    sentByName: me ? getName(me) : 'You',
                },
            ]);

            return {
                ...previousMessages,
                pages: [firstPages, ...pages.slice(1)],
            };
        },
    );

    return { tempId };
};

export const onSuccess = (message: Message, tempId: number) => {
    queryClient.setQueryData<InfiniteData<Message[]>>(
        [CONVERSATION_MESSAGES_KEY, message.conversationId],
        (prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                pages: prev.pages.map((messages) =>
                    messages
                        .map((item) =>
                            item.id === tempId ? { ...item, ...message } : item,
                        )
                        .filter(({ id }) => id > 0),
                ),
            };
        },
    );
};

export const onError = (
    message: CampaignOrMessageCreateParams,
    tempId: number,
) => {
    queryClient.setQueryData<InfiniteData<Message[]>>(
        [CONVERSATION_MESSAGES_KEY, message.conversationId],
        (prev) => {
            if (!prev) {
                return prev;
            }
            const attachmentsCache: Attachment[] =
                queryClient.getQueryData([LAST_UPLOADED_ATTACHMENTS]) ?? [];
            return {
                ...prev,
                pages: prev.pages.map((messages) =>
                    messages.map((item) =>
                        item.id === tempId
                            ? {
                                  ...item,
                                  ...{
                                      status: MessageStatus.Failed,
                                      attached:
                                          attachmentsCache.filter(
                                              (attachment) =>
                                                  message.attachments?.includes(
                                                      attachment.id,
                                                  ),
                                          ) ?? [],
                                  },
                              }
                            : item,
                    ),
                ),
            };
        },
    );
};

export const useMessageCreate = () => {
    const track = useTrack();
    const me = useMeQueryData();

    return useMutation({
        mutationFn: (
            message: CampaignOrMessageCreateParams & { conversationId: string },
        ) =>
            client
                .post<Message>(
                    `/conversations/${message.conversationId}/messages`,
                    {
                        attachments: message.attachments,
                        body: message.body,
                        timestamp: message.timestamp,
                        templateId: message.messageTemplateId,
                    },
                )
                .then(({ data }) => data),
        onMutate: (message) => {
            return createOptimisticMessage(
                message.body,
                message.conversationId,
                me,
            );
        },
        onSuccess: (message: Message, vars, { tempId }) => {
            onSuccess(message, tempId);
            track('message_created', {
                conversationId: vars.conversationId,
                bodyLength: vars.body.length,
                attachmentsCount: vars.attachments?.length ?? 0,
                status: message.status,
            });

            /*todo: make it better by merging logic with socket handleNewMessageEvent handler*/
            void queryClient.invalidateQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, message.conversationId],
            });
        },
        onError: (_, message, context) => {
            if (!context?.tempId) {
                return;
            }
            onError(message, context.tempId);
        },
    });
};

export const useUpdateMessage = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: ({ id, message }: UpdateParams) =>
            client.patch<void>(`/messages/${id}`, message),
        onSuccess: (_, { id, message }) => {
            track('message_updated');
            queryClient.setQueryData<InfiniteData<Message[]>>(
                [CONVERSATION_MESSAGES_KEY, message.conversationId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }

                    return {
                        ...prev,
                        pages: prev.pages.map((page: Message[]) =>
                            page.map((pageItem) => {
                                return pageItem.id === id
                                    ? {
                                          ...pageItem,
                                          timestamp:
                                              'timestamp' in message
                                                  ? (message.timestamp as Date)
                                                  : pageItem.timestamp,
                                          body:
                                              'body' in message
                                                  ? message.body
                                                  : pageItem.body,
                                      }
                                    : pageItem;
                            }),
                        ),
                    };
                },
            );
        },
    });
};
