import { UserPropertyModel, ValueProps } from '../../types';
import { LimitedTextValue } from '../LimitedTextValue';
import { useMeQueryData } from '../../../../queries/user';
import { parsePhoneNumber } from '../../../../utils/phoneNumber';
import { Stack } from '@mui/material';
import { CopyAction } from '../LimitedTextValue/CopyAction';
import { MarkAsPrimaryAction } from './MarkAsPrimaryAction';
import { ContactPhone } from '../../../../api/types';

const DEFAULT_COPY_TEXT = 'Copy phone number';

type Props = ValueProps<string | ContactPhone>;

export const PhoneValue = (props: Props) => {
    const user = useMeQueryData();
    const stringPhone: string =
        typeof props?.userProperty?.value === 'string'
            ? (props?.userProperty?.value ?? '')
            : (props?.userProperty?.value?.phone ?? '');

    const canTogglePrimary =
        !!props?.userProperty?.value &&
        typeof props?.userProperty?.value !== 'string' &&
        props?.userProperty?.id !== 'draft' &&
        !props?.readonly &&
        !props?.userProperty?.readonly;

    return (
        <LimitedTextValue
            onChange={props.onChange}
            formatDisplay={props.formatDisplay as (value: string) => string}
            readonly={props.readonly || props?.userProperty?.readonly}
            userProperty={{
                ...props.userProperty,
                value: stringPhone,
            }}
            validate={(nextUserProps?: UserPropertyModel<string>) => {
                if (!nextUserProps?.value) {
                    return null;
                }

                const parsed = parsePhoneNumber(
                    nextUserProps?.value ?? '',
                    user?.activeTeam.countryCode,
                );

                return parsed ? null : 'Invalid phone number';
            }}
        >
            <Stack direction="row" spacing={0.5} alignItems="center">
                <CopyAction
                    value={stringPhone}
                    defaultText={DEFAULT_COPY_TEXT}
                />

                {canTogglePrimary && (
                    <MarkAsPrimaryAction
                        phone={props.userProperty.value as ContactPhone}
                    />
                )}
            </Stack>
        </LimitedTextValue>
    );
};
