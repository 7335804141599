import { CarrierOption } from './types';
import { Twilio } from '../../icons/carriers/Twilio';
import { MSTeams } from '../../icons/carriers/MSTeams';
import { Telnyx } from '../../icons/carriers/Telnyx';
import { Other } from '../../icons/carriers/Other';
import { SxProps } from '@mui/material';
import { Zoom } from '../../icons/carriers/Zoom';
import { TelnyxCredentials, TwilioCredentials } from './types';
import emojis from 'emojibase-data/en/compact.json';
import { Inbox, InboxProvider } from '../../api/types';
import { AppleMessenger } from '../../icons/carriers/AppleMessenger';
import { getSquare } from '../../theme/style.helpers';
import { WebexIcon } from '../../icons/logos/WebexIcon';
import { WhatsApp } from '../../icons/carriers/WhatsApp';

export const getInboxData = ({
    inboxObj,
    index,
    provider,
    carrierCredentials,
    startingIndex,
}: {
    inboxObj: Partial<Inbox>;
    index: number;
    provider: InboxProvider;
    carrierCredentials?: TelnyxCredentials | TwilioCredentials;
    startingIndex: number;
}) => {
    const defaultInboxName = `Inbox #${startingIndex + index + 1}`;
    const randomEmoji = emojis[Math.floor(Math.random() * emojis.length)];

    const defaultInboxData = {
        provider,
        phone: inboxObj?.phone ?? '',
        name: inboxObj?.name ?? defaultInboxName,
        icon: inboxObj?.icon ?? randomEmoji.hexcode.toString(),
    };

    switch (provider) {
        case InboxProvider.TWILIO: {
            return {
                ...defaultInboxData,
                twilioSid: (carrierCredentials as TwilioCredentials).accountSID,
                twilioToken: (carrierCredentials as TwilioCredentials)
                    .authToken,
            };
        }
        case InboxProvider.TELNYX: {
            return {
                ...defaultInboxData,
                telnyxApiKey: (carrierCredentials as TelnyxCredentials).apiKey,
            };
        }
        case InboxProvider.BANDWIDTH:
        case InboxProvider.MSTEAMS:
        case InboxProvider.WEBEX:
        case InboxProvider.OTHER: {
            return defaultInboxData;
        }
        default: {
            return null;
        }
    }
};

export const availableCountries = ['US', 'CA'];

const carrierIcon: SxProps = {
    width: 40,
    height: 40,
};

export const carrierOptions: CarrierOption[] = [
    { id: 'twilio', label: 'Twilio', icon: <Twilio sx={carrierIcon} /> },
    {
        id: 'msteams',
        label: 'Microsoft Teams',
        icon: <MSTeams sx={carrierIcon} />,
    },
    { id: 'telnyx', label: 'Telnyx', icon: <Telnyx sx={carrierIcon} /> },
    {
        id: 'whatsapp',
        label: 'WhatsApp Business',
        icon: <WhatsApp sx={getSquare(40)} />,
    },
    { id: 'zoom', label: 'Zoom', icon: <Zoom sx={carrierIcon} /> },
    {
        id: 'webex',
        label: 'Webex',
        icon: <WebexIcon sx={getSquare(40)} />,
    },
    { id: 'other', label: 'Other', icon: <Other sx={carrierIcon} /> },
    {
        id: 'apple',
        label: 'Apple Business Messaging',
        icon: <AppleMessenger sx={getSquare(40)} />,
        isComingSoon: true,
    },
];
