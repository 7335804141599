import { NavigationArea } from '../../../hooks/use-keyboard-navigation';

export const handleMainSidebarKeyboardEvent = (e: KeyboardEvent) => {
    const parentArea = document.querySelector<HTMLElement>(
        `[data-navigation-area="${NavigationArea.MainSidebar}"]`,
    );
    const rightArea = document.querySelector<HTMLElement>(
        `[data-navigation-area="${NavigationArea.Inbox}"]`,
    );

    if (!parentArea) {
        return;
    }

    /* Get all elements with `data-navigation-element` in this area */
    const focusableElements = Array.from(
        parentArea.querySelectorAll<HTMLElement>('[data-navigation-element]'),
    );

    if (focusableElements.length === 0) {
        return;
    }

    /* Get the currently focused element */
    const activeElement = document.activeElement as HTMLElement | null;
    const currentIndex = focusableElements.indexOf(
        activeElement ?? focusableElements[0],
    );
    let nextIndex: number | null = null;

    if (e.code === 'ArrowDown') {
        nextIndex =
            currentIndex === -1 || currentIndex === focusableElements.length - 1
                ? 0 // Loop back to the first element
                : currentIndex + 1; // Move to the next element
    } else if (e.code === 'ArrowUp') {
        nextIndex =
            currentIndex === -1 || currentIndex === 0
                ? focusableElements.length - 1 // Loop back to the last element
                : currentIndex - 1; // Move to the previous element
    } else if (e.code === 'ArrowRight') {
        if (rightArea) {
            rightArea.focus();
            e.preventDefault(); // Prevent default scrolling behavior
        }
    }

    // Focus the next element, if applicable
    if (nextIndex !== null) {
        focusableElements[nextIndex].focus();
        e.preventDefault(); // Prevent default scrolling behavior
    }
};
