import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useBrandQuery } from '../../../queries/settings';
import { StepShell } from '../../Onboarding/StepShell';
import RectangleFrame from '../../../components/RectangleFrame';
import dayjs from 'dayjs';
import {
    useBulkCreateMutation,
    useCheckHostabilityQuery,
} from '../../../queries/inboxes';
import { useTrack } from '../../../contexts/analytics';
import { useSetupMicrosoftTeamsInboxContext } from './setup-teams-phone-context';
import { useNavigate } from 'react-router';
import { SignatureVerificationStepFormData } from '../../PhoneVerification/SignatureVerificationStep';
import {
    Stack,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    Box,
    FormHelperText,
    Link,
} from '@mui/material';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { Calendar } from '../../../icons/common/CCalendar';
import { PrivateUser } from '../../../icons/common/CPrivateUser';
import { ArrowBackIcon } from '../../../icons/common/CArrowBack';
import { MSTeams } from '../../../icons/carriers/MSTeams';
import { checkbox, checkboxWrapper, infoIcon } from './styles';
import { Link as RouterLink } from 'react-router-dom';
import { InfoIcon } from '../../../icons/common/CInfoIcon';
import groupBy from 'lodash/groupBy';
import axios from 'axios';
import isArrayLike from 'lodash/isArrayLike';
import { PortDisclaimer } from '../../PhoneVerification/helpers';

const DEFAULT_ERROR =
    'Something went wrong. Please contact Clerk Support at support@clerk.chat';

const parseErrorFromBandwidth = (
    errors: { error: string }[] | string | undefined,
) => {
    if (errors) {
        if (typeof errors === 'string') {
            return errors;
        }

        const vals = groupBy(errors, 'error');
        return Object.keys(vals).join(', ');
    }
    return DEFAULT_ERROR;
};

type FormData = {
    hasReadTeamsPhoneStandard: boolean;
    hasAgreedTermsAndConditions: boolean;
};

export const ConfirmStep = () => {
    const navigate = useNavigate();
    const { activationDate, selectedPhones } =
        useSetupMicrosoftTeamsInboxContext();
    const {
        control,
        handleSubmit,
        formState: { isValid },
    } = useForm<FormData>();
    const track = useTrack();

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const { mutateAsync, isPending: createLoading } = useBulkCreateMutation();
    const { data: hostableData, isLoading: hostableLoading } =
        useCheckHostabilityQuery(selectedPhones.map(({ phone }) => phone));

    const brand = useBrandQuery();

    const onSubmit: SubmitHandler<
        SignatureVerificationStepFormData
    > = async () => {
        setError(undefined);

        try {
            track('clicked_bulk_import_teams_phones');
            setLoading(true);
            await mutateAsync({
                phones: selectedPhones!,
                activationAt: activationDate,
            });
            setLoading(false);
            navigate('/');
        } catch (e) {
            const message: string =
                axios.isAxiosError(e) &&
                e.response?.data?.message &&
                isArrayLike(e.response?.data?.message)
                    ? parseErrorFromBandwidth(e.response.data.message)
                    : DEFAULT_ERROR;

            setError(message);
            setLoading(false);
            track('ms_teams_import_error', { message });
        }
    };

    const loadingState = isLoading || createLoading || hostableLoading;
    const backUrl = hostableData?.hostable
        ? '/setup-inbox/ms-teams/info'
        : '/setup-inbox/ms-teams/activation-date';

    return (
        <StepShell
            title="Terms and Conditions"
            icon={<MSTeams />}
            subtitle="Enable SMS on your Teams Phones by completing the last steps below"
            goBackward={backUrl}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={6} px={10}>
                    <Stack spacing={2}>
                        <PortDisclaimer />
                        <RectangleFrame variant="light">
                            <Controller
                                name="hasReadTeamsPhoneStandard"
                                control={control}
                                defaultValue={false}
                                rules={{ required: true, validate: (v) => v }}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={checkboxWrapper}
                                        control={
                                            <Checkbox
                                                sx={checkbox}
                                                color="info"
                                                checked={field.value}
                                                size="small"
                                                {...field}
                                            />
                                        }
                                        label={
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                flex={1}
                                            >
                                                <Typography
                                                    variant="body3"
                                                    color="primary.dark"
                                                >
                                                    I have a{' '}
                                                    <strong>
                                                        Teams Phone Standard
                                                    </strong>{' '}
                                                    license
                                                </Typography>

                                                <Link
                                                    color="info.main"
                                                    target="_blank"
                                                    href="https://www.youtube.com/watch?v=rZSuudvcNoo"
                                                    variant="body4"
                                                    onClick={() =>
                                                        track(
                                                            'clicked_help_on_teams_activation_page',
                                                        )
                                                    }
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <InfoIcon
                                                            sx={infoIcon}
                                                        />{' '}
                                                        <span>Help</span>
                                                    </Stack>
                                                </Link>
                                            </Stack>
                                        }
                                    />
                                )}
                            />
                        </RectangleFrame>
                        <RectangleFrame variant="light">
                            <Controller
                                name="hasAgreedTermsAndConditions"
                                control={control}
                                defaultValue={false}
                                rules={{ required: true, validate: (v) => v }}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={checkboxWrapper}
                                        control={
                                            <Checkbox
                                                sx={checkbox}
                                                color="info"
                                                checked={field.value}
                                                size="small"
                                                {...field}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body3"
                                                color="primary.dark"
                                            >
                                                I agree to the{' '}
                                                <a
                                                    href="https://clerk.chat/legal/terms"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    terms and conditions ↗
                                                </a>
                                            </Typography>
                                        }
                                    />
                                )}
                            />
                        </RectangleFrame>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={6}
                    >
                        <Box width="50%">
                            <RectangleFrame>
                                <Stack spacing={2}>
                                    <Stack spacing={2} direction="row">
                                        <PrivateUser sx={infoIcon} />
                                        <Typography
                                            variant="body4"
                                            color="custom.gray.super"
                                        >
                                            Authorized Person
                                        </Typography>
                                    </Stack>

                                    <Typography
                                        variant="body3"
                                        color="primary.dark"
                                        fontWeight={600}
                                    >
                                        {`${brand.data?.contactFirstName} ${brand.data?.contactLastName}`}
                                    </Typography>
                                </Stack>
                            </RectangleFrame>
                        </Box>
                        <Box width="50%">
                            <RectangleFrame>
                                <Stack spacing={2}>
                                    <Stack spacing={2} direction="row">
                                        <Calendar sx={infoIcon} />
                                        <Typography
                                            variant="body4"
                                            color="custom.gray.super"
                                        >
                                            Date
                                        </Typography>
                                    </Stack>

                                    <Typography
                                        variant="body3"
                                        color="primary.dark"
                                        fontWeight={600}
                                    >
                                        {dayjs(activationDate)
                                            .tz('America/Toronto')
                                            .format(
                                                'MMMM D, YYYY - hh:mm a EST',
                                            )}
                                    </Typography>
                                </Stack>
                            </RectangleFrame>
                        </Box>
                    </Stack>
                    {!!error && <FormHelperText error>{error}</FormHelperText>}

                    <Stack direction="row" justifyContent="space-between">
                        <Button
                            component={RouterLink}
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            to={backUrl}
                            disabled={loadingState}
                        >
                            {loadingState ? <ButtonDotLoader /> : 'Back'}
                        </Button>
                        <Button
                            disabled={loadingState || !isValid}
                            type="submit"
                        >
                            {loadingState ? <ButtonDotLoader /> : 'Complete'}
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </StepShell>
    );
};
