import { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import { InputProps } from '../../../../types/ElementsProps';
import '../../../../assets/styles/react-datepicker-theme.scss';
import Input from '../../../../elements/Inputs/Input';
import { fuzzyDate } from '../../../../utils/format';
import { Box } from '@mui/material';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';
import { limitedValue } from '../styles';

const DatePickerCustomInput = forwardRef<HTMLInputElement, InputProps>(
    function DatePickerCustomInput(
        { value, onClick, 'aria-label': ariaLabel },
        ref,
    ) {
        return (
            <Input
                aria-label={ariaLabel}
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                onClick={onClick}
                value={value}
                ref={ref}
                fullWidth
                readonly
                uncontrolled
            />
        );
    },
);

const renderValue = (val: string, fuzzy = true) => {
    if (fuzzy) {
        return fuzzyDate(new Date(val));
    }
    return dayjs(val).toString();
};

export const DateValue = ({
    userProperty,
    readonly,
    onChange,
}: ValueProps<string | Date>) => {
    const [isEditEnabled, setEditEnabled] = useState(false);

    if (isEditEnabled && !readonly) {
        return (
            <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={
                    userProperty.value
                        ? new Date(userProperty.value)
                        : new Date()
                }
                customInput={
                    <DatePickerCustomInput
                        aria-label={`${userProperty.name} value input`}
                    />
                }
                onCalendarClose={() => {
                    setEditEnabled(false);
                }}
                onChange={(date: Date) =>
                    onChange?.({ ...userProperty, value: date })
                }
            />
        );
    }

    return (
        <Box
            sx={limitedValue}
            title={String(userProperty.value ?? '')}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                renderValue(String(userProperty.value))
            ) : (
                <EmptyValuePlaceholder
                    text={readonly ? 'Empty' : undefined}
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
