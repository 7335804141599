import { Typography } from '@mui/material';
import { emptyValue } from './UserPropertyValue/styles';
import { ReactNode } from 'react';

export function EmptyValuePlaceholder({
    text = '+ Set a value',
    'aria-label': ariaLabel = '',
}: {
    text?: ReactNode;
    'aria-label'?: string;
}) {
    return (
        <Typography
            variant="body3"
            color="primary.dark"
            sx={emptyValue}
            aria-label={ariaLabel}
        >
            {text}
        </Typography>
    );
}
