import { useEffect } from 'react';

declare global {
    interface Window {
        //eslint-disable-next-line no-undef
        FB: typeof FB;
    }
}

const useFacebookIntegration = () => {
    useEffect(() => {
        const loadFacebookSDK = () => {
            if (document.getElementById('facebook-jssdk')) {
                return;
            }

            const script = document.createElement('script');
            script.id = 'facebook-jssdk';
            script.src = 'https://connect.facebook.net/en_US/sdk.js';
            script.async = true;

            script.onload = () => {
                if (window.FB) {
                    window.FB.init({
                        appId: import.meta.env['VITE_WHATSAPP_APP_ID'], // Replace with your App ID
                        autoLogAppEvents: true,
                        xfbml: true,
                        version: 'v21.0',
                    });
                }
            };

            document.body.appendChild(script);
        };

        loadFacebookSDK();
    }, []);
};

export default useFacebookIntegration;
