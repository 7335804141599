import React, { useState } from 'react';
import {
    Checkbox,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectProps,
    Typography,
} from '@mui/material';
import { SearchTextField } from '../../elements/SearchTextField/SearchTextField';
import * as styles from './styles';

export type Option = {
    id: string;
    label: string;
    secondaryLabel?: string;
    description?: string;
    icon?: React.ElementType;
    disabled?: boolean;
};

export type EnhancedSelectProps<Value> = Omit<
    SelectProps<Value>,
    'onChange'
> & {
    options: Option[];
    searchable?: boolean;
    onSearch?: (searchTerm: string) => void;
    hasCheckbox?: boolean;
    menuFullHeight?: boolean;
    onChange: (value: string, checked: boolean) => void;
    footer?: React.ReactNode;
    menuMaxWidth?: number;
};

export function EnhancedSelect<Value = unknown>({
    value,
    options,
    hasCheckbox,
    placeholder,
    searchable,
    onChange,
    footer,
    menuMaxWidth,
    multiple = false,
    variant = 'outlined',
    onClose,
    onOpen,
    menuFullHeight = false,
    MenuProps,
    onSearch,
    ...rest
}: EnhancedSelectProps<Value>) {
    const [isOpened, setIsOpened] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredOptions =
        searchable && searchTerm.length > 0 && !(typeof onSearch === 'function')
            ? options.filter((option) =>
                  option.label.toLowerCase().includes(searchTerm.toLowerCase()),
              )
            : options;

    return (
        <Select
            multiple={multiple}
            variant={variant}
            open={isOpened}
            displayEmpty={!!placeholder}
            value={value}
            placeholder={placeholder}
            onClose={(e) => {
                setIsOpened(false);
                typeof onClose === 'function' && onClose(e);
            }}
            onOpen={(e) => {
                setIsOpened(true);
                typeof onOpen === 'function' && onOpen(e);
            }}
            inputProps={{
                ...rest.inputProps,
            }}
            renderValue={() => {
                if ((!value || (value as string).length === 0) && placeholder) {
                    return (
                        <Typography
                            variant="body3"
                            mr={3}
                            color="custom.gray.super"
                            noWrap
                        >
                            {placeholder}
                        </Typography>
                    );
                }

                if (Array.isArray(value)) {
                    return (
                        <Typography variant="body3" mr={3} noWrap>
                            {value
                                .map(
                                    (id) =>
                                        options.find((o) => o.id === id)
                                            ?.label ?? '',
                                )
                                .filter(Boolean)
                                .join(', ')}
                        </Typography>
                    );
                }

                const selectedItem = options.find((o) => o.id === value);
                const Icon = selectedItem?.icon;
                return (
                    <>
                        {!!Icon && (
                            <ListItemIcon>
                                <Icon sx={styles.icon} />
                            </ListItemIcon>
                        )}
                        <Typography variant="body3" mr={3} noWrap>
                            {selectedItem?.label ?? ''}
                        </Typography>
                    </>
                );
            }}
            MenuProps={{
                ...MenuProps,
                sx: menuFullHeight
                    ? MenuProps?.sx
                    : {
                          ...MenuProps?.sx,
                          maxHeight: 300,
                      },
            }}
            {...rest}
        >
            {!!searchable && [
                /* @ts-expect-error - `onKeyDown` is not available in `MenuItem` type, but it is available in DOM element and required for `SearchTextField` */
                <MenuItem
                    key="search"
                    dense
                    sx={styles.searchHeader}
                    onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
                >
                    <SearchTextField
                        fullWidth
                        value={searchTerm}
                        variant="standard"
                        InputProps={{ sx: styles.searchInputSx }}
                        inputProps={{ sx: styles.searchInput }}
                        placeholder="Search"
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            event.stopPropagation();
                        }}
                        onChange={(event) => {
                            event.stopPropagation();
                            setSearchTerm(
                                (event.target as HTMLInputElement)?.value,
                            );
                            typeof onSearch === 'function' &&
                                onSearch?.(
                                    (event.target as HTMLInputElement)?.value,
                                );
                        }}
                    />
                </MenuItem>,
                <Divider key="search-divider" />,
            ]}
            {filteredOptions.map((option: Option) => {
                const isChecked = Array.isArray(value)
                    ? value.includes(option.id)
                    : value === option.id;

                const Icon = option?.icon;

                return (
                    <MenuItem
                        disabled={!!option.disabled}
                        value={option.id}
                        key={option.id}
                        sx={{
                            maxWidth: menuMaxWidth,
                            ...(hasCheckbox
                                ? styles.menuItemWithCheckbox
                                : undefined),
                        }}
                        onClick={(event) => {
                            event.stopPropagation();

                            onChange(option.id, !isChecked);

                            if (!multiple) {
                                setIsOpened(false);
                            }
                        }}
                    >
                        {hasCheckbox && (
                            <Checkbox
                                size="small"
                                sx={styles.checkbox}
                                color="info"
                                checked={isChecked}
                            />
                        )}

                        {!!Icon && (
                            <ListItemIcon>
                                <Icon sx={styles.icon} />
                            </ListItemIcon>
                        )}

                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'body3',
                                color: 'primary.dark',
                            }}
                            secondaryTypographyProps={{
                                variant: 'body4',
                                color: 'custom.gray.super',
                                sx: { whiteSpace: 'break-spaces' },
                            }}
                            primary={option.label}
                            secondary={option.description}
                        />

                        {!!option.secondaryLabel && (
                            <Typography variant="body3" color="primary.dark">
                                {option.secondaryLabel}
                            </Typography>
                        )}
                    </MenuItem>
                );
            })}
            {!!footer && [
                <Divider key="divider" />,
                <MenuItem key="footer">{footer}</MenuItem>,
            ]}
        </Select>
    );
}
