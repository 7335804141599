import { Box, Typography } from '@mui/material';

export function Header({ title }: { title: string }) {
    return (
        <Box pb={1}>
            <Typography variant="body3" color="custom.gray.super">
                Property:{' '}
                <Typography variant="body3" color="primary.dark">
                    {title}
                </Typography>
            </Typography>
        </Box>
    );
}
