import {
    AutocompleteRenderGroupParams,
    MenuList,
    Typography,
} from '@mui/material';
import { groupLabel, groupList } from './styles';

export function Group(props: AutocompleteRenderGroupParams) {
    return (
        <li key={props.key}>
            <Typography
                variant="body4"
                color="custom.gray.super"
                sx={groupLabel}
            >
                {props.group}
            </Typography>
            <MenuList sx={groupList}>{props.children}</MenuList>
        </li>
    );
}
