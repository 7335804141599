/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContactPropertyType, Profile } from '../../api/types';
import { PropertyIcon } from '../../icons/iconsMap';

enum UserPropertyFilterCondition {
    AND = 'AND',
    OR = 'OR',
}

enum TextTypeValue {
    Is = 1,
    IsNot = 2,
    Contains = 3,
    DoesNotContain = 4,
    Exists = 5,
}

enum NumberTypeValue {
    Is = 1,
    MoreThan = 2,
    LessThan = 3,
}

enum DateTypeValue {
    // Relative
    MoreThan = 1,
    Exactly = 2,
    LessThan = 3,
    Period = 4,
    // Absolute
    After = 5,
    On = 6,
    Before = 7,
    Exists = 8,
}

enum PersonTypeValue {}

export enum PropertySource {
    Clerk = 'clerk',
    Google = 'google',
    Microsoft = 'microsoft',
    Salesforce = 'salesforce',
    Shopify = 'shopify',
    Hubspot = 'hubspot',
    Pipedrive = 'pipedrive',
}

export interface UserPropertyModel<T = any> {
    id: string;
    type: ContactPropertyType;
    name: string;
    icon?: PropertyIcon;
    options?: T extends PersonTypeValue ? Array<Profile> : Array<string>;
    readonly?: boolean;
    readonlyIcon?: boolean;
    source?: PropertySource;
    value?: T;
    created?: Date;
    updated?: Date;
}

interface UserPropertyFilterModel<T = any> extends UserPropertyModel<T> {
    constraint?: TextTypeValue | NumberTypeValue | DateTypeValue;
}

export type FilterValue = UserPropertyFilterModel | UserPropertyFilterCondition;
