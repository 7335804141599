import { useMemo, useState } from 'react';
import { Inbox, Profile } from '../../../api/types';
import { Header } from '../Header';
import {
    Chip,
    Divider,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { SearchFilledIcon } from '../../../icons/common/SearchFilledIcon';
import { NavLink } from 'react-router-dom';
import { useInboxAssignmentsForInbox } from '../../../queries/inboxes';
import { useTeammates } from '../../../queries/user';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { getName } from '../../../main/campaign/v3/get-profile-name';
import { rolesOptions } from '../../../main/settings/Members/utils';

type Props = {
    inbox: Inbox;
};

export function Members({ inbox }: Props) {
    const [query, setQuery] = useState('');
    const { data: usersList = [] } = useInboxAssignmentsForInbox(inbox.id);
    const { data: teammates = [] } = useTeammates();

    const users = useMemo(() => {
        const users = usersList?.reduce((acc, { userId }) => {
            const teammate = teammates?.find((t) => t.id === userId);
            return teammate ? acc.concat(teammate) : acc;
        }, [] as Profile[]);
        return query.length
            ? users.filter((user) =>
                  getName(user).toLowerCase().includes(query.toLowerCase()),
              )
            : users;
    }, [teammates, usersList, query]);
    return (
        <>
            <Header
                title="Members"
                description="List of team members assigned to the phone number"
            />
            <Divider />
            <Stack flex={1} gap={6.5}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <TextField
                        size="small"
                        value={query}
                        onChange={({
                            target,
                        }: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                        >) => setQuery(target?.value ?? '')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchFilledIcon />
                                </InputAdornment>
                            ),
                        }}
                        placeholder="Search members"
                        variant="outlined"
                        sx={{ width: { xs: 1, sm: 'auto', md: 256 } }}
                    />
                    <NavLink to="/settings/members">
                        {'Manage members ->'}
                    </NavLink>
                </Stack>
                {users.length ? (
                    <Stack gap={4}>
                        {users.map((user) => (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={user.id}
                            >
                                <Stack direction="row" gap={2}>
                                    <UserAvatar
                                        key={user.id}
                                        size={20}
                                        imageUrl={user.avatar?.url}
                                        alt={getName(user)}
                                        username={getName(user)}
                                    />
                                    <Typography
                                        data-testid="inbox-assigned-name"
                                        variant="body3"
                                    >
                                        {getName(user)}
                                    </Typography>
                                </Stack>
                                <Chip
                                    size="small"
                                    label={
                                        rolesOptions.find(
                                            (role) =>
                                                role.id === user.role?.type,
                                        )?.label ?? 'No assigned role'
                                    }
                                />
                            </Stack>
                        ))}
                    </Stack>
                ) : (
                    <Typography variant="body3" color="text.secondary">
                        No results found. Please try a different search.
                    </Typography>
                )}
            </Stack>
        </>
    );
}
