import styles from './CohortContactsListItem.module.scss';
import Avatar from '../Avatar';
import { Contact } from '../../api/types';
import { CampaignContact } from '../../api/campaign.types';
import { useContactNameAndPhone } from '../NewConversation/SearchPanel/hooks/useContactNameAndPhone';

interface CohortContactsListItemProps {
    data: {
        countryCode?: string;
        contacts: (Contact | CampaignContact)[];
        onClick: (contact: Contact | CampaignContact) => void;
    };
    index: number;
    // This style prop is used by react-window lib.
    style: string;
}

export const CohortContactsListItem = ({
    data,
    index,
    style,
}: CohortContactsListItemProps) => {
    const contact = data.contacts[index];
    const { name } = useContactNameAndPhone(contact);

    return (
        <li style={style}>
            <a className={styles.contact} onClick={() => data.onClick(contact)}>
                <Avatar size="small" imageUrl={contact.avatarURL} />
                <div className={styles.contactName}>{name}</div>
            </a>
        </li>
    );
};
