import {
    buttonLikeStyle,
    buttonLikeStyleHovered,
    teamSelectorBtn,
    withBackgroundButton,
} from './styles';
import { Avatar, Button, Stack, Tooltip, Typography } from '@mui/material';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { userEmail } from '../../helpers/formatting';
import { useMeQueryData } from '../../queries/user';
import { DEFAULT_TEAM_NAME } from './constants';
import { MenuProps } from '../../hooks/use-menu';
import { ExpandMoreFilledIcon } from '../../icons/common/ExpandMoreFilled';
import { useSidebar } from '../../contexts/sidebar';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { getSquare } from '../../theme/style.helpers';
import { focusVisible } from '../../theme/focusVisible';

const ICONS_OFFSET = 151;

export function TeamSelectorButton({
    open,
    handleOpen,
    withBackground,
}: {
    handleOpen: MenuProps['handleOpen'];
    open: boolean;
    withBackground: boolean;
}) {
    const me = useMeQueryData();
    const { isCollapsed } = useSidebar();
    const isMobile = useIsDownMd();
    const isShrinkMode = isCollapsed && !isMobile;

    return (
        <Tooltip
            title="Account settings"
            placement="bottom"
            disableHoverListener={!isShrinkMode}
            disableFocusListener
        >
            <Button
                sx={[
                    isShrinkMode
                        ? {
                              justifyContent: 'center',
                              '& > .MuiButton-icon': {
                                  marginRight: 0,
                              },
                          }
                        : teamSelectorBtn,
                    withBackground && withBackgroundButton,
                    {
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '-1px',
                        },
                    },
                ]}
                variant="text"
                onClick={handleOpen}
                fullWidth
                startIcon={
                    <Avatar
                        sx={getSquare(20)}
                        alt={me?.activeTeam?.name || DEFAULT_TEAM_NAME}
                        src={me?.activeTeam.teamAvatarURL || logo}
                    />
                }
                endIcon={
                    !isShrinkMode ? (
                        <ExpandMoreFilledIcon
                            sx={[
                                buttonLikeStyle,
                                open && buttonLikeStyleHovered,
                            ]}
                        />
                    ) : undefined
                }
                tabIndex={0}
                data-navigation-element
                aria-haspopup="true"
                aria-controls={open ? 'con-t-team-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
            >
                {!isShrinkMode && (
                    <Stack
                        ml={2}
                        flexGrow={1}
                        maxWidth={
                            !isCollapsed && !isMobile && !withBackground
                                ? ICONS_OFFSET
                                : `calc(100vw - ${ICONS_OFFSET}px)`
                        }
                        alignItems="flex-start"
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={4}
                            maxWidth={1}
                        >
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight={500}
                                noWrap
                            >
                                {me?.activeTeam?.name || DEFAULT_TEAM_NAME}
                            </Typography>
                        </Stack>
                        <Typography
                            variant="body4"
                            color="text.secondary"
                            maxWidth={1}
                            noWrap
                        >
                            {userEmail(me!)}
                        </Typography>
                    </Stack>
                )}
            </Button>
        </Tooltip>
    );
}
