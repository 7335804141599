import { useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PaymentPlan from '../../../components/PaymentPlan';
import { getPlansByPeriod, PlanPeriod } from '../plans';
import { StepShell } from '../../Onboarding/StepShell';
import { useAnalytics } from '../../../contexts/analytics';
import { useMeQueryData } from '../../../queries/user';
import { setupShopifySubscription } from '../../../api/shopify';
import { Box, Stack } from '@mui/material';
import { useSelectedPlan } from '../use-selected-plan';
import { PaymentPeriodSwitcher } from '../../../components/PaymentPeriodSwitcher/PaymentPeriodSwitcher';

export const PaymentPlans = () => {
    const [selectedPlan, setSelectedPlan] = useSelectedPlan();
    const [period, setPeriod] = useState<PlanPeriod>(
        selectedPlan ? selectedPlan.period : 'yearly',
    );
    const analytics = useAnalytics();
    const me = useMeQueryData();
    const navigate = useNavigate();
    const billingProvider = me?.activeTeam?.billingProvider;

    const plans = useMemo(() => getPlansByPeriod(period), [period]);

    const dispatchPlanEvent = () =>
        analytics.dispatch({
            payload: {
                eventPayload: {
                    plan: selectedPlan,
                    billing: period,
                    userId: me?.id,
                    email: me?.email,
                    teamId: me?.activeTeam?.id,
                },
                eventName: 'user_clicked_payment_plan',
            },
        });

    return (
        <StepShell
            disableFrame
            title="Choose your plan"
            subtitle={
                <Stack gap={1}>
                    Select from the following plans. Need something custom?
                    <Box
                        sx={{
                            '& a': {
                                color: 'info.main',
                            },
                        }}
                    >
                        <NavLink to="mailto:sales@clerk.chat">{`Just reach out ->`}</NavLink>
                    </Box>
                </Stack>
            }
            sx={{ pt: 0 }}
            icon="💼"
        >
            <Box mb={6}>
                <PaymentPeriodSwitcher
                    value={period}
                    onChange={(value) => setPeriod(value)}
                />
            </Box>
            <Box display="flex" justifyContent="center" mx="auto" gap={6}>
                <PaymentPlan
                    planItem={plans['starter']}
                    onClick={(clientSecret: string) => {
                        dispatchPlanEvent();
                        setSelectedPlan(plans['starter']);
                        if (billingProvider === 'stripe') {
                            navigate(
                                `/payment/billing-info?s=${clientSecret}&pid=${plans['starter'].priceId}`,
                            );
                        } else if (billingProvider === 'shopify') {
                            setupShopifySubscription('starter', period).then(
                                (response) => {
                                    window.location.href =
                                        response.data.confirmation_url;
                                },
                            );
                        }
                    }}
                    basicButton
                />
                <PaymentPlan
                    planItem={plans['unlimited']}
                    onClick={(clientSecret: string) => {
                        dispatchPlanEvent();
                        setSelectedPlan(plans['unlimited']);

                        if (billingProvider === 'stripe') {
                            navigate(
                                `/payment/billing-info?s=${clientSecret}&pid=${plans['unlimited'].priceId}`,
                            );
                        } else if (billingProvider === 'shopify') {
                            setupShopifySubscription('unlimited', period).then(
                                (response) => {
                                    window.location.href =
                                        response.data.confirmation_url;
                                },
                            );
                        }
                    }}
                />
            </Box>
        </StepShell>
    );
};
