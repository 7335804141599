import { LoadingWithText } from '../../components/Loading/LoadingWithText';
import { BlockedOnboardingScreenLayout } from '../../containers/Onboarding/Teams/BlockedOnboardingScreenLayout/BlockedOnboardingScreenLayout';
import { useTeamsNonWoOnboarding } from '../../containers/Onboarding/Teams/hooks/use-teams-non-wo-onboarding';
import { getView } from '../../containers/Onboarding/Teams/helpers/view-map';

export const TeamsOnboarding = () => {
    const { isLoading, view } = useTeamsNonWoOnboarding();
    return (
        <>
            <BlockedOnboardingScreenLayout>
                {!isLoading && getView(view)}
            </BlockedOnboardingScreenLayout>
            {isLoading && (
                <LoadingWithText
                    title="Syncing Your Profile..."
                    subtitle={`We're retrieving your profile details. Please hold tight!`}
                />
            )}
        </>
    );
};
