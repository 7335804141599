import { Button, Grid, Typography } from '@mui/material';
import { AddIcon } from '../../icons/common/AddIcon';
import { useNavigate } from 'react-router';
import { useTrack } from '../../contexts/analytics';
import { focusVisible } from '../../theme/focusVisible';

export function EmptyConversation({
    inboxId,
    title,
    description,
}: {
    inboxId?: string;
    title: string;
    description: string;
}) {
    const navigate = useNavigate();
    const track = useTrack();
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            flex={1}
            direction="row"
            height="100vh"
            aria-label={`${title} ${description}`}
        >
            <Grid container item alignItems="center" direction="column">
                <Typography fontSize={56} lineHeight="56px" mb={4}>
                    📂
                </Typography>
                <Typography
                    variant="h2"
                    fontWeight={600}
                    color="primary.dark"
                    mb={1}
                >
                    {title}
                </Typography>
                <Typography variant="body3" color="primary.dark" mb={6}>
                    {description}
                </Typography>
                {inboxId && (
                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        startIcon={<AddIcon />}
                        tabIndex={0}
                        sx={{
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '2px',
                            },
                        }}
                        onClick={() => {
                            track('clicked_create_conversation_empty_state');
                            navigate(`/inbox/${inboxId}/new`);
                        }}
                        data-navigation-element
                    >
                        Send a message
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}
