import { UUID } from '../../../types/uuid';
import { History } from './History';
import { Box } from '@mui/material';
import { TypingIndicator } from '../../../components/TypingIndicator/TypingIndicator';
import {
    MessageForm,
    MessageFormData,
} from '../../../components/MessageForm/MessageForm';
import {
    useMessageCreate,
    useUpdateMessage,
} from '../../../components/MessageFormContainers/message.query';
import { handleError } from '../../../components/MessageFormContainers/utils';
import { useToastContext } from '../../../containers/toast/reducer';
import { useMemo, useState } from 'react';
import { Feature, Message, MessageStatus } from '../../../api/types';
import { Nullable } from '../../../helpers/types';
import { container, historyHolder } from './conversation-view.styles';
import { useConversation } from '../../../queries/conversations';
import { useByPhones } from '../../../components/Contacts/use-contact-by-phone';
import { WithFeature } from '../../../containers/Feature/WithFeature';
import { ClassificationLabels } from '../../../components/ClassificationLabels/ClassificationLabels';
import { ClassifyButton } from '../../../components/ClassifyButton/ClassifyButton';
import { HEADER_HEIGHT, pixelize } from '../inbox.styles';
import { useIs10DLCRequired } from '../../../main/settings/Compliance/useIs10DLCRequired';
import { EXTRA_OFFSET_ON_MOBILE } from '../query.helpers';
import { BANNER_HEIGHT } from '../../../components/Banner/Banner';
import { PaymentFailedBanner } from '../../../components/Banner/PaymentFailedBanner';
import { useIsSendingBlocked } from '../../../queries/user';

type Props = {
    conversationId: UUID;
};

const messageToFormData = (
    message: Nullable<Message>,
): MessageFormData | null => {
    if (!message) {
        return null;
    }
    return {
        message: message.body,
        sendAt: message.timestamp as string,
        attachments: message.attached,
    };
};

export const ConversationView = ({ conversationId }: Props) => {
    const isSendingBlocked = useIsSendingBlocked();
    const [message, setMessage] = useState<Message | null>(null);
    const creation = useMessageCreate();
    const mutation = useUpdateMessage();
    const { data } = useConversation(conversationId);
    const memberQueries = useByPhones(data?.members ?? []);
    const [sendMessageAnnounce, setSendMessageAnnounce] = useState<
        string | null
    >(null);
    const isSingleContact = useMemo(() => {
        return (
            data?.members.length === 1 &&
            memberQueries.filter(({ data }) => data).length ===
                data?.members.length
        );
    }, [memberQueries, data?.members]);
    const is10DLCRequired = useIs10DLCRequired();

    const { dispatch: toastDispatch } = useToastContext();

    const onSend = (formData: MessageFormData) => {
        if (message) {
            return mutation
                .mutateAsync({
                    id: message.id,
                    message: {
                        timestamp: formData.sendAt
                            ? new Date(formData.sendAt)
                            : undefined,
                        body: formData.message,
                        conversationId: message.conversationId,
                    },
                })
                .then(() => {
                    setMessage(null);
                    setSendMessageAnnounce(
                        'Message has been updated successfully',
                    );
                })
                .catch(() => {
                    handleError(toastDispatch);
                    setSendMessageAnnounce(
                        'Error occurred trying to update the message',
                    );
                })
                .finally(() => {
                    setTimeout(() => {
                        setSendMessageAnnounce(null);
                    }, 5000);
                });
        }
        return creation
            .mutateAsync({
                conversationId,
                body: formData.message,
                timestamp: formData.sendAt
                    ? new Date(formData.sendAt)
                    : undefined,
                attachments: formData.attachments?.map(({ id }) => id),
                messageTemplateId: formData.messageTemplateId,
            })
            .then((response) => {
                setMessage(null);
                if (response?.status === MessageStatus.Failed) {
                    setSendMessageAnnounce(`Message failed to send`);
                } else {
                    setSendMessageAnnounce(
                        `Message has been sent successfully`,
                    );
                }
            })
            .catch(() => {
                setSendMessageAnnounce('Message failed to send');
                handleError(toastDispatch);
            })
            .finally(() => {
                setTimeout(() => {
                    setSendMessageAnnounce(null);
                }, 5000);
            });
    };
    const topOffset = is10DLCRequired
        ? BANNER_HEIGHT + HEADER_HEIGHT
        : HEADER_HEIGHT;

    return (
        <Box
            sx={[
                container,
                {
                    height: [
                        `calc(100vh - ${pixelize(topOffset + EXTRA_OFFSET_ON_MOBILE)})`,
                        `calc(100vh - ${pixelize(topOffset + EXTRA_OFFSET_ON_MOBILE)})`,
                        `calc(100vh - ${pixelize(topOffset)})`,
                    ],
                },
            ]}
        >
            <Box sx={historyHolder}>
                <History
                    conversationId={conversationId}
                    onEdit={(m) => setMessage(m)}
                />
            </Box>
            <Box sx={{ pt: 2 }}>
                <WithFeature feature={Feature.Classifications}>
                    <Box
                        pb={2}
                        mx={6}
                        display="flex"
                        gap={2}
                        position="relative"
                        flexWrap="wrap"
                    >
                        <ClassificationLabels conversationId={conversationId} />
                        <ClassifyButton conversationId={conversationId} />
                    </Box>
                </WithFeature>
                <TypingIndicator conversationId={conversationId} />
                <Box
                    aria-live="assertive"
                    aria-atomic="false"
                    role="alert"
                    style={{
                        display: sendMessageAnnounce?.length
                            ? 'inline'
                            : 'none',
                        position: 'absolute',
                        width: '1px',
                        height: '1px',
                        margin: '-1px',
                        overflow: 'hidden',
                        clip: 'rect(0 0 0 0)',
                        border: '0',
                    }}
                >
                    {sendMessageAnnounce}
                </Box>
                <PaymentFailedBanner />
                <MessageForm
                    signatureAvailable
                    templatesAvailable={isSingleContact}
                    initial={messageToFormData(message)}
                    onSend={onSend}
                    conversationId={conversationId}
                    onCancel={() => setMessage(null)}
                    disabled={isSendingBlocked}
                />
            </Box>
        </Box>
    );
};
