import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { MailPlus } from '../../../icons/common/CMailPlus';
import { getSquare } from '../../../theme/style.helpers';
import { useSidebar } from '../../../contexts/sidebar';

export function InviteButton({ onClick }: { onClick: () => void }) {
    const { isCollapsed } = useSidebar();

    return (
        <Tooltip
            disableHoverListener={!isCollapsed}
            disableFocusListener
            arrow
            title={
                <Typography noWrap variant="body4">
                    Invite team
                </Typography>
            }
            placement="right"
        >
            <Link onClick={onClick}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={isCollapsed ? 'center' : undefined}
                    spacing={4}
                >
                    <MailPlus sx={getSquare(20)} />{' '}
                    {!isCollapsed && <span>Invite team</span>}
                </Stack>
            </Link>
        </Tooltip>
    );
}
