import { PlanItem } from '../../containers/PaymentFlow/plans';
import { useState } from 'react';
import { createIntent } from '../../api/payment-flow';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
    Typography,
} from '@mui/material';
import { colors } from '../../theme/palette';
import { Circle } from '@mui/icons-material';
import { CheckFilledIcon } from '../../icons/filled/CheckFilledIcon';

interface PaymentPlanProps {
    planItem: PlanItem;
    basicButton?: boolean;
    onClick: (clientSecret: string) => void;
}

export const PaymentPlan = ({
    planItem,
    basicButton,
    onClick,
}: PaymentPlanProps) => {
    const [isLoading, setLoading] = useState(false);

    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: '#E0E4EB',
                p: 6,
                borderRadius: 2,
                backgroundColor:
                    planItem.type === 'unlimited' && colors.lightGrey[50],
                width: 280,
            }}
        >
            <Typography
                variant="h3"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
                {planItem.title}{' '}
                {planItem.isPopular && (
                    <Chip
                        size="small"
                        title="Popular"
                        label="Popular"
                        sx={{
                            ml: 2,
                            '&.MuiChip-colorDefault': {
                                backgroundColor: '#E1EBFF',
                                color: 'rgba(1, 37, 103, 1)',
                            },
                        }}
                    />
                )}
            </Typography>

            <Stack gap={1} mt={6}>
                <Typography variant="h2" fontSize="32px" lineHeight="44px">
                    ${planItem.price}
                </Typography>
                <Typography variant="body3"> user / month</Typography>
            </Stack>

            <Button
                variant={basicButton ? 'outlined' : 'contained'}
                onClick={() => {
                    setLoading(true);
                    createIntent(planItem.type, planItem.priceId)
                        .then((data) => {
                            setLoading(false);
                            onClick(data.secret);
                        })
                        .catch((e) => {
                            setLoading(false);
                            // eslint-disable-next-line no-console
                            console.log(e);
                        });
                }}
                disabled={isLoading}
                sx={{ mt: 6, mb: 10 }}
                fullWidth
            >
                {isLoading ? (
                    <CircularProgress size={18} />
                ) : (
                    `Let’s go with ${planItem.title}`
                )}
            </Button>

            <List
                subheader={
                    <ListSubheader
                        component="div"
                        id="nested-list-subheader"
                        sx={{
                            background: 'transparent',
                            lineHeight: '20px',
                            pb: 1,
                        }}
                        disableGutters
                    >
                        <Typography variant="body3">
                            {planItem.listTitle}
                        </Typography>
                    </ListSubheader>
                }
            >
                {planItem.features.map((feature) => {
                    return (
                        <ListItem key={feature} disableGutters disablePadding>
                            <ListItemIcon
                                sx={{
                                    '&.MuiListItemIcon-root': {
                                        width: 6,
                                        height: 6,
                                    },
                                    '&.MuiListItemIcon-root .MuiSvgIcon-root': {
                                        width: 6,
                                        height: 6,
                                    },
                                }}
                            >
                                <Circle fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={feature} />
                        </ListItem>
                    );
                })}
            </List>
            <Divider sx={{ borderStyle: 'dashed', mt: 4, mb: 6 }} />
            <Typography variant="body3" color="text.secondary">
                Notes
            </Typography>
            <ListItem disablePadding sx={{ pt: 1 }}>
                <ListItemIcon
                    sx={{
                        '&.MuiListItemIcon-root .MuiSvgIcon-root': {
                            width: 20,
                            height: 20,
                        },
                    }}
                >
                    <CheckFilledIcon sx={{ color: colors.green[600] }} />
                </ListItemIcon>
                <ListItemText sx={{ ml: 2 }} primary={planItem.note} />
            </ListItem>
        </Box>
    );
};
