import { colors } from '../../../theme/palette';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Banner } from '../../../components/Banner/Banner';
import { useCurrentRole } from '../../../utils/user';
import { useWorkspaceOwners } from '../../../hooks/hooks';
import { Profile, RoleTypes } from '../../../api/types';
import { useMeQueryData } from '../../../queries/user';

const OwnerLink = () => {
    return (
        <Link to="/settings/compliance" component={RouterLink} sx={{ ml: 1 }}>
            Learn more{' ->'}
        </Link>
    );
};

const MemberLink = (props: { owners: Profile[]; cc: string }) => {
    const ownerEmails = props.owners.map((owner) => owner.email).join(', ');
    const url = new URL(`mailto:${ownerEmails}`);
    url.searchParams.append(
        'subject',
        'Register Your Company to Continue Sending SMS on Clerk Chat',
    );
    url.searchParams.append('cc', props.cc);
    url.searchParams.append(
        'body',
        `Your team needs to complete 10DLC registration by Dec 1st to continue sending SMS on Clerk Chat.\nPlease complete the registration process by following the instructions here: https://app.clerk.chat/settings/compliance`,
    );

    return (
        <Link href={url.href} component="a" sx={{ ml: 1 }}>
            Contact your admins{' ->'}
        </Link>
    );
};

export function TenDLCBanner() {
    const me = useMeQueryData();
    const role = useCurrentRole();
    const owners = useWorkspaceOwners();

    if (!role) {
        return null;
    }

    return (
        <Banner color={colors.red[50]}>
            <Typography variant="body4" color="primary.dark">
                🚨 To ensure your business SMS is not blocked as spam by
                carriers, contact your admin to complete 10DLC registration by
                Jan 31st.
                {[RoleTypes.Admin, RoleTypes.WorkspaceOwner].includes(role) ? (
                    <OwnerLink />
                ) : (
                    <MemberLink owners={owners} cc={me!.email!} />
                )}
            </Typography>
        </Banner>
    );
}
