import { SxProps } from '@mui/material';
import { colors } from '../../../theme/palette';
import { focusVisible } from '../../../theme/focusVisible';

export const modeSwitcher: SxProps = {
    m: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    height: '32px',
    p: '0 12px',
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'custom.gray.divider',
    '& svg': {
        color: colors.blue[500],
    },
    '&:focus-visible': {
        ...focusVisible,
        outlineOffset: '0px',
        borderRadius: 1,
    },
};
