import {
    Checkbox,
    InputAdornment,
    ListItem,
    ListItemButton,
    ListItemIcon,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { SearchIcon } from '../../../icons/common/SearchIcon';
import { ChangeEvent, forwardRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { checkbox, itemButton } from './styles';
import { BasicPhoneItem } from './BasicPhoneItem';
import { useFilteredPhones } from './useFilteredPhones';

export function PhonesList<T = string>({
    phones,
    handleSelect,
    selected = [],
    phoneToString = (phone: T) => phone as string,
    itemHeight = 36,
    ItemComponent = BasicPhoneItem<T>,
}: {
    phones: T[];
    selected: T[];
    itemHeight?: number;
    handleSelect: (phone: T) => void;
    phoneToString?: (phone: T) => string;
    ItemComponent?: React.ComponentType<{
        phone: T;
    }>;
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPhones = useFilteredPhones({
        phones,
        searchTerm,
        phoneToString,
    });

    return (
        <>
            <Stack spacing={1}>
                <TextField
                    fullWidth
                    placeholder="Search"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    value={searchTerm}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setSearchTerm(
                            event.currentTarget.value
                                .trim()
                                .toLocaleLowerCase(),
                        )
                    }
                />
                {!!searchTerm && !filteredPhones.length && (
                    <Typography variant="body3" color="primary.dark">
                        No numbers found. Please try changing your search query.
                    </Typography>
                )}
            </Stack>

            {!!filteredPhones.length && (
                <Virtuoso
                    data={filteredPhones}
                    style={{ height: 300 }}
                    components={{
                        List: forwardRef(function StackedList(props, ref) {
                            return <Stack {...props} ref={ref} spacing={2} />;
                        }),
                    }}
                    itemContent={(_, phone) => {
                        const phoneStr = phoneToString(phone);

                        return (
                            <ListItem key={phoneStr} disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        handleSelect(phone);
                                    }}
                                    className={
                                        selected.includes(phone)
                                            ? 'selected-item'
                                            : undefined
                                    }
                                    sx={{ ...itemButton, height: itemHeight }}
                                >
                                    <ItemComponent phone={phone} />
                                    <ListItemIcon>
                                        <Checkbox
                                            size="small"
                                            color="info"
                                            checked={selected.includes(phone)}
                                            tabIndex={0}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': phoneStr,
                                            }}
                                            sx={checkbox}
                                        />
                                    </ListItemIcon>
                                </ListItemButton>
                            </ListItem>
                        );
                    }}
                />
            )}
        </>
    );
}
