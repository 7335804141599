import { HEADER_HEIGHT } from '../../pages/NewInbox/inbox.styles';
import { SxProps } from '@mui/material';
import { colors } from '../../theme/palette';
import { getSquare } from '../../theme/style.helpers';

export const teamSelectorBtn = {
    height: HEADER_HEIGHT,
    paddingLeft: 4,
    paddingRight: 0,
    overflow: 'hidden',
    justifyContent: 'flex-start',
    borderRadius: 0,
    '&:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiButton-icon': { ml: 0 },
    '& .MuiButton-endIcon': {
        ...getSquare(28),
        mr: 0,
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

export const avatar: SxProps = {
    height: 1,
    width: 1,
    objectFit: 'cover',
};
export const limitedName: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    textWrap: 'wrap',
};

export const buttonLikeStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 1,
    width: 24,
    height: 24,
    borderRadius: '4px',
    transition: 'background-color 0.3s',
    color: 'primary.dark',

    '& svg': {
        width: 20,
        height: 20,
    },

    '&:hover': {
        backgroundColor: 'custom.gray.tableDivider',
        cursor: 'pointer',
    },
};

export const buttonLikeStyleHovered = {
    backgroundColor: 'custom.gray.tableDivider',
};

export const withBackgroundButton = {
    width: 248,
    height: 36,
    boxSizing: 'content-box',
    backgroundColor: colors.background.paper,
    px: 3,
    py: 2.5,
    elevation: 3,
    borderRadius: 1.5,
    '&:hover': {
        backgroundColor: colors.background.paper,
    },
};
