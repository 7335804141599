import { createContext, ReactNode, useContext, useState } from 'react';
import {
    InboxSettings,
    TabName,
} from '../../containers/InboxSettings/InboxSettings';

type OpenSettingsProps = {
    inboxId: string | null;
    isDelete?: boolean;
    tabName?: TabName;
};

type InboxSettingsContextType = {
    inboxId: string | null;
    isDeleteView: boolean;
    tabName: TabName;
    openInboxSettings: (props: OpenSettingsProps) => void;
};

const InboxSettingsContext = createContext<InboxSettingsContextType>({
    inboxId: null,
    tabName: TabName.About,
    isDeleteView: false,
    openInboxSettings: () => {},
});

export const InboxSettingsProvider = ({
    children,
}: {
    children: ReactNode;
}) => {
    const [inboxId, setInboxId] =
        useState<InboxSettingsContextType['inboxId']>(null);
    const [isDeleteView, setIsDeleteView] = useState(false);

    const [tabName, setTabName] = useState<TabName>(TabName.About);

    const openInboxSettings = ({
        inboxId,
        tabName,
        isDelete = false,
    }: OpenSettingsProps) => {
        setInboxId(inboxId);
        setIsDeleteView(isDelete);
        if (tabName) {
            setTabName(tabName);
        }
    };

    return (
        <InboxSettingsContext.Provider
            value={{ inboxId, tabName, isDeleteView, openInboxSettings }}
        >
            {children}
            {!!inboxId && (
                <InboxSettings
                    open
                    inboxId={inboxId}
                    deleteInbox={isDeleteView}
                    tabName={tabName}
                    cancelDeleteInbox={() => {
                        setIsDeleteView(false);
                    }}
                    onClose={() => {
                        openInboxSettings({
                            inboxId: null,
                            isDelete: false,
                            tabName: TabName.About,
                        });
                    }}
                />
            )}
        </InboxSettingsContext.Provider>
    );
};

export const useSidebarContext = () => {
    return useContext(InboxSettingsContext);
};
