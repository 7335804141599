import {
    useIsPaymentFailed,
    useIsSendingBlocked,
    useMeQueryData,
} from '../../queries/user';
import { Alert, Box, Button, Stack } from '@mui/material';
import { RoleTypes } from '../../api/types';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import { Link as RouterLink } from 'react-router-dom';
import noEntry from '../../assets/images/no-entry.png';
import { useTrack } from '../../contexts/analytics';

export const PaymentFailedBanner = () => {
    const track = useTrack();
    const paymentFailedDate = useIsPaymentFailed();
    const isSendingBlocked = useIsSendingBlocked();
    const isWorkspaceOwner =
        (useMeQueryData()?.activeRole?.type || RoleTypes.Member) ===
        RoleTypes.WorkspaceOwner;

    if (!paymentFailedDate) {
        return null;
    }

    return (
        <Box sx={{ p: 6 }}>
            <Alert
                severity={isSendingBlocked ? 'error' : 'warning'}
                icon={
                    <img src={noEntry} alt="sad emoji" width={20} height={20} />
                }
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        width: 1,
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    <Stack>
                        <AlertTitle>Payment Failed</AlertTitle>
                        {isSendingBlocked
                            ? 'Message sending is currently blocked. '
                            : 'Message sending may soon be limited. '}
                        {isWorkspaceOwner
                            ? 'Update your payment method to resolve this issue.'
                            : 'Ask the workspace owner to update the payment method.'}
                    </Stack>
                    {isWorkspaceOwner && (
                        <Button
                            sx={{ minWidth: 220 }}
                            onClick={() =>
                                track('payment_failed_banner_cta_clicked')
                            }
                            to="/payment/plan"
                            size="small"
                            variant="outlined"
                            component={RouterLink}
                        >
                            Review the payment method
                        </Button>
                    )}
                </Box>
            </Alert>
        </Box>
    );
};
