import { useContactPhoneUpdate } from '../../../../queries/contact-phones';
import { IconButton, Tooltip } from '@mui/material';
import { StarFilledIcon } from '../../../../icons/common/StarFilledIcon';
import { StarIcon } from '../../../../icons/common/StarIcon';
import { ContactPhone } from '../../../../api/types';

export function MarkAsPrimaryAction({ phone }: { phone: ContactPhone }) {
    const { mutate: updateContactPhone, isPending } = useContactPhoneUpdate();

    return (
        <Tooltip
            title={phone.isPrimary ? 'Unmark as primary' : 'Mark as primary'}
            placement="top"
        >
            <IconButton
                disabled={isPending}
                onClick={(e) => {
                    e.stopPropagation();
                    updateContactPhone({
                        id: phone.contactId ?? '',
                        phoneId: phone.id,
                        label: phone.label,
                        phone: phone.phone,
                        isPrimary: !phone.isPrimary,
                    });
                }}
                size="small"
                color="primary"
                aria-label={`${phone.isPrimary ? 'Primary' : 'Not primary'} number button`}
            >
                {phone.isPrimary ? <StarFilledIcon /> : <StarIcon />}
            </IconButton>
        </Tooltip>
    );
}
