import { useMemo } from 'react';
import { AnyContact, ConversationOption } from '../../types';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import {
    createContactFromContactPhoneNumber,
    createContactFromPhoneNumber,
} from '../../utils/createContactFromPhoneNumber';
import { useEnabledFeature, useMeQueryData } from '../../../../queries/user';
import { useCohortsQueryData } from '../../../../queries/cohorts';
import { useContactsSearch } from '../../../../queries/contacts';
import { isCohort } from '../../utils/options';
import { Contact, Feature } from '../../../../api/types';
import { CohortMetaDto } from '../../../../api/cohorts';
import { CountryCode } from 'libphonenumber-js/types';
import { getPrimaryPhone } from '../../utils';

export function useOptions(
    quickSearchTerm: string,
    isLimitReached: boolean,
    selectedContacts: AnyContact[],
    selectedCohorts: CohortMetaDto[],
): {
    options: ConversationOption[];
    isSearchFetching: boolean;
    isSomeFilteredOptionsExist: boolean;
} {
    const isCampaignsAllowed = useEnabledFeature(Feature.Campaigns);
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const countryCode: CountryCode =
        useMeQueryData()?.activeTeam?.countryCode || 'US';
    const cohorts = useCohortsQueryData();

    const foundCohorts = useMemo(
        () =>
            isCampaignsAllowed
                ? cohorts.filter((c) =>
                      c.name
                          .toLowerCase()
                          .includes(quickSearchTerm.toLowerCase()),
                  )
                : [],
        [cohorts, isCampaignsAllowed, quickSearchTerm],
    );

    const { data: foundContacts = [], isFetching: isSearchFetching } =
        useContactsSearch(quickSearchTerm);

    const options = useMemo(() => {
        if (!quickSearchTerm || isLimitReached) {
            return [];
        }

        const contactsWithPhones: AnyContact[] = foundContacts
            .filter((contact) =>
                isMultipleContactPhonesEnabled
                    ? (contact.phones ?? []).length > 0
                    : !!contact.phone,
            )
            .reduce<
                AnyContact[]
            >((acc, contact) => [...acc, contact, ...(contact.phones ?? []).map((phone) => createContactFromContactPhoneNumber(contact, phone))], []);

        if (!quickSearchTerm || isLimitReached) {
            return [...contactsWithPhones, ...foundCohorts];
        }

        const isValidPhone = isValidPhoneNumber(quickSearchTerm, countryCode);

        const isExistingContact = contactsWithPhones.some(
            (contact) =>
                (isMultipleContactPhonesEnabled
                    ? getPrimaryPhone((contact as Contact).phones ?? [])
                    : contact
                )?.phone === quickSearchTerm,
        );

        if (isValidPhone && !isExistingContact) {
            const phoneNumber = parsePhoneNumber(
                quickSearchTerm,
                countryCode,
            ).number;

            return [
                ...contactsWithPhones,
                createContactFromPhoneNumber(phoneNumber),
            ];
        }

        return [...contactsWithPhones, ...foundCohorts];
    }, [
        foundCohorts,
        foundContacts,
        isLimitReached,
        isMultipleContactPhonesEnabled,
        countryCode,
        quickSearchTerm,
    ]);

    const isSomeFilteredOptionsExist = useMemo(() => {
        const selectedContactsIds = selectedContacts.map((c) => c.id);
        const selectedCohortsIds = selectedCohorts.map((c) => c.id);

        return options.some((option) => {
            if (isCohort(option)) {
                return !selectedCohortsIds.includes(option.id);
            }

            if (isMultipleContactPhonesEnabled && 'phones' in option) {
                return !(option.phones ?? []).every((phone) =>
                    selectedContactsIds.includes(phone.id),
                );
            }

            return !selectedContactsIds.includes(option.id);
        });
    }, [
        selectedContacts,
        selectedCohorts,
        options,
        isMultipleContactPhonesEnabled,
    ]);

    return {
        options,
        isSearchFetching,
        isSomeFilteredOptionsExist,
    };
}
