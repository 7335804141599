import { useState } from 'react';
import styles from './Chat.module.scss';
import { Messages } from './Messages';
import { ConversationMessageForm } from '../MessageFormContainers/ConversationMessageForm';
import { Conversation, Message, ScheduledMessage } from '../../api/types';
import { WorkflowHeader } from '../ConversationHeader/WorkflowHeader';
import { TypingIndicator } from '../TypingIndicator/TypingIndicator';
import { Box } from '@mui/material';
import { content } from './styles';
import { PaymentFailedBanner } from '../Banner/PaymentFailedBanner';

interface ChatProps {
    conversation: Conversation;
    messageFormDisabled?: boolean;
    onBackClick: () => void;
}

function Chat(props: ChatProps) {
    const [smForEdit, setSmForEdit] = useState<ScheduledMessage | null>(null);
    const [messageForEdit, setMessageForEdit] = useState<Message | null>(null);

    return (
        <section className={styles['chat-flow']}>
            <Box flex="0 0 auto">
                <WorkflowHeader
                    conversation={props.conversation}
                    onBackClick={props.onBackClick}
                />
            </Box>
            <Box sx={content}>
                {props.conversation && (
                    <Messages
                        setMessageForEdit={setMessageForEdit}
                        conversation={props.conversation}
                        setSmForEdit={setSmForEdit}
                    />
                )}
            </Box>

            <div>
                {props.conversation && (
                    <TypingIndicator conversationId={props.conversation.id} />
                )}
            </div>
            <div>
                <PaymentFailedBanner />
                <ConversationMessageForm
                    conversation={props.conversation}
                    smForEdit={smForEdit}
                    messageForEdit={messageForEdit}
                    onEditCancel={() => {
                        setSmForEdit(null);
                        setMessageForEdit(null);
                    }}
                    disabled={props.messageFormDisabled}
                />
            </div>
        </section>
    );
}

export default Chat;
