import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
} from '@mui/material';
import {
    ClerkPermission,
    Conversation,
    ConversationStatus,
    Feature,
} from '../../api/types';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import {
    cleanByStatus,
    useDeleteConversation,
    useMarkConversationAsRead,
    useMarkConversationAsUnRead,
    useUpdateConversation,
} from '../../queries/conversations';
import { useState } from 'react';
import { DeleteIcon } from '../../icons/common/CDeleteIcon';
import { ArchiveIcon } from '../Inbox/CArchiveIcon';
import noop from 'lodash/noop';
import { MarkAsReadIcon } from '../Inbox/CMarkAsReadIcon';
import { useTrack } from '../../contexts/analytics';
import { useNavigate, useParams } from 'react-router';
import { DialogAttentionIcon } from '../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../elements/Dialog/DialogTitleTwoLine';
import { MarkAsUnreadIcon } from '../../icons/common/MarkAsUnreadIcon';
import { BlockPhoneListItem } from '../../main/settings/BlockList/BlockPhoneListItem';
import { WithFeature } from '../../containers/Feature/WithFeature';
import { UUID } from '../../types/uuid';

type Props = {
    conversation: Conversation;
    onAction?: () => void;
};

export const ConversationActions = ({
    conversation: { id, inboxId, status, unreadMessageCount, members },
    onAction = noop,
}: Props) => {
    const track = useTrack();
    const navigate = useNavigate();
    const { entityId } = useParams<{ entityId?: UUID }>();
    const { mutateAsync: markAsRead } = useMarkConversationAsRead();
    const { mutateAsync: markAsUnRead } = useMarkConversationAsUnRead();
    const { mutateAsync: updateConvo, isPending: isUpdating } =
        useUpdateConversation();
    const { mutateAsync: deleteConvo, isPending } = useDeleteConversation();
    const unread = unreadMessageCount > 0;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    return (
        <>
            <MenuItem
                data-testid="conv-mark-as-read"
                onClick={(e) => {
                    e.stopPropagation();
                    track(
                        unread
                            ? 'conversation_marked_read'
                            : 'conversation_marked_unread',
                        { conversationId: id },
                    );
                    if (unread) {
                        markAsRead(id).then(onAction);
                    } else {
                        markAsUnRead(id).then(onAction);
                    }
                }}
            >
                <ListItemIcon>
                    {unread ? <MarkAsReadIcon /> : <MarkAsUnreadIcon />}
                </ListItemIcon>
                <ListItemText>
                    {unread ? 'Mark as Read' : 'Mark as Unread'}
                </ListItemText>
            </MenuItem>
            <MenuItem
                disabled={isUpdating}
                data-testid="conv-mark-arch"
                onClick={(e) => {
                    e.stopPropagation();

                    updateConvo({
                        id,
                        status:
                            status === ConversationStatus.Archived
                                ? ConversationStatus.Active
                                : ConversationStatus.Archived,
                    }).then(() => {
                        track(
                            status === ConversationStatus.Archived
                                ? 'conversation_unarchived'
                                : 'conversation_archived',
                            {
                                conversationId: id,
                                origin: 'conversation_actions',
                            },
                        );
                        cleanByStatus({ id, status, inboxId });
                        onAction();
                    });
                }}
            >
                <ListItemIcon>
                    {isUpdating ? (
                        <CircularProgress size={18} disableShrink />
                    ) : (
                        <ArchiveIcon />
                    )}
                </ListItemIcon>
                <ListItemText>
                    {status === ConversationStatus.Archived
                        ? 'Un-Archive'
                        : 'Archive'}
                </ListItemText>
            </MenuItem>
            <WithFeature feature={Feature.BlockList}>
                <BlockPhoneListItem
                    phone={members}
                    onSuccess={() => {
                        if (entityId === id) {
                            navigate(`/inbox/${inboxId}`);
                        }
                        onAction();
                    }}
                />
            </WithFeature>
            <WithPermission
                requiredPermissions={[ClerkPermission.DeleteConversation]}
            >
                <Divider />
                <MenuItem
                    data-testid="conv-delete"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsDeleteModalOpen(true);
                    }}
                    sx={{ color: 'error.main' }}
                >
                    <ListItemIcon>
                        <DeleteIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </WithPermission>
            <Dialog
                open={isDeleteModalOpen}
                maxWidth="xs"
                onClose={() => setIsDeleteModalOpen(false)}
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm conversation deletion"
                    subtitle="The conversation will be deleted permanently. Proceed?"
                />
                <DialogActions>
                    <Button
                        disabled={isPending}
                        sx={{ minWidth: 100 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        startIcon={
                            isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        onClick={() => {
                            deleteConvo({
                                id,
                                inboxId,
                                status,
                            }).then(() => {
                                track('conversation_deleted', {
                                    conversationId: id,
                                });
                                onAction();
                                setIsDeleteModalOpen(false);
                                return navigate(`/inbox/${inboxId}`);
                            });
                        }}
                        variant="outlined"
                        color="error"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
