import { Box, ButtonBase, MenuItem } from '@mui/material';

export function Actions({ onRemove }: { onRemove: () => void }) {
    return (
        <Box px={1}>
            <MenuItem component={ButtonBase} sx={{ width: 1 }} type="submit">
                Save
            </MenuItem>
            <MenuItem onClick={onRemove}>Delete</MenuItem>
        </Box>
    );
}
