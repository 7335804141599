import { Box, Typography } from '@mui/material';
import { colors } from '../../../theme/palette';
import { section } from '../styles';
import { useContacts } from '../../../components/Contacts/contacts.queries';
import isEmpty from 'lodash/isEmpty';
import { ListSkeleton } from './ListSkeleton';
import { ContactsListItem } from './ContactsListItem';

type Props = { query: string };

export const ContactsList = ({ query = '' }: Props) => {
    const {
        data: { pages: [{ data: contacts }] } = { pages: [{ data: [] }] },
        isLoading,
    } = useContacts({
        query,
        limit: 3,
    });

    if (!isLoading && isEmpty(contacts)) {
        return null;
    }

    return (
        <Box sx={section}>
            <Typography
                variant="body4"
                sx={{ color: colors.text.secondary, px: 3 }}
            >
                Contacts
            </Typography>
            {isLoading && <ListSkeleton />}
            {contacts?.map((contact) => (
                <ContactsListItem
                    key={contact.id}
                    contact={contact}
                    query={query}
                />
            ))}
        </Box>
    );
};
