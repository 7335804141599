/*
 * To implement this component the article below was used:
 * https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
 */

import cc from 'classcat';
import { forwardRef } from 'react';
import styles from './TextareaAutosize.module.scss';

interface TextareaAutosizeProps {
    value?: string;
    wrapperClassName?: string;
    textareaClassName?: string;
    onChange?: (value: string) => void;
    disabled: boolean;
    'aria-label'?: string;
}

export const TextareaAutosize = forwardRef<
    HTMLTextAreaElement,
    TextareaAutosizeProps
>(function TextareaAutosize(
    {
        disabled,
        value = ' ',
        wrapperClassName,
        textareaClassName,
        onChange,
        'aria-label': ariaLabel,
    },
    ref,
) {
    return (
        <div
            className={cc([styles['textarea-wrap'], wrapperClassName])}
            data-replicated-value={value}
        >
            <textarea
                aria-label={ariaLabel}
                ref={ref}
                className={cc([styles['textarea'], textareaClassName])}
                onChange={(e) => onChange?.(e.currentTarget.value)}
                disabled={disabled}
                rows={1}
            >
                {value}
            </textarea>
        </div>
    );
});
