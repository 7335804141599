import { colors } from '../../theme/palette';
import {
    ArchiveState,
    useGoToQueryParam,
} from '../../pages/NewInbox/query.helpers';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { Tab } from '../../theme/Tab';
import { Tabs } from '../../theme/Tabs';
import { useEffect, useState } from 'react';
import { useTrack } from '../../contexts/analytics';
import { a11yProps } from '../utils/tabs-a11y-props';

export const StateTabs = ({ state }: { state: ArchiveState }) => {
    const track = useTrack();
    const goToQueryParam = useGoToQueryParam();
    const [tab, setTab] = useState(state);

    useEffect(() => {
        if (state !== tab) {
            setTab(state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
        <Tabs
            sx={{
                px: 4,
                backgroundColor: colors.lightGrey[50],
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}
            value={tab}
            color="info"
            onChange={(_: unknown, next: ArchiveState) => {
                setTab(next);
                goToQueryParam(
                    {
                        [QueryParam.ArchiveState]: next,
                    },
                    [QueryParam.CohortIds, QueryParam.SpecialFilter],
                );
                track(`inbox_${next}_clicked`);
            }}
            aria-label="Filter conversations"
        >
            <Tab
                color="info"
                label="Active"
                value="active"
                tabIndex={0}
                {...a11yProps('active', 'filter')}
                sx={{
                    mr: 1,
                }}
            />
            <Tab
                color="info"
                label="Archived"
                value="archived"
                tabIndex={0}
                {...a11yProps('archived', 'filter')}
            />
        </Tabs>
    );
};
