import { Contact } from '../../api/types';

const now = new Date().toISOString();

export const getPlaceholder = (
    phone: string,
    partial: Partial<Contact> = {},
): Omit<Contact, 'id'> => ({
    phone,
    name: '',
    data: {},
    email: '',
    avatarURL: '',
    public: false,
    updated: now,
    created: now,
    phones: [],
    ...partial,
});
