import { NavLink } from 'react-router-dom';
import { ToastActions, ToastDispatch } from '../../containers/toast/reducer';
import { ErrorCodes } from '../../api/types';
import { AxiosError } from 'axios';

export type ErrorResponse =
    | {
          response: {
              data: {
                  code: string;
              };
          };
      }
    | {
          status: number;
          data: {
              code: string;
              message: string;
          };
      }
    | Error;

export const getCode = (error: ErrorResponse) => {
    if ('response' in error) {
        return error.response.data?.code;
    }

    if ('data' in error) {
        return error.data?.code;
    }

    return null;
};

export const errorToToast = (
    error: ErrorResponse | AxiosError<string | { code: ErrorCodes }>,
) => {
    const errorCode = getCode(error);

    switch (true) {
        case errorCode === ErrorCodes.MessageLimitReached:
            return {
                id: ErrorCodes.MessageLimitReached,
                title: 'You are out of messages',
                description: (
                    <>
                        Please upgrade by clicking{' '}
                        <NavLink to="/payment/plan">this link</NavLink>
                    </>
                ),
            };

        case errorCode === ErrorCodes.PaidTeamRequired:
            return {
                id: ErrorCodes.PaidTeamRequired,
                title: 'Paid Team Required',
                description: (
                    <>
                        Click <NavLink to="/payment/plan">here</NavLink> to
                        upgrade
                    </>
                ),
            };

        case errorCode === ErrorCodes.CannotDeleteLastTeam:
            return {
                id: ErrorCodes.CannotDeleteLastTeam,
                title: 'Cannot delete last team',
                description: (
                    <>You cannot delete the last team in the workspace</>
                ),
            };

        case errorCode === ErrorCodes.CampaignBrandRegistrationRequired:
            return {
                id: ErrorCodes.CampaignBrandRegistrationRequired,
                title: 'Please register your company brand',
                description: (
                    <>
                        to send a campaign{' '}
                        <NavLink to="/settings/compliance">here</NavLink>
                    </>
                ),
            };

        case 'status' in error &&
            error?.status === 422 &&
            error?.data?.message === 'body.profanity.detected':
            return {
                id: error?.data?.message,
                title: 'Message not sent',
                description: (
                    <>
                        The message you have sent contains{' '}
                        <NavLink to="/settings/profanity">words</NavLink> that
                        are not allowed by your admin
                    </>
                ),
            };
        case 'status' in error &&
            error.status === 422 &&
            error?.data?.message === 'Filter property not found':
            return {
                id: error?.data?.message,
                title: 'Filter property not found',
                description: (
                    <>
                        Some properties from the list filter were deleted,
                        please consider the changes
                    </>
                ),
            };

        case 'status' in error &&
            error.status === 422 &&
            error?.response.data.message ===
                'Sending message to an Opt-Out contact is not allowed':
            return {
                id: error?.response.data.message,
                title: 'Message not sent',
                description: error?.response.data.message,
            };

        default:
            return {
                id: 'unknown-server-error',
                title: 'An unknown error occurred',
                description: 'Please try again',
            };
    }
};

export const handleError =
    (dispatch?: (action: ToastDispatch) => void) => (error: ErrorResponse) => {
        dispatch?.({
            type: ToastActions.ADD,
            payload: errorToToast(error),
        });

        throw new Error();
    };
