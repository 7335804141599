import { OptionId } from '../elements/AdvancedSelect';
import createClient, { client } from './http';
import {
    Inbox,
    InboxAssignment,
    InboxProvider,
    TeamsPhoneResponse,
} from './types';

export const markAllAsReadForInbox = ({ inboxId }: { inboxId: string }) =>
    client
        .post<{
            raw: [];
            affected: 2;
        }>(`/inbox/${inboxId}/markAllAsRead`)
        .then(({ data }) => data);

type ReservePhoneDto = {
    phone: string;
    provider: InboxProvider;
    name?: string;
    twilioSid?: string;
    twilioToken?: string;
    telnyxApiKey?: string;
};

export type ListChanneslDto = {
    provider: InboxProvider;
    twilioSid?: string;
    twilioToken?: string;
    telnyxApiKey?: string;
};

export function getUserInboxAssignments() {
    return createClient()
        .get<InboxAssignment[]>('/inbox/user-inbox-assignments')
        .then(({ data }) => data);
}

export function listAvailableChannels(dto: ListChanneslDto) {
    return createClient()
        .post<string[]>('/inbox-provisioner/list', dto)
        .then(({ data }) => data);
}

export function reservePhone(dto: ReservePhoneDto) {
    return createClient()
        .post<Inbox>('/inbox-provisioner/provision', dto)
        .then(({ data }) => data);
}

export function addUserToInbox({
    inboxId,
    userId,
}: {
    inboxId: string;
    userId: string;
}) {
    return createClient()
        .put(`/inbox/${inboxId}/addUser`, { userId })
        .then(({ data }) => data);
}

export function removeUserFromInbox({
    inboxId,
    userId,
}: {
    inboxId: string;
    userId: string;
}) {
    return createClient()
        .put(`/inbox/${inboxId}/removeUser`, { userId })
        .then(({ data }) => data);
}

type ExportInboxLogsDto = {
    inboxIds: OptionId[] | string[];
    startDate: Date;
    endDate: Date;
};

export function exportInboxLogs({
    inboxIds,
    startDate,
    endDate,
}: ExportInboxLogsDto) {
    return createClient()
        .post(
            `/conversations/export/all`,
            { inboxIds, startDate, endDate },
            { responseType: 'blob' },
        )
        .then(({ data }) => data);
}

export function removeInbox({ inboxId }: { inboxId: string }) {
    return createClient().delete(`/inbox/${inboxId}`);
}

export function checkHostability({ phones }: { phones: string[] }) {
    return client
        .post<{ hostable: boolean }>(`/inbox-hosting/check-hostability`, {
            phones,
        })
        .then(({ data }) => data);
}

export function hostInbox({ inboxId }: { inboxId: string }) {
    return createClient()
        .post<{ hostable: boolean }>(`/inbox-hosting/host`, {
            inboxId,
        })
        .then(({ data }) => data);
}

export function sendVerificationCall({ phone }: { phone: string }) {
    return createClient()
        .post(`/inbox-hosting/send-verification-call`, {
            phone,
        })
        .then(({ data }) => data);
}

export function verifyVerificationCallCode({
    phone,
    code,
}: {
    phone: string;
    code: string;
}) {
    return createClient()
        .post(`/inbox-hosting/verify-verification-call-code`, {
            phone,
            code,
        })
        .then(({ data }) => data);
}

export function portInbox({ inboxId }: { inboxId: string }) {
    return createClient()
        .post<{ hostable: boolean }>(`/inbox-hosting/port`, {
            inboxId,
        })
        .then(({ data }) => data);
}

export function createBulkForTeams(payload: TeamsPhoneResponse) {
    return createClient()
        .post(`/inbox-hosting/create-bulk`, payload)
        .then(({ data }) => data);
}

export function archiveAllForInbox(payload: { inboxId: string }) {
    return createClient()
        .post(`/inbox/${payload.inboxId}/archiveAll`)
        .then(({ data }) => data);
}

export function setupWhatsappInbox(payload: {
    whatsAppPhoneId: string;
    whatsAppBusinessAccountId: string;
    whatsAppAccessCode: string;
    whatsAppVerificationPin: string | null;
}) {
    return client
        .post(`/whatsapp/setup-inbox`, payload)
        .then(({ data }) => data);
}
