import { Box, ButtonBase, SxProps, Tooltip } from '@mui/material';
import { AdapterLink } from '../ConversationsList/AdapterLink';
import { getViewSelector } from '../ConversationsList/styles';
import { Entity } from '../../pages/NewInbox/query.helpers';
import { DotDivider } from '../common/DotDivider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getSquare } from '../../theme/style.helpers';
import { useTrack } from '../../contexts/analytics';
import { UUID } from '../../types/uuid';
import { useIsVerified } from '../../queries/settings';
import { focusVisible } from '../../theme/focusVisible';

type Props = { entity: Entity; inboxId: UUID; sx?: SxProps };
export const EntitySwitcher = ({ entity, inboxId, sx }: Props) => {
    const track = useTrack();
    const isVerified = useIsVerified();

    return (
        <Box
            display="flex"
            alignItems="center"
            mt={-2}
            mx={4}
            mb={2}
            width={1}
            sx={sx}
        >
            <ButtonBase
                data-testid="sidebar-conversation-filter"
                LinkComponent={AdapterLink}
                sx={
                    (getViewSelector(entity === Entity.Conversations),
                    {
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '1px',
                            borderRadius: 0.5,
                        },
                    })
                }
                href={`/inbox/${inboxId}/${Entity.Conversations}`}
                onClick={() => {
                    track('inbox_conversations_clicked');
                }}
                data-navigation-element
                aria-label="Inbox conversations tab"
            >
                Conversations
            </ButtonBase>
            <DotDivider />
            <ButtonBase
                data-testid="sidebar-campaign-filter"
                LinkComponent={AdapterLink}
                sx={
                    (getViewSelector(entity === Entity.Campaigns),
                    {
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '1px',
                            borderRadius: 0.5,
                        },
                    })
                }
                href={`/inbox/${inboxId}/${Entity.Campaigns}`}
                onClick={() => {
                    track('inbox_campaigns_clicked');
                }}
                data-navigation-element
                aria-label="Inbox campaigns tab"
            >
                Campaigns{' '}
                {!isVerified && (
                    <Tooltip title="Brand registration required">
                        <InfoOutlinedIcon
                            tabIndex={-1}
                            sx={{ ...getSquare(12), ml: 1 }}
                        />
                    </Tooltip>
                )}
            </ButtonBase>
        </Box>
    );
};
