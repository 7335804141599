import { useEffect, useState } from 'react';
import { useMeQueryData } from '../../../../queries/user';
import { useGetMicrosoftTeamsPhones } from '../../../../queries/integrations';
import { useTeams } from 'msteams-react-base-component';

export enum Views {
    NonMSUser = 'nonMSUser',
    NoPermission = 'noPermission',
    WelcomeToWorkspace = 'welcomeToWorkspace',
    CallToVerify = 'callToVerify',
}

export const useTeamsNonWoOnboarding = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState<Views | null>(null);
    const me = useMeQueryData();
    const { data: msPhones, isLoading: isPhonesLoading } =
        useGetMicrosoftTeamsPhones();
    const [{ inTeams }] = useTeams();
    useEffect(() => {
        if (!me?.microsoftId) {
            setView(Views.NonMSUser);
            setIsLoading(false);
        }
        if (msPhones?.phones && !isPhonesLoading) {
            const isAssigned = msPhones.phones.some(
                (phone) => phone?.user?.userPrincipalName === me?.email,
            );
            if (msPhones.phones.length === 0 || !isAssigned) {
                setView(Views.CallToVerify);
                setIsLoading(false);
            } else {
                setView(Views.WelcomeToWorkspace);
                setIsLoading(false);
            }
        }
        if (!msPhones?.phones && !isPhonesLoading) {
            setView(Views.CallToVerify);
            setIsLoading(false);
        }
    }, [me, msPhones, isPhonesLoading, inTeams]);
    return { isLoading, view };
};
