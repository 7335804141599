import { IconButton, Popper, Tooltip } from '@mui/material';
import { useState } from 'react';
import { EmojiIcon } from './EmojiIcon';
import EmojiPicker from '../../../../EmojiPicker';
import { insertText } from '../../../utils';
import { memo } from 'react';
import { CustomEditor } from '../../../slate';

export const EmojiButton = memo(function EmojiButton({
    disabled = false,
    editor,
}: {
    disabled?: boolean;
    editor: CustomEditor;
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <>
            <Tooltip title="Add Emoji" arrow placement="top">
                <span>
                    <IconButton
                        color="primary"
                        disabled={disabled}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                            setAnchorEl((ref) => (ref ? null : e.currentTarget))
                        }
                        aria-label="Add Emoji"
                    >
                        <EmojiIcon />
                    </IconButton>
                </span>
            </Tooltip>

            {!!anchorEl && (
                <Popper
                    open
                    anchorEl={anchorEl}
                    placement="top-start"
                    disablePortal
                >
                    <EmojiPicker
                        onClick={(emoji) => {
                            setAnchorEl(null);
                            insertText(editor, emoji);
                        }}
                        onClose={() => setAnchorEl(null)}
                    />
                </Popper>
            )}
        </>
    );
});
