import dayjs, { Dayjs } from 'dayjs';

export function activationDateValidation(
    date: Date,
    allPhonesMatchBandwidth?: { match: boolean },
) {
    const requestedDate = dayjs(date);

    if (isWeekend(requestedDate) || isUSHoliday(requestedDate.toDate())) {
        return "Activation date can't be on a weekend or holiday";
    }

    if (!requestedDate.isAfter(dayjs())) {
        return 'Activation date must be in the future';
    }

    if (
        !allPhonesMatchBandwidth?.match &&
        requestedDate.diff(dayjs(), 'hours') < 72
    ) {
        return 'Number must be activated 3 days from now';
    }

    return null;
}

export function isWeekend(date: Dayjs) {
    return ['sa', 'su'].includes(date.format('dd').toLowerCase());
}

/**
 * Get the next available date for activation
 * Returned date will be at least 3 days from now and not on a weekend or holiday
 *
 * @param date - The date to start from (default is today)
 */
export function getNextAvailableDate(date: Dayjs = dayjs()) {
    let inThreeDays = date
        .add(3, 'day')
        .hour(11)
        .minute(0)
        .second(0)
        .tz('America/New_York');

    while (activationDateValidation(inThreeDays.toDate()) !== null) {
        inThreeDays = inThreeDays.add(1, 'day');
    }

    return inThreeDays;
}

export function getNextAvailableDateInstant(date: Dayjs = dayjs()) {
    // Instant activation is only available between 7am and 5pm EST Monday - Friday
    // Check if current time satisfies this condition
    const dateEST = date.tz('America/New_York');
    if (
        dateEST.hour() >= 8 &&
        dateEST.hour() < 17 &&
        dateEST.day() >= 1 &&
        dateEST.day() <= 5
    ) {
        return date;
    }

    // If date is on a weekend or holiday, we need to find the next available date
    if (isWeekend(dateEST) || isUSHoliday(dateEST.toDate())) {
        return getNextAvailableDateInstant(
            dateEST.add(1, 'day').hour(8).minute(0).second(0),
        );
    }

    // date is on a weekday, but not in the right time window
    return getNextAvailableDateInstant(dateEST.add(1, 'hour'));
}

const matchDay = (
    d: Date,
    month: number,
    day: number,
    occurrence: number,
    isNextDay = false,
) => {
    const date = new Date(d);
    if (isNextDay) {
        date.setDate(date.getDate() - 1);
    }

    if (date.getMonth() !== month - 1 || date.getDay() !== day) {
        return false;
    }

    if (occurrence > 0) {
        // check n-th occurrence
        return occurrence === Math.ceil(date.getDate() / 7);
    }

    // check last occurrence
    const dt = new Date(date);
    dt.setDate(date.getDate() + 7);
    return dt.getMonth() > date.getMonth();
};

const matchDate = (d: Date, month: number, date: number) => {
    return d.getMonth() === month - 1 && d.getDate() === date;
};

export function isUSHoliday(date: Date) {
    return (
        // New Year
        matchDate(date, 1, 1) ||
        // Independence Day
        matchDate(date, 7, 4) ||
        // Veterans Day
        matchDate(date, 11, 11) ||
        // Election Day
        matchDate(date, 11, 5) ||
        // Christmas Eve
        matchDate(date, 12, 24) ||
        // Christmas Day
        matchDate(date, 12, 25) ||
        // Good Friday
        matchDate(date, 3, 29) ||
        // Juneteenth
        matchDate(date, 6, 19) ||
        // MLK Day
        matchDay(date, 1, 1, 3) ||
        // President's Day
        matchDay(date, 2, 1, 3) ||
        // Memorial Day
        matchDay(date, 5, 1, -1) ||
        // Labor Day
        matchDay(date, 9, 1, 1) ||
        // Columbus Day
        matchDay(date, 10, 1, 2) ||
        // Thanksgiving Day
        matchDay(date, 11, 4, 4) ||
        // Day after Thanksgiving Day
        matchDay(date, 11, 4, 4, true)
    );
}
