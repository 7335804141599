import { Box, Button, Link, Typography } from '@mui/material';
import { onboardingFooter } from './styles';
import { NavLink } from 'react-router-dom';
import { useUpdateTeam } from '../../queries/team';
import { useTrack } from '../../contexts/analytics';
import { useCallback } from 'react';

export const OnboardingFooter = () => {
    const updateTeam = useUpdateTeam();
    const track = useTrack();

    const skipSetup = useCallback(() => {
        track('onboarding_skipped');
        updateTeam.mutate({
            onboarding: {
                teamsLicense: true,
                inboxActivation: true,
                brandRegistration: true,
                integration: true,
            },
        });
    }, [track, updateTeam]);

    return (
        <Box sx={onboardingFooter}>
            <Typography color="custom.text.secondary" variant="body3">
                Need help? Contact our
                <NavLink
                    to="https://support.clerk.chat"
                    target="blank"
                >{` support team ->`}</NavLink>
            </Typography>
            <Link onClick={skipSetup}>
                <Button
                    disabled={updateTeam.isPending}
                    onClick={skipSetup}
                    variant="text"
                >
                    {updateTeam.isPending ? 'Skipping...' : 'Skip setup'}
                </Button>
            </Link>
        </Box>
    );
};
