import { useMeQueryData } from '../../queries/user';
import { Contact, ContactPhone, ContactPropertyType } from '../../api/types';
import { PropertySource } from '../ContactProperty/types';
import { formatPhoneNumber, parsePhoneNumber } from '../../utils/phoneNumber';
import UserProperty from './UserProperty';
import {
    useContactPhoneCreate,
    useContactPhoneDelete,
    useContactPhoneUpdate,
} from '../../queries/contact-phones';
import noop from 'lodash/noop';
import { useCallback } from 'react';
import { UserPropertyModel } from './types';
import { useContactCreateQuery } from '../../queries/contacts';

export function ContactPhonesProperty({
    contact,
    onCreate = noop,
}: {
    onCreate?: (c: Contact) => void;
    contact: Partial<Contact>;
}) {
    const countryCode = useMeQueryData()?.activeTeam?.countryCode ?? 'US';
    const { mutateAsync: createContact } = useContactCreateQuery();
    const { mutate: createContactPhone } = useContactPhoneCreate();
    const { mutate: updateContactPhone } = useContactPhoneUpdate();
    const { mutateAsync: deleteContactPhone } = useContactPhoneDelete();
    const defaultPhones: ContactPhone[] = [
        {
            id: 'draft',
            phone: contact?.phone ?? '',
            label: 'Phone',
            isPrimary: true,
            contactId: contact?.id ?? '',
        },
    ];
    const phones = contact?.phones?.length ? contact?.phones : defaultPhones;

    const handleUpdate = useCallback(
        (phone: ContactPhone) => {
            if (contact?.id !== undefined) {
                if (phone.id === 'draft') {
                    createContactPhone({
                        id: contact.id,
                        phone: phone.phone,
                        label: phone.label,
                        isPrimary: phones?.length === 1,
                    });
                } else {
                    updateContactPhone({
                        id: contact.id,
                        phoneId: phone.id,
                        phone: phone.phone,
                        label: phone.label,
                        isPrimary: phone.isPrimary,
                    });
                }
            } else {
                createContact(contact).then((c) => {
                    createContactPhone({
                        id: c.id,
                        phone: phone.phone,
                        label: phone.label,
                        isPrimary: true,
                    });

                    onCreate(c);
                });
            }
        },
        [
            contact,
            createContact,
            createContactPhone,
            onCreate,
            phones?.length,
            updateContactPhone,
        ],
    );

    const handleRemove = (phone: UserPropertyModel) => {
        if (!contact.id || !phone.id) {
            return;
        }

        deleteContactPhone({
            id: contact.id,
            phoneId: phone.id,
        }).then(() => {
            const nextContact = {
                ...contact,
                phones: (contact?.phones ?? []).filter(
                    ({ id }) => id === phone.id,
                ),
            } as Contact;
            onCreate(nextContact);
        });
    };

    return phones.map((phone) => (
        <UserProperty
            key={phone.id}
            contactId={phone.contactId}
            userProperty={{
                id: phone.id,
                type: ContactPropertyType.Phone,
                name: phone.label,
                value: phone,
                icon: 'phone',
                source: PropertySource.Clerk,
                readonlyIcon: true,
                readonly: !!contact?.externalId,
            }}
            onDelete={handleRemove}
            onPropertyChange={(data) => {
                handleUpdate({
                    ...phone,
                    label: data.name,
                });
            }}
            onValueChange={(value) => {
                const parsedPhone = parsePhoneNumber(
                    value?.value ?? '',
                    countryCode,
                );

                if (!parsedPhone) {
                    return;
                }

                handleUpdate({
                    ...phone,
                    phone: parsedPhone,
                });
            }}
            valueTransformer={(value) =>
                formatPhoneNumber(String(value ?? ''), countryCode)
            }
        />
    ));
}
