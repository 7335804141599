import { useMemo } from 'react';
import partition from 'lodash/partition';
import { availableIntegrations } from './integrations-list';
import { useMeQueryData } from '../../../queries/user';
import { IntegrationSource, IntegrationTypes } from '../../../api/types';
import { useListIntegrations } from '../../../queries/integrations';

export function useFilteredIntegrations(filter: string, searchTerm?: string) {
    const me = useMeQueryData();
    const { data: myIntegrations = [] } = useListIntegrations();

    const integrations = availableIntegrations;

    return useMemo(() => {
        const whitelistedIntegrations = me?.activeTeam.whitelistedIntegrations;
        const integrationList = (
            whitelistedIntegrations
                ? integrations.filter((availableIntegration) =>
                      whitelistedIntegrations.includes(
                          availableIntegration.source as IntegrationSource,
                      ),
                  )
                : integrations
        )
            .filter((availableIntegration) => {
                if (filter === 'all') {
                    return true;
                }
                return availableIntegration.filter === filter;
            })
            .filter((availableIntegration) => {
                if (!searchTerm || !searchTerm.length) {
                    return true;
                }

                return availableIntegration.name
                    .toLocaleLowerCase()
                    .includes(searchTerm);
            })
            .filter((availableIntegration) => {
                return (
                    !availableIntegration.forFeature ||
                    me?.features.some(
                        (f) =>
                            f.name === availableIntegration.forFeature &&
                            f.enabled,
                    )
                );
            })
            .filter((availableIntegration) => {
                if (
                    availableIntegration.source ===
                        IntegrationSource.Microsoft &&
                    availableIntegration.integrationType ===
                        IntegrationTypes.Data
                ) {
                    return me?.activeTeam.microsoftTenantId;
                }
                return true;
            });
        const [availableList, comingSoonList] = partition(integrationList, {
            comingSoon: false,
        });

        const [connectedList, notConnectedList] = partition(
            availableList,
            (availableIntegration) =>
                myIntegrations.find(
                    (integration) =>
                        integration.integrationSource ===
                            availableIntegration.source &&
                        integration.integrationType ===
                            availableIntegration.integrationType,
                ),
        );

        return {
            comingSoonIntegrations: comingSoonList,
            connectedIntegrations: connectedList,
            availableIntegrations: notConnectedList,
        };
    }, [
        me?.activeTeam.whitelistedIntegrations,
        me?.activeTeam.microsoftTenantId,
        integrations,
        filter,
        searchTerm,
        myIntegrations,
        me?.features,
    ]);
}
