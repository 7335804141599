import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { pluralize } from '../../../utils/pluralize';
import { useGetSetting } from '../../../queries/user';
import { UserTeamSettingType } from '../../../api/types';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import { useTrack } from '../../../contexts/analytics';
import { focusVisible } from '../../../theme/focusVisible';

type DialogProps = { contactsCount: number };

export const useConfirmSending = (): [
    () => Promise<boolean>,
    (p: DialogProps) => React.JSX.Element,
] => {
    const track = useTrack();
    const { data } = useGetSetting(UserTeamSettingType.Signature);
    const [open, setOpen] = useState<boolean>(false);
    const [rejector, setRejector] = useState(() => () => setOpen(false));
    const [resolver, setResolver] = useState(() => () => setOpen(false));

    const dialog = ({ contactsCount }: DialogProps) => (
        <Dialog
            open={open}
            onClose={() => rejector()}
            maxWidth="xs"
            data-navigation-area="modal"
        >
            <DialogTitle
                textAlign="center"
                variant="h3"
                aria-label={`Campaign sending. Ready to send a campaign message to ${pluralize(contactsCount, 'contact', 'contacts')}? Confirm
                    the message sending process.
                `}
            >
                Campaign sending
            </DialogTitle>
            <DialogContent>
                <Typography variant="body3" color="custom.text.secondary">
                    Ready to send a campaign message to{' '}
                    {pluralize(contactsCount, 'contact', 'contacts')}? Confirm
                    the message sending process.
                    {data?.enabled && (
                        <Alert severity="warning" sx={{ mt: 2 }}>
                            <AlertTitle>
                                Your signature will be added to each message.
                            </AlertTitle>
                        </Alert>
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        const slateEditorElement = document.querySelector(
                            '[data-slate-editor]',
                        );
                        if (slateEditorElement) {
                            setTimeout(() => {
                                slateEditorElement.focus();
                            }, 0);
                        }
                        rejector();
                    }}
                    sx={{
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '2px',
                        },
                    }}
                    variant="outlined"
                    data-navigation-element
                    tabIndex={0}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        const slateEditorElement = document.querySelector(
                            '[data-slate-editor]',
                        );
                        if (slateEditorElement) {
                            setTimeout(() => {
                                slateEditorElement.focus();
                            }, 0);
                        }
                        resolver();
                    }}
                    sx={{
                        '&:focus-visible': {
                            ...focusVisible,
                            outlineOffset: '2px',
                        },

                        minWidth: 200,
                    }}
                    fullWidth
                    data-navigation-element
                    tabIndex={0}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );

    return [
        () => {
            setOpen(true);
            return new Promise((resolve, reject) => {
                setResolver(() => () => {
                    setOpen(false);
                    resolve(true);
                });
                setRejector(() => () => {
                    track('campaign_message_dialog_dismiss', {
                        signature: data?.enabled ? 'yes' : 'no',
                    });
                    setOpen(false);
                    reject(false);
                });
            });
        },
        dialog,
    ];
};
