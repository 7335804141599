import { Box, Paper, Typography } from '@mui/material';
import { IconOuterButton } from '../../IconOuterButton/IconOuterButton';
import { ArrowDownwardFilled } from '../../../icons/common/CArrowDownwardFilled';
import { ArrowUpwardFilled } from '../../../icons/common/CArrowUpwardFilled';
import { Enter } from '../../../icons/common/CEnter';
import { Esc } from '../../../icons/common/CEsc';

export const Legend = () => {
    return (
        <Paper
            sx={{
                display: 'flex',
                px: 3,
                py: 4,
                gap: 8,
                borderRadius: 0,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                border: '1px solid',
                borderColor: 'custom.gray.tableDivider',
                backgroundColor: 'custom.gray.superLight',
                borderTop: 0,
                boxShadow: 0,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconOuterButton>
                        <ArrowDownwardFilled />
                    </IconOuterButton>
                    <IconOuterButton>
                        <ArrowUpwardFilled />
                    </IconOuterButton>
                </Box>
                <Typography variant="body4" color="custom.text.secondary">
                    Navigate
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <IconOuterButton>
                    <Enter />
                </IconOuterButton>
                <Typography variant="body4" color="custom.text.secondary">
                    Select
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
            >
                <IconOuterButton>
                    <Esc />
                </IconOuterButton>
                <Typography variant="body4" color="custom.text.secondary">
                    Dismiss
                </Typography>
            </Box>
        </Paper>
    );
};
