import { getValueFromProperty } from './utils';
import { Contact, ContactPropertyType, Feature } from '../../api/types';
import CollapsiblePropertiesSection from '../../components/CollapsiblePropertiesSection';
import { UserPropertyModel } from '../../components/UserInfoProperty/types';
import UserProperty from '../../components/UserInfoProperty/UserProperty';
import {
    useContactCreateQuery,
    useContactUpdateQuery,
} from '../../queries/contacts';
import { useDeleteProperty, useUpdateProperty } from '../../queries/properties';
import { formatPhoneNumber, parsePhoneNumber } from '../../utils/phoneNumber';
import { useEnabledFeature, useMeQueryData } from '../../queries/user';
import AddNewProperty from '../../components/ContactProperty/AddNewProperty';
import { PropertySource } from '../../components/ContactProperty/types';
import noop from 'lodash/noop';
import { OnFeature } from '../Feature/OnFeature';
import { ContactPhonesProperty } from '../../components/UserInfoProperty/ContactPhonesProperty';

interface ContactPropertiesGroupProps {
    title: string;
    contact: Partial<Contact>;
    properties: UserPropertyModel[];
    canCreateProps?: boolean;
    initiallyExpanded?: boolean;
    onCreate?: (c: Contact) => void;
}

export const ContactPropertiesGroup = ({
    onCreate = noop,
    ...props
}: ContactPropertiesGroupProps) => {
    const user = useMeQueryData();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const { mutateAsync: updateProperty } = useUpdateProperty();
    const { mutate: deleteProperty } = useDeleteProperty();
    const { mutate: updateContact } = useContactUpdateQuery();
    const { mutateAsync: createContact } = useContactCreateQuery();

    const handlePropertyChange = (prop: UserPropertyModel) => {
        updateProperty({ id: prop.id, params: prop });
    };

    return (
        <CollapsiblePropertiesSection
            title={props.title}
            initiallyExpanded={props.initiallyExpanded}
        >
            {props.canCreateProps && (
                <>
                    <OnFeature
                        feature={Feature.MultipleContactPhones}
                        with={
                            <ContactPhonesProperty
                                contact={props.contact}
                                onCreate={onCreate}
                            />
                        }
                        without={
                            <UserProperty
                                contactId={props.contact?.id}
                                userProperty={{
                                    id: '',
                                    type: ContactPropertyType.Phone,
                                    name: 'Phone',
                                    value: props.contact?.phone || '',
                                    icon: 'phone',
                                    source: PropertySource.Clerk,
                                }}
                                onPropertyChange={handlePropertyChange}
                                onValueChange={(value) => {
                                    if (props.contact?.id) {
                                        updateContact({
                                            ...props.contact,
                                            phone: parsePhoneNumber(
                                                (
                                                    value as UserPropertyModel<string>
                                                )?.value ?? '',
                                                user!.activeTeam.countryCode,
                                            ),
                                        } as Contact);
                                    } else {
                                        createContact({
                                            ...props.contact,
                                            phone: parsePhoneNumber(
                                                (
                                                    value as UserPropertyModel<string>
                                                )?.value ?? '',
                                                user!.activeTeam.countryCode,
                                            ),
                                        }).then((c) => {
                                            onCreate(c);
                                        });
                                    }
                                }}
                                valueTransformer={(value) =>
                                    formatPhoneNumber(
                                        String(value ?? ''),
                                        user?.activeTeam.countryCode,
                                    )
                                }
                            />
                        }
                    />

                    <UserProperty
                        contactId={props.contact?.id}
                        userProperty={{
                            id: '',
                            type: ContactPropertyType.Email,
                            name: 'Email',
                            readonly: true,
                            value: props.contact?.email,
                            icon: 'email-2',
                            source: PropertySource.Clerk,
                        }}
                        onPropertyChange={handlePropertyChange}
                        onValueChange={(value) => {
                            if (props.contact?.id) {
                                updateContact({
                                    ...props.contact,
                                    email: (value as UserPropertyModel<string>)
                                        ?.value,
                                } as Contact);
                            } else {
                                createContact({
                                    ...props.contact,
                                    email: (value as UserPropertyModel<string>)
                                        ?.value,
                                }).then(onCreate);
                            }
                        }}
                    />
                </>
            )}

            {props.properties
                .filter(
                    (prop) =>
                        !(
                            isMultipleContactPhonesEnabled &&
                            prop.type === ContactPropertyType.Phone
                        ),
                )
                .map((property) => (
                    <UserProperty
                        key={property.id}
                        contactId={props.contact.id}
                        userProperty={{
                            value: props.contact
                                ? getValueFromProperty(props.contact, property)
                                : null,
                            ...property,
                        }}
                        onPropertyChange={handlePropertyChange}
                        onOptionCreate={async (option) => {
                            if (option && option.length) {
                                return await updateProperty({
                                    id: property.id!,
                                    params: {
                                        name: property.name,
                                        icon: property.icon,
                                        type: property.type,
                                        options: property.options
                                            ? property.options.concat(option)
                                            : [option],
                                    },
                                });
                            }

                            return Promise.reject(property);
                        }}
                        onValueChange={(property) => {
                            const payload = {
                                data: {
                                    ...props.contact?.data,
                                    [property.id]: property.value,
                                },
                            };

                            if (props.contact?.id) {
                                updateContact({
                                    ...props.contact,
                                    ...payload,
                                    id: props.contact?.id,
                                });
                            } else {
                                createContact({
                                    ...props.contact,
                                    ...payload,
                                }).then(onCreate);
                            }
                        }}
                        onDelete={(a) => deleteProperty(a.id!)}
                    />
                ))}

            {props.canCreateProps && <AddNewProperty contact={props.contact} />}
        </CollapsiblePropertiesSection>
    );
};
