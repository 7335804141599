import { useEffect, useRef, useState } from 'react';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import Input from '../../../../elements/Inputs/Input';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { Box, Typography } from '@mui/material';
import { limitedValue } from '../styles';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';

export const EmailValue = ({ userProperty, onChange }: ValueProps<string>) => {
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    useOnClickOutside(inputRef, () => setEditEnabled(false));

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
        }
    }, [isEditEnabled]);

    if (isEditEnabled) {
        return (
            <Input
                aria-label={`${userProperty.name} value input`}
                type="email"
                wrapperClassName={styles['edit-input-wrapper']}
                className={styles['edit-input']}
                value={userProperty.value}
                blurOnEnter={true}
                onBlur={(value: string) => {
                    setEditEnabled(false);
                    onChange?.({ ...userProperty, value });
                }}
                ref={inputRef}
                fullWidth
            />
        );
    }

    return (
        <Box
            sx={limitedValue}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                <Typography
                    aria-label={`${userProperty.name} value`}
                    variant="body3"
                    color="primary.dark"
                    noWrap
                    sx={{
                        display: 'inline-block',
                    }}
                >
                    {userProperty.value}
                </Typography>
            ) : (
                <EmptyValuePlaceholder
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
