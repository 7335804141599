import { useNavigate } from 'react-router';
import { ClerkPermission } from '../../api/types';
import logo from '../../elements/IconComponents/clerk-logo-header.svg';
import { useMeQueryData } from '../../queries/user';
import {
    Avatar,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { useListTeams, useSwitchTeamQuery } from '../../queries/team';
import { useMenu } from '../../hooks/use-menu';
import { pluralize } from '../../utils/pluralize';
import { setAuthToStorage } from '../../api/team';
import { queryClient } from '../../queries/queryClient';
import { signOut } from '../../api/auth';
import { ActiveIcon } from './ActiveIcon';
import { Link } from 'react-router-dom';
import { NotificationsIcon } from '../../containers/SettingsLayout/Navigation/icons/NotificationsIcon';
import { InformationIcon } from '../../icons/common/InformationIcon';
import { WithPermission } from '../../containers/WithPermission/WithPermission';
import { CompanyIcon } from '../../icons/common/CCompanyIcon';
import { MembersIcon } from '../../icons/common/CMembersIcon';
import { useTrack } from '../../contexts/analytics';
import { avatar, limitedName } from './styles';
import { MailboxIcon } from '../../icons/common/MailboxIcon';
import { UserProfileIcon } from '../../icons/common/UserProfileIcon';
import { TeamCreateListItem } from './TeamCreateListItem';
import { ShowForPaidTeam } from '../../guards/ShowForPaidTeam';
import { TeamSelectorButton } from './TeamSelectorButton';
import { DEFAULT_TEAM_NAME } from './constants';

type Props = {
    teamOnly?: boolean;
    withBackground?: boolean;
};

export const TeamSelector = ({
    teamOnly = false,
    withBackground = false,
}: Props) => {
    const track = useTrack();
    const navigate = useNavigate();
    const me = useMeQueryData();
    const { anchorEl, open, handleOpen, handleClose } = useMenu();
    const { data: teams = [] } = useListTeams();
    const { mutateAsync: setTeam } = useSwitchTeamQuery();

    return (
        <>
            <TeamSelectorButton
                open={open}
                handleOpen={handleOpen}
                withBackground={withBackground}
            />
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                slotProps={{
                    paper: {
                        elevation: 5,
                        sx: {
                            width: 216,
                            mt: withBackground ? 2 : -1,
                            boxSizing: 'content-box',
                            p: 1,
                            pb: 0,
                            '& .MuiButtonBase-root.MuiMenuItem-root.Mui-disabled':
                                {
                                    py: 0,
                                    px: 0,
                                    opacity: 1,
                                },
                        },
                    },
                }}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    horizontal: 'left',
                    vertical: 'top',
                }}
                MenuListProps={{
                    'aria-labelledby': 'con-t-team-menu',
                }}
            >
                <MenuItem disabled sx={{ mt: 1 }}>
                    <Typography
                        variant="body4"
                        color="custom.gray.super"
                        sx={{
                            display: 'block',
                            px: 2,
                            mb: 1,
                        }}
                    >
                        My{' '}
                        {pluralize(teams.length, 'team', 'teams', 'team', '')}
                    </Typography>
                </MenuItem>
                {teams.map((team) => (
                    <MenuItem
                        key={team.id}
                        selected={team.id === me?.activeTeam?.id}
                        onClick={() => {
                            setTeam(team.id).then((data) => {
                                track('team_switched', {
                                    teamId: team.id,
                                });
                                setAuthToStorage(data);
                                navigate('/');
                                queryClient.resetQueries();
                            });
                            handleClose();
                        }}
                        sx={{ overflow: 'hidden' }}
                        data-navigation-element
                        tabIndex={0}
                    >
                        <ListItemIcon>
                            <Avatar
                                sx={avatar}
                                alt={team?.name || DEFAULT_TEAM_NAME}
                                src={team?.teamAvatarURL || logo}
                            />
                        </ListItemIcon>

                        <ListItemText
                            primaryTypographyProps={{
                                sx: limitedName,
                            }}
                        >
                            {team.name ?? 'Team'}
                        </ListItemText>
                    </MenuItem>
                ))}
                <ShowForPaidTeam>
                    <WithPermission
                        requiredPermissions={[ClerkPermission.CreateTeam]}
                    >
                        <TeamCreateListItem />
                    </WithPermission>
                </ShowForPaidTeam>

                {!teamOnly && (
                    <>
                        <Divider />
                        <MenuItem disabled sx={{ mt: 2 }}>
                            <Typography
                                variant="body4"
                                color="custom.gray.super"
                                sx={{
                                    display: 'block',
                                    px: 2,
                                    mt: 2,
                                    mb: 1,
                                }}
                            >
                                Account settings
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            component={Link}
                            to="/settings/profile"
                            onClick={() => {
                                track('user_menu_clicked', {
                                    target: 'profile',
                                });
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                <UserProfileIcon />
                            </ListItemIcon>
                            <ListItemText>My Profile</ListItemText>
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/settings/notifications"
                            onClick={() => {
                                track('user_menu_clicked', {
                                    target: 'notifications',
                                });
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                <NotificationsIcon />
                            </ListItemIcon>
                            <ListItemText>Notifications</ListItemText>
                        </MenuItem>
                        <WithPermission
                            requiredPermissions={[ClerkPermission.ModifyTeam]}
                        >
                            <MenuItem
                                component={Link}
                                to="/settings/team"
                                onClick={() => {
                                    track('user_menu_clicked', {
                                        target: 'team',
                                    });
                                }}
                                data-navigation-element
                                tabIndex={0}
                            >
                                <ListItemIcon>
                                    <CompanyIcon />
                                </ListItemIcon>
                                <ListItemText>Team Info</ListItemText>
                            </MenuItem>
                        </WithPermission>
                        <WithPermission
                            requiredPermissions={[ClerkPermission.ModifyTeam]}
                        >
                            <MenuItem
                                component={Link}
                                to="/settings/members"
                                onClick={() => {
                                    track('user_menu_clicked', {
                                        target: 'members',
                                    });
                                }}
                                data-navigation-element
                                tabIndex={0}
                            >
                                <ListItemIcon>
                                    <MembersIcon />
                                </ListItemIcon>
                                <ListItemText>Members</ListItemText>
                            </MenuItem>
                        </WithPermission>

                        <Divider />

                        <MenuItem
                            href="https://support.clerk.chat/Clerk-Help-Center-cea421f30cf64e579bd15851de5dfc2c"
                            target="_blank"
                            rel="noreferrer noopener"
                            component="a"
                            onClick={() => {
                                track('user_menu_clicked', {
                                    target: 'support',
                                });
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                <InformationIcon />
                            </ListItemIcon>
                            <ListItemText>Get Support ↗</ListItemText>
                        </MenuItem>
                        <MenuItem
                            href="https://feedback.clerk.chat/"
                            target="_blank"
                            rel="noreferrer noopener"
                            component="a"
                            onClick={() => {
                                track('user_menu_clicked', {
                                    target: 'feedback',
                                });
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                <MailboxIcon />
                            </ListItemIcon>
                            <ListItemText>Leave feedback ↗</ListItemText>
                        </MenuItem>

                        <Divider />

                        <MenuItem
                            sx={{
                                color: 'error.main',
                            }}
                            onClick={async () => {
                                track('sign_out');
                                await signOut();
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                <ActiveIcon sx={{ color: 'error.main' }} />
                            </ListItemIcon>
                            <ListItemText>Log out</ListItemText>
                        </MenuItem>
                    </>
                )}
            </Menu>
        </>
    );
};
