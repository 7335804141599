import { Query, useQueries, useQuery } from '@tanstack/react-query';
import { aggregator } from './LookupAggregator/phone-lookup-aggregator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isString from 'lodash/isString';
import { useEnabledFeature, useMeQueryData } from '../../queries/user';
import { getPlaceholder } from './get-placeholder';
import { queryClient } from '../../queries/queryClient';
import { Contact, Feature } from '../../api/types';

const initTime = new Date().getTime();

export const CONTACT_BY_PHONE_KEY = 'contact_by_phone';

export const useByPhone = (phone: string = '') => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useQuery({
        queryKey: [CONTACT_BY_PHONE_KEY, phone, isMultipleContactPhonesEnabled],
        queryFn: () =>
            aggregator.getContact(phone, isMultipleContactPhonesEnabled),
        refetchOnMount: ({ state }) =>
            state.dataUpdatedAt < initTime ? 'always' : false,
        staleTime: 60_000,
        meta: {
            shouldCache: isValidPhoneNumber(phone),
        },
        enabled: isValidPhoneNumber(phone),
    });
};

export const getByPhone = (
    phone: string,
    isMultipleContactPhonesEnabled: boolean,
) =>
    queryClient.getQueryData<Contact>([
        CONTACT_BY_PHONE_KEY,
        phone,
        isMultipleContactPhonesEnabled,
    ]);

export const useByPhones = (phones: string[]) => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useQueries({
        queries: phones.filter(isString).map((phone) => ({
            queryKey: [
                CONTACT_BY_PHONE_KEY,
                phone,
                isMultipleContactPhonesEnabled,
            ],
            queryFn: () =>
                aggregator.getContact(phone, isMultipleContactPhonesEnabled),
            refetchOnMount: ({ state }: Query) =>
                state.dataUpdatedAt < initTime ? 'always' : false,
            staleTime: 60_000,
            meta: {
                shouldCache: isValidPhoneNumber(phone),
            },
            enabled: isValidPhoneNumber(phone),
        })),
    });
};

export const usePlaceholder = (phone: string) => {
    const defaultValue =
        useMeQueryData()?.activeTeam.contactsPublicByDefault ?? false;
    return getPlaceholder(phone, {
        public: defaultValue,
    });
};
