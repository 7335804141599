import { lazy, Suspense } from 'react';
import { Skeleton } from '@mui/material';

interface Props {
    errorCode?: number | null;
}

const FailedStatus = lazy(() => import('./FailedStatus'));

export const LazyFailedStatus = ({ errorCode }: Props) => (
    <Suspense fallback={<Skeleton width={140} height={14} />}>
        <FailedStatus errorCode={errorCode} />
    </Suspense>
);
