import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { Redirect } from '../utils/redirect';
import { askNotificationPermission } from '../notifications';
import { useMeQueryData } from '../queries/user';
import { get } from '../utils/local-storage';
import { useSocket } from '../websocket';
import { useNotifications } from './use-notifications';
import { useTeamsSubPage } from '../hooks/useTeamsSubPage';
import SideBar from '../containers/SideBar';
import useClarity from '../hooks/useClarity';
import { Box } from '@mui/material';
import { useTrack } from '../contexts/analytics';
import { InboxSettingsProvider } from '../components/Inbox/InboxSettingsContext';
import { useSidebar } from '../contexts/sidebar';
import { TenDLCBanner } from './settings/Compliance/TenDLCBanner';
import { useIs10DLCRequired } from './settings/Compliance/useIs10DLCRequired';
import { BANNER_HEIGHT } from '../components/Banner/Banner';

export function Main() {
    useNotifications();
    const socket = useSocket();
    const navigate = useNavigate();
    const subPage = useTeamsSubPage();
    const profile = useMeQueryData();
    const track = useTrack();
    const { isCollapsed } = useSidebar();
    const is10DLCRequired = useIs10DLCRequired();

    const auth = get('auth');
    useClarity();

    useEffect(() => {
        if (profile?.id) {
            track('sign_in');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.id]);

    useEffect(() => {
        if (subPage) {
            navigate(subPage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subPage]);

    useEffect(() => {
        if (profile) {
            askNotificationPermission();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile?.id]);

    useEffect(() => {
        socket.init();
        return () => socket.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    if (!profile) {
        return <Redirect to="/login" />;
    }

    return (
        <>
            {is10DLCRequired && <TenDLCBanner />}
            <Box
                sx={(theme) => ({
                    display: 'grid',
                    minHeight: is10DLCRequired
                        ? `calc(100vh - ${BANNER_HEIGHT}px)`
                        : '100vh',
                    gridTemplateColumns: 'auto 1fr',
                    maxWidth: 1,
                    overflow: 'hidden',

                    [theme.breakpoints.between('md', 'lg')]: {
                        gridTemplateColumns: isCollapsed
                            ? 'auto 1fr'
                            : '72px 1fr',
                    },

                    [theme.breakpoints.down('md')]: {
                        gridTemplateColumns: '1fr',
                        gridTemplateRows: '61px 1fr',
                    },
                })}
            >
                <InboxSettingsProvider>
                    <SideBar />
                    <Outlet />
                </InboxSettingsProvider>
            </Box>
        </>
    );
}
