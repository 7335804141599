import { Button, ButtonGroup } from '@mui/material';

type PaymentPeriodValue = 'monthly' | 'yearly';

interface PaymentPeriodSwitcherProps {
    value: PaymentPeriodValue;
    onChange: (value: PaymentPeriodValue) => void;
}

export const PaymentPeriodSwitcher = ({
    value,
    onChange,
}: PaymentPeriodSwitcherProps) => (
    <ButtonGroup
        sx={{
            p: '3px',
            border: '1px solid #B2BEFB',
            boxSizing: 'border-box',
            '&.MuiButtonGroup-root > .MuiButtonGroup-firstButton': {
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
            },
            '&.MuiButtonGroup-root > .MuiButtonGroup-lastButton': {
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
            },
        }}
    >
        <Button
            variant={value === 'monthly' ? 'contained' : 'text'}
            color="info"
            size="small"
            sx={{
                width: 116,
            }}
            onClick={() => onChange('monthly')}
        >
            Monthly
        </Button>
        <Button
            variant={value === 'yearly' ? 'contained' : 'text'}
            color="info"
            onClick={() => onChange('yearly')}
            size="small"
            sx={{ width: 116 }}
        >
            Yearly
        </Button>
    </ButtonGroup>
);
