import { StepShell } from '../../Onboarding/StepShell';
import {
    Box,
    Button,
    InputAdornment,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useTrack } from '../../../contexts/analytics';
import { useEffect, useState } from 'react';
import { useSetupWhatsappInbox } from '../../../queries/inboxes';
import { useNavigate } from 'react-router';
import { ApiKeyIcon } from '../../SettingsLayout/Navigation/icons/ApiKeyIcon';
import { WhatsApp } from '../../../icons/carriers/WhatsApp';
import { FacebookLogoIcon } from '../../../icons/logos/Facebook';
import useFacebookIntegration from '../../../components/FacebookSdk/FacebookIntegration';
import {
    ToastActions,
    ToastDispatch,
    useToastContext,
} from '../../toast/reducer';
import { NavLink } from 'react-router-dom';

type fbAuthResponse = { code?: string; expiresIn: number; userID?: string };

type whatsAppEmbeddedBaseResponse = {
    type: 'WA_EMBEDDED_SIGNUP';
    version: '3';
};
type whatsAppEmbeddedFinishResponse = {
    data: {
        phone_number_id: string;
        waba_id: string;
    };
    event: 'FINISH';
} & whatsAppEmbeddedBaseResponse;

type whatsAppEmbeddedCancelResponse = {
    data: {
        current_step: string;
    };
    event: 'CANCEL';
} & whatsAppEmbeddedBaseResponse;

const genErrorToasty = (title: string, copy: string): ToastDispatch => {
    return {
        type: ToastActions.ADD,
        payload: {
            severity: 'error',
            title,
            duration: 15000,
            description: (
                <Box width={260}>
                    <span>
                        {copy}{' '}
                        <NavLink
                            to="https://support.clerk.chat"
                            target="blank"
                        >{` support team ->`}</NavLink>
                    </span>
                </Box>
            ),
        },
    };
};

export const WhatsAppAuthStep = () => {
    const navigate = useNavigate();
    const track = useTrack();

    const { dispatch } = useToastContext();

    const [loginResponse, setLoginResponse] = useState<fbAuthResponse | null>(
        null,
    );
    const [whatsAppMessageEvent, setWhatsAppMessageEvent] = useState<
        whatsAppEmbeddedFinishResponse | whatsAppEmbeddedCancelResponse | null
    >(null);

    const [verificationPin, setVerificationPin] = useState<string | null>(null);

    const [pinError, setPinError] = useState<boolean>(false);

    const setupWhatsappInbox = useSetupWhatsappInbox();

    const launchWhatsAppSignup = () => {
        const fbLoginCallback = (response: {
            authResponse: fbAuthResponse;
        }) => {
            if (response['authResponse']) {
                // Extract the access token or other data
                const { authResponse } = response;
                setLoginResponse(authResponse);
                //reset mutation state
                setupWhatsappInbox.reset();
            }
        };

        if (window.FB) {
            window.FB.login(fbLoginCallback, {
                config_id: import.meta.env['VITE_WHATSAPP_CONFIG_ID'],
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    setup: {},
                    featureType: '',
                    sessionInfoVersion: '3',
                },
            });
        }
    };
    const handleMessage = (event: MessageEvent) => {
        if (event.origin !== 'https://www.facebook.com') {
            return;
        }

        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
            setWhatsAppMessageEvent(data);
        }
    };
    const useFacebookMessageHandler = () => {
        useEffect(() => {
            window.addEventListener('message', handleMessage);

            // Cleanup listener on unmount
            return () => {
                window.removeEventListener('message', handleMessage);
            };
        }, []);
    };
    useFacebookIntegration();
    useFacebookMessageHandler();

    useEffect(() => {
        if (
            loginResponse &&
            loginResponse.code &&
            whatsAppMessageEvent &&
            whatsAppMessageEvent.event === 'FINISH' &&
            setupWhatsappInbox.isIdle
        ) {
            setupWhatsappInbox
                .mutateAsync({
                    whatsAppPhoneId: whatsAppMessageEvent.data.phone_number_id,
                    whatsAppBusinessAccountId:
                        whatsAppMessageEvent.data.waba_id,
                    whatsAppAccessCode: loginResponse.code,
                    whatsAppVerificationPin: verificationPin,
                })
                .then((createdInbox) => {
                    if (createdInbox) {
                        return navigate(`/inbox/${createdInbox?.id}/finish`);
                    }
                })
                .catch((error) => {
                    const errorData = error.response.data;
                    switch (errorData?.message) {
                        case 'Inbox Already Exists for this Number':
                            dispatch(
                                genErrorToasty(
                                    'Inbox Already Exists',
                                    'An inbox for this whatsapp number already exists. Please onboard a different number or contact our',
                                ),
                            );
                            break;
                        case 'Security PIN Mismatch':
                            if (pinError) {
                                dispatch(
                                    genErrorToasty(
                                        'Security PIN Mismatch',
                                        'Your provided two-step verification PIN does not match the existing PIN for your Business. Please double check and submit again or contact our',
                                    ),
                                );
                            } else {
                                setPinError(true);
                            }
                            setVerificationPin(null);
                            break;
                        default:
                            dispatch(
                                genErrorToasty(
                                    'General Error',
                                    'Please try again later or contact our',
                                ),
                            );
                    }
                });
        }
    }, [
        dispatch,
        navigate,
        setupWhatsappInbox,
        setLoginResponse,
        loginResponse,
        whatsAppMessageEvent,
        verificationPin,
        pinError,
        setPinError,
    ]);

    return (
        <StepShell
            title="Your WhatsApp Account is ready to be onboarded!"
            icon={<WhatsApp />}
            subtitle={
                <Typography variant="subtitle2">
                    Let&apos;s get you set up with Clerk Chat so you can start
                    texting with your WhatsApp number. We&apos;ll guide you
                    through the process step by step.
                </Typography>
            }
            goBackward="/setup-inbox/existing-number"
        >
            <Stack spacing={6} width={320} alignItems="center">
                {pinError ? (
                    <>
                        <Stack spacing={2} alignItems="center" flex={1}>
                            <Typography variant="body3" textAlign="center">
                                Your Whatsapp Business has{' '}
                                <b>two-step verification</b> enabled. Please
                                submit your <b>two-step verification PIN</b> to
                                continue.
                            </Typography>
                            <TextField
                                type="tel"
                                value={verificationPin}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    // Allow only numbers and restrict to 6 digits
                                    if (/^\d{0,6}$/.test(input)) {
                                        setVerificationPin(input);
                                    }
                                }}
                                fullWidth
                                placeholder="Enter Your 6-Digit Pin"
                                InputProps={{
                                    autoFocus: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ApiKeyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Link
                                href="https://faq.whatsapp.com/1278661612895630"
                                variant="body4"
                                color="info.main"
                                textAlign="center"
                                target="_blank"
                            >
                                What is my two-step verification PIN? -&gt;
                            </Link>
                        </Stack>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            size="large"
                            disabled={
                                setupWhatsappInbox.isPending ||
                                !verificationPin ||
                                verificationPin.length < 6
                            }
                            onClick={() => {
                                track('clicked_whatsapp_embedded_signup', {});
                                setupWhatsappInbox.reset();
                            }}
                            sx={{ width: 320, marginTop: 40 }}
                        >
                            Submit Verification Pin
                        </Button>
                    </>
                ) : (
                    <Box
                        spacing={4}
                        alignItems="center"
                        justifyContent="space-between"
                        flex={1}
                    >
                        <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<FacebookLogoIcon />}
                            color="primary"
                            size="large"
                            disabled={setupWhatsappInbox.isPending}
                            onClick={() => {
                                track('clicked_whatsapp_embedded_signup', {});
                                launchWhatsAppSignup();
                            }}
                            sx={{ width: 320 }}
                        >
                            Continue with Facebook
                        </Button>
                    </Box>
                )}
            </Stack>
        </StepShell>
    );
};
