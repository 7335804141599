import { useEffect, useRef, useState } from 'react';
import styles from '../values.module.scss';
import { ValueProps } from '../../types';
import TextareaAutosize from '../../../../elements/TextareaAutosize';
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import useOnKeyDown from '../../../../hooks/useOnKeyDown';
import { Box, Typography } from '@mui/material';
import { breakableValue } from '../styles';
import { EmptyValuePlaceholder } from '../../EmptyValuePlaceholder';

export const TextValue = ({
    userProperty,
    onChange,
    readonly,
}: ValueProps<string>) => {
    const [value, setValue] = useState(userProperty.value);
    const [isEditEnabled, setEditEnabled] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useOnClickOutside(inputRef, () => {
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Enter', true, (e) => {
        if (e.shiftKey) {
            return;
        }
        onChange?.({ ...userProperty, value });
        setEditEnabled(false);
    });

    useOnKeyDown(inputRef, 'Escape', () => {
        setValue(userProperty.value);
        setEditEnabled(false);
    });

    useEffect(() => {
        setValue(userProperty.value);
    }, [userProperty]);

    useEffect(() => {
        if (isEditEnabled) {
            inputRef.current?.focus();
            const position = value?.length || 0;
            inputRef.current?.setSelectionRange(position, position);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditEnabled]);

    if (isEditEnabled && !readonly) {
        return (
            <TextareaAutosize
                aria-label={`${userProperty.name} value input`}
                ref={inputRef}
                onChange={(val) => setValue(val.trim())}
                wrapperClassName={styles['txtarea-wrapper']}
                textareaClassName={styles['txtarea']}
                disabled={!!userProperty.readonly}
                value={value}
            />
        );
    }
    return (
        <Box
            sx={breakableValue}
            title={userProperty.value}
            onClick={() => setEditEnabled(true)}
        >
            {userProperty.value ? (
                <Typography
                    aria-label={`${userProperty.name} value`}
                    variant="body3"
                    color="primary.dark"
                >
                    {userProperty.value}
                </Typography>
            ) : (
                <EmptyValuePlaceholder
                    text={readonly ? 'Empty' : undefined}
                    aria-label={`${userProperty.name} set a value`}
                />
            )}
        </Box>
    );
};
