import { memo } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import { SendIcon } from '../../../SendIcon';
import { CheckIcon } from '../../../../../icons/common/CCheckIcon';
import { useIsDenseMode } from '../../../useIsDenseMode';
import { focusVisible } from '../../../../../theme/focusVisible';

type Props = {
    edit: boolean;
    canSend: boolean;
    disabled: boolean;
    aiEnabled: boolean;
    handleSubmit: () => void;
    title: string;
};

const buttonSx = {
    height: 28,
    minWidth: 28,
    '&:focus-visible': {
        ...focusVisible,
        outlineOffset: '2px',
    },
};

export const SendButton = memo(function SendButton({
    edit = false,
    canSend = false,
    disabled = false,
    aiEnabled = false,
    title = 'Send',
    handleSubmit,
}: Props) {
    const label = edit ? 'Save' : 'Send';
    const icon = edit ? <CheckIcon /> : <SendIcon />;
    const color = aiEnabled ? 'secondary' : 'info';
    const isDisabled = !canSend || disabled;
    const isDenseMode = useIsDenseMode();

    return (
        <Tooltip title={edit ? 'Save' : title} arrow placement="top">
            <span>
                {isDenseMode ? (
                    <IconButton
                        aria-label={label}
                        disabled={!canSend || disabled}
                        onClick={handleSubmit}
                        color={color}
                        size="small"
                        sx={buttonSx}
                        data-navigation-element={
                            !canSend || disabled ? undefined : 'true'
                        }
                        aria-live={disabled ? 'assertive' : ''}
                    >
                        {icon}
                    </IconButton>
                ) : (
                    <Button
                        aria-label={label}
                        disabled={isDisabled}
                        onClick={handleSubmit}
                        color={color}
                        size="small"
                        startIcon={icon}
                        sx={buttonSx}
                        data-navigation-element={
                            !canSend || disabled ? undefined : 'true'
                        }
                    >
                        {label}
                    </Button>
                )}
            </span>
        </Tooltip>
    );
});
