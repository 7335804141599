import ToggleSwitch from '../../ToggleSwitch';
import { Box, Typography } from '@mui/material';
import { SendIcon } from '../../../icons/common/SendIcon';
import { modeSwitcher } from './styles';
import { Mode } from '../NewConversation';
import { WithFeature } from '../../../containers/Feature/WithFeature';
import { Feature } from '../../../api/types';

interface Props {
    mode: Mode;
    toggleMode: () => void;
    disabledMode?: boolean;
}

export const NewConversationControls = ({
    mode,
    toggleMode,
    disabledMode = false,
}: Props) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
        }}
    >
        <WithFeature feature={Feature.Campaigns}>
            <Box
                sx={modeSwitcher}
                data-navigation-element
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        toggleMode();
                    }
                }}
                tabIndex={0}
            >
                <SendIcon size="20px" name="send" />
                <Typography variant="body4" mr={1} ml={1}>
                    Send a campaign
                </Typography>
                <ToggleSwitch
                    disabled={disabledMode}
                    isChecked={mode === 'campaign'}
                    onToggle={toggleMode}
                />
            </Box>
        </WithFeature>
    </Box>
);
