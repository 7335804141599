import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SxProps,
    Tooltip,
} from '@mui/material';
import { useKnowledgeBaseEnabled } from '../../../../../helpers/use-knowledge-base-enabled';
import { AiAssistantIcon } from '../../../../ConversationRow/Assistant/AiAssistantIcon';
import { useEffect, useState } from 'react';
import { useAskQuestion } from '../../../../../main/settings/KnowledgeBase/source.query';
import { UUID } from '../../../../../types/uuid';
import { GenerateText } from './CGenerateText';
import { Power } from './CPower';
import { useUpdateConversation } from '../../../../../queries/conversations';
import { DeleteIcon } from '../../../../../icons/common/CDeleteIcon';
import { useClearAssistant } from '../../../../ConversationRow/use-message.query';
import { useTrack } from '../../../../../contexts/analytics';
import { insertText } from '../../../utils';
import { CustomEditor } from '../../../slate';
import { useMenu } from '../../../../../hooks/use-menu';
import { focusVisible } from '../../../../../theme/focusVisible';

const square16: SxProps = {
    width: 16,
    height: 16,
};

export const AssistantMenu = ({
    conversationId,
    editor,
    disabled = false,
    aiAssistantEnabledForInbox = false,
    aiPipelineEnabledForInbox = false,
    aiDisabledForConversation = true,
}: {
    conversationId: UUID;
    disabled?: boolean;
    aiAssistantEnabledForInbox: boolean;
    aiPipelineEnabledForInbox: boolean;
    aiDisabledForConversation: boolean;
    editor: CustomEditor;
}) => {
    const track = useTrack();
    const isEnabled = useKnowledgeBaseEnabled();
    const clearance = useClearAssistant();
    const updater = useUpdateConversation();
    const ask = useAskQuestion('toolbar');
    const [isAssistDialogOpen, setIsAssistDialogOpen] =
        useState<boolean>(false);
    const { anchorEl, open, handleOpen, handleClose } = useMenu();

    useEffect(() => {
        if (ask.isSuccess) {
            insertText(editor, ask.data, true);
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ask.isSuccess]);

    useEffect(() => {
        if (open) {
            track('assistant_message_form_menu_opened');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <Tooltip title="Assistant" arrow placement="top">
                <span>
                    <IconButton
                        disabled={disabled}
                        id="conversation-ai-control-button"
                        aria-controls={
                            open ? 'conversation-ai-control-menu' : undefined
                        }
                        aria-label="AI Assistant"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpen}
                        size="small"
                        sx={{
                            borderRadius: 1,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor:
                                !aiDisabledForConversation &&
                                (aiAssistantEnabledForInbox ||
                                    aiPipelineEnabledForInbox)
                                    ? 'secondary.hoverBorder'
                                    : 'transparent',
                            color: 'secondary.main',
                            transition: (theme) =>
                                theme.transitions.create(
                                    ['borderColor', 'color', 'bgcolor'],
                                    {
                                        duration:
                                            theme.transitions.duration.standard,
                                    },
                                ),
                            p: 0.5,
                            bgcolor:
                                !aiDisabledForConversation &&
                                (aiAssistantEnabledForInbox ||
                                    aiPipelineEnabledForInbox)
                                    ? 'secondary.hover'
                                    : 'transparent',
                            '&:hover': {
                                bgcolor: 'secondary.hover',
                                borderColor: 'secondary.hoverBorder',
                            },
                            svg: square16,
                            '&:focus-visible': {
                                ...focusVisible,
                                outlineOffset: '2px',
                            },
                        }}
                        data-navigation-element
                    >
                        <AiAssistantIcon sx={square16} />
                    </IconButton>
                </span>
            </Tooltip>

            <Menu
                id="conversation-ai-control"
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'conversation-ai-control-button',
                }}
            >
                <MenuItem
                    disabled={ask.isPending}
                    onClick={() => {
                        ask.mutate({ conversationId });
                    }}
                >
                    {ask.isPending ? (
                        <CircularProgress size={16} sx={{ mr: 5 }} />
                    ) : (
                        <ListItemIcon>
                            <GenerateText />
                        </ListItemIcon>
                    )}

                    <ListItemText>Generate answer with AI</ListItemText>
                </MenuItem>
                <MenuItem
                    disabled={
                        (!aiAssistantEnabledForInbox &&
                            !aiPipelineEnabledForInbox) ||
                        updater.isPending
                    }
                    onClick={() => {
                        updater
                            .mutateAsync({
                                id: conversationId,
                                assistantDisabled: !aiDisabledForConversation,
                            })
                            .then(() =>
                                track(
                                    !aiDisabledForConversation
                                        ? 'conversation_assistant_disabled'
                                        : 'conversation_assistant_enabled',
                                ),
                            );
                        if (!aiDisabledForConversation) {
                            setIsAssistDialogOpen(true);
                        }
                    }}
                >
                    {updater.isPending ? (
                        <CircularProgress size={16} sx={{ mr: 5 }} />
                    ) : (
                        <ListItemIcon>
                            <Power />
                        </ListItemIcon>
                    )}
                    <ListItemText>
                        {aiDisabledForConversation ? 'Enable ' : 'Disable '}
                        for conversation
                    </ListItemText>
                </MenuItem>
            </Menu>
            <Dialog
                open={isAssistDialogOpen}
                onClose={() => setIsAssistDialogOpen(false)}
            >
                <DialogTitle>
                    Would you like to remove scheduled assistant messages for
                    this conversation?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() =>
                            clearance
                                .mutateAsync(conversationId)
                                .finally(() => setIsAssistDialogOpen(false))
                        }
                        disabled={clearance.isPending}
                        startIcon={
                            clearance.isPending ? (
                                <CircularProgress size={20} color="error" />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        variant="outlined"
                        color="error"
                    >
                        Delete
                    </Button>
                    <Button
                        disabled={clearance.isPending}
                        onClick={() => {
                            setIsAssistDialogOpen(false);
                            track('assistant_conversation_clearance_canceled');
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
